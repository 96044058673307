body#cms {
  div:not('.engagement-title') {
    h2 {
      span {
        font-family: @font-base;
        font-size: 38px;
        font-weight: 500;
        line-height: 47px;
        text-transform: initial;
        strong {
          font-weight: 700;
        }
      }
    }
  }
  section#main {
    .author {
      p {
        font-family: @font-base;
        font-size: 20px;
        font-weight: 600;
        color: @green2;
        margin-bottom: 0;
        line-height: 1.3;
        span {
          font-size: 15px;
          font-weight: 400;
          display: block;
          margin: 0;
          strong {
            font-size: 20px;
          }
        }
      }
    }
  }
  .etape {
    margin-bottom: 41px;
    h2 {
      line-height: 0.7;
      span {
        font-family: @font-base;
        font-size: 25px;
        font-weight: 200;
        line-height: 1;
        strong {
          font-weight: 700;
        }
      }
    }
    p {
      font-family: @font-base;
      font-size: 14px;
      font-weight: 400;
      color: @black;
      margin-bottom: 5px;
      line-height: 1.5;
    }
    .image-label {
      position: absolute;
      top: -21px;
      right: -57px;
      z-index: 1;
      @media screen and (max-width: 768px) {
        right: 50px;
      }
      &:before {
        content: "";
        border: 1px dashed @green2;
        width: 84px;
        height: 1;
        position: absolute;
        right: -90px;
        top: 50%;
        display: block;
        z-index: 5;
      }
    }
  }
}
