.products.row ,
.products-grid.row {
    margin: 0 -37px;
    div.cart-product {
        padding: 0 0px 50px 40px;
        position: relative;
        @media (max-width: 992px) {
            padding: 0px 0 50px 0;
        }
        .product-flags,
        .tages {
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            z-index: 10;
            @media (max-width: 767px) {
                left: 8px;
                top: 8px;
            }
            .product-flag,
            .plantes {
                width: 73px;
                height: 22px;
                text-align: center;
                display: inline-block;
                background-color: rgba( @green2 , .1);
                font-family: 'arial';
                font-size: 13px;
                line-height: 22px;
                color: @green2;
                padding: 0;
            }
            .prefere {
                width: 73px;
                height: 22px;
                text-align: center;
                display: inline-block;
                background-color: @green2;
                font-weight: 300;
                font-family: 'arial';
                font-size: 10px;
                line-height: 22px;
                color: @white;
            }
        }
        .product-flags {
            right: 0;
            left: initial;
        }
    }
    .product-miniature {
       
        .product-description {
            padding: 6px 0 0; 
            @media (max-width: 768px) {
                padding: 8px;
            }
            .extra-small-gutters {
                @media (max-width: 768px) {
                    margin: 0;
                    display: flex;
                }
                .col {
                    @media (max-width: 768px) {
                        padding: 0;
                    }
                }
                .product-add-cart {
                    @media (max-width: 992px) {
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                    }
                   > .btn-primary {
                       @media (max-width: 768px) {
                           margin: 0 5px 0 0 !important;
                       }
                   }
                }
                .product-miniature-right {
                    @media (max-width: 768px) {
                        text-align: left;
                    }
                }
            }
        }
        .product-title {
            padding: 0;
            margin-bottom: 0;
            height: 47px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2; 
            -webkit-box-orient: vertical;
            @media (max-width: 992px) {
                margin: 0;
                height: initial;
                -webkit-line-clamp: 1;
                margin-bottom: 10px;
            }
            a {
                color: @green2;
                font-family: @font-base;
                font-size: 17px;
                line-height: 20px;
                font-weight: bold;
                text-transform: uppercase;
                text-align: left;
                display: block;

                @media (max-width: 768px) {
                    margin: 0;
                    line-height: 1;
                    font-size: 17px;
                    height: 36px;
                }
            }
        }    
        .desc-min {
            margin-bottom: 10px;
            font-family: @font-base3;
            font-weight: normal;
            font-size: 13px;
            line-height: 15px;
            color: @gray3 !important;
            text-align: left;
            height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-transform: initial;
            @media (max-width: 768px) {
                margin: 0 0 5px;
                font-size: 12px;
                line-height: 14px;
                height: auto;
                margin-bottom: 10px;
            }
        }
        .gelules {
            margin-bottom: 10px;
            font-family: @font-base3;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: @gray3;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-transform: initial;
            height: 18px;
            @media screen and (max-width: 768px) {
                height: initial;
                font-size: 12px;
                line-height: 14px;
                height: auto;
                margin-bottom: 10px;
            }
        }
        .trustpilot-widget {
            margin-bottom: 12px;
            iframe {
                width: 162px !important;
                margin: 0;
                transform: translateX(-10px);
            }
        }
        .product-price-and-shipping {
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column-reverse;
            justify-content: center;
            text-align: left;
            @media screen and (max-width: 992px) {
                width: 85px;
                
            }
            .product-price {
                font-family: @font-base;
                font-weight: 700;
                font-size: 15px;
                line-height: 17px;
                margin-bottom: 3px;
                color: @black;
                @media screen and (max-width: 992px) {
                    font-size: 19px;

                }
                sup {
                    font-size:12px;
                    font-weight: 500;
                    margin-left:5px;
                    color:#CCC;
                    display: inline-block;
                    width: 29px;
                }
            }
            
        }
        .product-add-cart {
            display: flex !important;
            padding: 0;
            .btn {
                font-size: 12px;
                line-height: 40px;
                font-family: @font-base;
                font-weight: normal;
                text-transform: uppercase;
                color: @purple;
                letter-spacing: 0;
                max-width: 150px;
                width: 100%;
                height: 40px;
                margin: 0;
                border: 1px solid @purple;
                background-color: @white;
                border-radius: 10px;
                &.add-to-cart {
                    font-weight: 700;
                    width: 40px;
                    height: 40px;
                    background-color: @purple;
                    border: 1px solid @purple;
                    i {
                        margin: 0;
                    }
                    &:hover {
                        background: @white;
                        i {
                            color: @purple;
                        }
                    }
                }   
                @media screen and (max-width: 1025px) {
                    font-size: 13px;
                }
            }
            a.btn.btn-primary {
               
                @media screen and (max-width: 768px) {
                    line-height: 1.2;
                    padding: 8px 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                &:hover {
                    background-color: @purple;
                    color: @white;
                }
            }
            form {
                max-width: 40px;
                width: 100%;
                margin-left: 5px;
            }
        }
    }
}  
@media screen and (max-width: 1025px) {
    .product-add-cart {
        .btn {
            font-size: 13px;
        }
    }
}
@media screen and (max-width: 641px) {
    .products-grid {
        .product-miniature {
            .product-title a {
                font-size: 22px;
            }

            .desc-min {
                p {
                    font-size: 16px;
                }
            }
            .product-add-cart {
                .btn {
                    font-size: 11px;
                    padding: 0;
                }
            }
           
        }
    }
}
