body#module-ph_simpleblog-page,
body#module-ph_simpleblog-categorypage,
body#module-ph_simpleblog-category,
body#module-ph_simpleblog-list {
  background-color: @white;
  padding: 0 !important;
  .container {
    width: 100%;
  }
  .breadcrumb {
    display: none;
  }
  div.category-header {
    display: flex;
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 768px) {
      background-size: cover;
    }

    div.category-image {
      width: 100vw;
      position: absolute;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      z-index: 0;
      top: 0;
      padding: 0;
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        &.img-responsive {
          @media (max-width: 992px) {
            display: none;
          }
        }
        &.image-mobile {
          display: none;

          @media (max-width: 992px) {
            display: block;
            object-fit: cover;
            height: 473px;
          }
        }
      }
    }

    div.category-description {
        width: 50%;
        height: 499px;
        z-index: 3;
        max-width: 800px;
        margin: 0 auto 13px 0;
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding-bottom: 147px;
      @media (max-width: 991.98px) {
        height: 473px;
      }

      @media (max-width: 767.98px) {
        width: 100%;
        padding-bottom: 50px;
      }

      h1 {
        font-family: @font-base;
        font-weight: bold;
        text-align: left;
        color: @white;
        font-size: 40px;
        margin-bottom: 10px;
        @media (max-width: 767px) {
         font-size: 36px;
        }
      }
      p {
        font-family: @font-base;
        font-size: 20px;
        font-weight: 400;
        color: @white;
        @media (max-width: 767px) {
          font-size: 16px;
         }
      }
    }
  }
  .year {
    margin: 38px 0 0px;
    display: flex;
    justify-content: center;
    flex: 0 0 100%;
    max-width: 100%;
    span {
      width: 96px;
      height: 96px;
      box-shadow: 0 0 15px 0 rgba(@black, 0.16);
      background-color: @green2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: @font-base;
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      color: @white;
      border-radius: 50%;
    }
  }
  .events {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    padding-top: 50px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      background-color: @bgcolor-footer;
      width: 2px;
      height: calc(100% + 50px);
      top: -50px;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: -1;
    }
    &-card {
      flex: 0 0 calc(50% - 72px);
      max-width: calc(50% - 72px);
      flex-flow: wrap;
      box-shadow: 0 0 3px 0 rgba(@black, 0.16);
      border: solid 1px rgba(@green2, 0.4);
      position: relative;
      margin-bottom: 134px;
      padding: 15px 15px 20px 15px;
      &:nth-child(odd) {
        &:after,&:before {
          top: 28px;
        }
        &:after {
          content: " ";
          position: absolute;
          right: -15px;
          border-top: 15px solid transparent;
          border-right: none;
          border-left: 15px solid @white;
          border-bottom: 15px solid transparent;
        }
        &:before {
          content: " ";
          position: absolute;
          right: -16px;
          border-top: 15px solid transparent;
          border-right: none;
          border-left: 15px solid rgba(@green2, 0.4);
          border-bottom: 15px solid transparent;
        }
        .date-event {
          right: -105px;
          top: 12px;
        }
      }
      &:nth-child(even) {
        &:after,&:before {
          top: 175px;
        }
        &:after {
          content: " ";
          position: absolute;
          left: -15px;
          border-top: 15px solid transparent;
          border-right: 15px solid @white;
          border-left: none;
          border-bottom: 15px solid transparent;
        }
        &:before {
          content: " ";
          position: absolute;
          left: -16px;
          border-top: 15px solid transparent;
          border-right: 15px solid rgba(@green2, 0.4);
          border-left: none;
          border-bottom: 15px solid transparent;
        }
        .date-event {
          left: -105px;
          top: 155px;
        }
      }
      .date-event {

        position: absolute;
        > span {
          display: flex;
          flex-direction: column;
          font-family: @font-base;
          font-size: 14px;
          font-weight: 300;
          color: @white;
          width: 66px;
          height: 66px;
          box-shadow: 0 0 15px 0 rgba(@black, 0.16);
        //   background-color: @green3;
          border-radius: 50%;
          align-items: center;
          justify-content: center;
          .day {
            font-family: @font-base;
            font-size: 22px;
            font-weight: 300;
            text-align: center;
            color: @white;
          }
        }
      }
      .image {
        box-shadow: 0 0 3px 0 rgba(@black, 0.16);
        border: solid 1px rgba(@green2, 0.4);
        text-align: center;
        padding: 5px 0;
        img {
          max-width: 220px;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .title {
        padding: 10px 15px;
        margin: 0 -15px;
        margin-bottom: 18px;
        border-bottom: solid 1px rgba(@green2, 0.2);
        h2 {
          font-family: @font-base;
          font-size: 24px;
          font-weight: bold;
          text-transform: uppercase;
          color: @green2;
          margin-bottom: 10px;
        }
        .min-infos {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .brand {
            font-family: @font-base;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            color: @green2;
          }
          .date {
            font-family: @font-base;
            font-size: 16px;
            font-weight: 500;
            color: @gray-darker2;
          }
        }
      }
      .description {
        p {
          font-family: @font-base;
          color: @gray-darker2;
          font-size: 16px;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 20px;
        }
        .more {
          font-family: @font-base;
          font-size: 18px;
          font-weight: 500;
          color: @green2;
          i {
            margin: 0 0 0 13px;
            &:before {
              content: "";
              background-image: url("@{img}slick-arrow-left.svg");
              background-repeat: no-repeat;
              background-size: contain;
              width: 7px;
              height: 12px;
              display: inline-block;
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
  .loadmore-wrapper {
    .loadmore {
      margin: 0px 0 94px;
      display: flex;
      justify-content: center;
      flex: 0 0 100%;
      max-width: 100%;
      img {
        width: 74px;
        height: 74px;
        // box-shadow: 0 0 15px 0 rgba(@black, 0.16);
        // background-color: @green2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: @font-base;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        color: @white;
        border-radius: 50%;
      }
    }
  }
}