.card-actif_naturel {
    position: relative;
    @media (max-width:991.98px) {
        padding: 20px 0;
    }
    &:before {
        position: absolute;
        content: "";
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        background-color: @beige1;
        width: 90%;
        height: 100%;
        @media (max-width:991.98px) {
            width: 100%;
            height: calc(100% - 100px);
            transform: initial;
            top: initial;
            bottom: 0;
        }
    }
    .elementor-widget-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &-img {
        flex: 0 0 200px;
        min-width: 200px;
        height: 200px;
        margin-right: 37px;


        @media (max-width:991.98px) {
            flex: 0 0 160px;
            height: 160px;
            margin-right: 24px;
        }

        @media (max-width:767.98px) {
            flex: 0 0 100%;
            min-width: 100%;
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
        }

        img {
            height: 200px;
            width: 200px;

            @media (max-width:991.98px) {
                height: 160px;
                width: 160px;
            }
        }

    }

    &-disc {
        flex: 1;
        padding: 10px 60px 10px 0;

        @media (max-width:767.98px) {
            text-align: center;
            flex: 0 0 100%;
            min-width: 100%;
            padding: 0;
        }
        p {
            line-height: 25px;
        }
    }

    &-wrapper {
        margin-bottom: 56px;
    }
}