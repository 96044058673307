.modal#reminder_popup_modal {
  .modal-body {
    padding: 0;
    > img {
      @media screen and (max-width: 620px) {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }
    }
  }
  &.modal-temoignage {
    .modal-body {
      > img {

        @media screen and (max-width: 1024px) {
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
        }
      }
    }
    .content-modal {
      @media screen and (max-width: 1024px) {
        position: relative;
        top: initial;
        left: initial;
        transform: initial;
        padding: 50px;
      }
    }
  }
  .ns-temoignage.slide-popin {
    width: 100%;
    max-width: 675px;
    margin: 20px 0 0px;
    &:before,
    &:after {
      content: initial;
    }
    .elementor-testimonial-wrapper {
      .elementor-testimonial-image {
        img {
          width: 60px;
          border-radius: 100%;
          max-width: 60px;
        }
      }
      .elementor-testimonial-details {

        .elementor-testimonial-name {
          font-size: 12px;
        }
      }

      .elementor-testimonial-content {
        h3 {
          font-family: @font-base2;
          font-size: 18px;
          font-weight: 400;
          text-transform: initial;
          margin-bottom: 19px;
        }

        p:not(.h3) {
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          margin-bottom: 35px;
          min-height: initial;
        }

      }
    }
    .slick-arrow {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      box-shadow: none;
      border: none;
      font-size: 0;
      width: 16.5px;
      height: 26.5px;
      background-image: url("@{img}slick-arrow-left.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      z-index: 9;
      &.slick-prev {
        left: 0;
        right: initial;
        @media screen and (max-width: 620px) {
          left: 0px;
        }
      }
      &.slick-next {
        left: initial;
        right: 0;
        transform: translateY(-50%) scale(-1);
        @media screen and (max-width: 620px) {
          right: 20px;
        }
      }
      &:hover {
        opacity: .8;
      }

    }

    .slick-list {
      overflow: hidden;
      .slick-track {
        display: flex;
        justify-content: center;
        .slick-slide {
          padding: 0 6px;
        }
      }
    }
    .elementor-testimonial-job {
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
          padding: 0;
          span {
            display: block;
            width: 14.8px;
            height: 13.8px;
            background-image: url("@{img}rating-star.svg");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .modal-dialog {
    max-width: fit-content;
  }
  .modal-content {
    background: transparent;
  }
  .close {
    position: absolute;
    top: 9px;
    right: 9px;
    z-index: 5;
    color: white;
    opacity: 1;
    text-shadow: none;
    height: initial;
    line-height: 0.5;
    span {
      font-size: 50px;
    }
  }
  h5 {
    font-family: @font-base;
    font-size: 38px;
    font-weight: bold;
    color: @white;
    text-transform: uppercase;
    margin-bottom: 19px;
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  .about-us-description {
    p {
      font-family: @font-base;
      font-size: 20px;
      font-weight: 500;
      color: @white;
      text-transform: uppercase;
      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
  .botton-cart {
    max-width: 564px;
    width: 100%;
    margin-top: 30px;
  }
  .btn {
    font-family: @font-base;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    height: 53px;
    line-height: 53px;
    @media screen and (max-width: 768px) {
      font-size: 16px;
      padding: 10px 0;
      height: initial;
      line-height: 1.5;
    }
    &.btn-white {
      color: @green2;
      background: @white;
      border-color: @white;
      &:hover {
        color: @white;
        background: @green2;
        border-color: @white;
      }
    }
    &.btn-green {
      color: @white;
      background: @green2;
      border-color: @green2;
      &:hover {
        color: @green2;
        background: @white;
        border-color: @green2;
      }
    }

  }
  .content-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    padding: 27px;
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
    @media screen and (max-width: 620px) {
      position: relative;
      top: initial;
      left: initial;
      transform: initial;
      padding: 50px;
      width: 100%;
    }
  }
  &.modal-green {
    h5 {
      color: @green2;
    }
    .about-us-description {
      p {
        color: @green2;
      }
    }
    .close {
      color: @green2;
    }
  }
  &.modal-first {
    .content-modal {
      select {
        max-width: 275px;
        height: 54px !important;
        line-height: 54px;
        border: 2px solid @white;
        background-color: @green2;
        color: white;
        font-size: 18px;
        padding: 0 29px;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 28px;
        background: url("@{img}dropSelect.svg") no-repeat scroll right  16px center / 20px 20px;
        * {
          color: @green2;
        }
      }
    }
  }
  &.second-modal {
    .content-modal {
      align-items: flex-start;
    }
    .botton-cart {
      max-width: 434px;
    }
  }

  @media screen and (max-width: 576px) {
    .modal-dialog {
      margin: 90px auto;
      padding: 15px;
    }
    .modal-content {
      min-height: initial;
    }
  }

}
.modal-backdrop {
  background: rgba(@black , .2);
}
////// Popup ADD to cart ///////

div#blockcart-modal-wrap {
  div.product-pack {
    border: none;
    padding: 0;
    margin: 0 0 15px 0;
    &.frais {
      div.product-pack-desc {
        max-width: 310px;
        p.label {
          font-weight: 500;
          font-size: 14px;
          color: @green2;
          text-align: left;
          text-transform: initial;
          line-height: 1.2;
          @media (max-width: 992.98px) {
            text-align: center;
          }
        }
      }
      button.btn {
        font-size: 10px;
        font-weight: bold;
        padding: 0 10px;
        height: 34px;
        line-height: 34px;
        &:hover {
          color: @green2;
        }
      }
    }

    &-group {
      border: 1px solid @lightgreen;
      padding: 1vh 11px 1vh 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 992.98px) {
        flex-direction: column;
        padding: 15px 5px;
      }
    }

    &-img {
      max-width: 155px;
      width: 100%;
    }

    &-desc {
      max-width: 418px;
      width: 100%;
      padding: 8px 10px 0 0;
      @media (max-width: 1360px ) and ( min-width: 1024px) {
        max-width: initial;
        width: auto;
      }
      p.label {
        font-family: @font-base;
        font-weight: 700;
        font-size: 19px;
        color: @lightgreen3;
        margin-bottom: 4px;
        text-align: left;
        text-transform: uppercase;
        line-height: 1;

        @media (max-width: 1024px) {
          text-align: center;
        }
      }
      p.month {
        font-family: @font-base;
        font-weight: 700;
        font-size: 17.5px;
        color: @green2;
        margin-bottom: 1px;
        text-align: left;

        @media (max-width: 1024px) {
          text-align: center;
        }
        span {
          font-size: 20px;
          color: @lightgreen3;
        }
      }

      p.description {
        font-family: @font-base;
        font-weight: 400;
        font-size: 14px;
        color: @gray-darker2;
        line-height: 18px;
        strong {
          font-weight: 600;
        }
        @media (max-width: 1024px) {
          text-align: center;
        }
      }
    }

    &-price {
      padding: 0 41px 0 25px;
      @media (max-width: 1360px ) and ( min-width: 1024px) {
        padding: 0 10px 0 10px;
      }
      .old-price {
        font-family: @font-base;
        font-weight: 600;
        font-size: 20px;
        color: @gray5;
        margin: 0;
        padding: 0;
        text-align: right;
        text-decoration: line-through;
        @media (max-width: 992.98px) {
          text-align: center;
        }
      }

      .price {
        font-family: @font-base;
        font-weight: 700;
        font-size: 28px;
        color: @lightgreen;
      }
    }

    &-cart {
      @media (max-width: 1360px ) and ( min-width: 1024px) {
        min-width: 150px;
      }
      @media (max-width: 992.98px) {
        margin-top: 10px;
      }
      input.qty_discount {
        display: none;
      }

      p.econome {
        font-family: @font-base;
        font-weight: 700;
        font-size: 16px;
        color: @green2;
        margin-bottom: 5px;
        text-align: center;

        span {
          position: relative;
          padding-left: 25px;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 18px;
            height: 18px;
            background-image: url("@{img}econome.png");
          }
        }
      }

      a.btn {
        font-family: @font-base;
        font-weight: 700;
        font-size: 12px;
        padding: 0 12px;
        @media (max-width: 1360px ) and ( min-width: 1024px) {
          font-size: 12px;
        }
        @media (max-width: 1440px) {
          height: initial;
          line-height: initial;
          padding: 10px;
        }
        &:hover {
          border-color: @lightgreen;
        }
      }
    }

    div.img-popup {
      position: relative;
      cursor: pointer;

      img {
        position: absolute;
        bottom: 100%;
        right: -50%;
        width: 312px;
        min-width: 312px;
        width: auto;
        height: auto;
        overflow: hidden;
        opacity: 0;
        transition: all 0.3s ease-in;
        @media (max-width: 1360px) {
          right: 0;
        }
      }

      &:hover {
        img {
          opacity: 1;
          overflow: visible;
        }
      }
    }

    &-group2 {
      margin: 0;
      display: flex;
      justify-content: space-between;
      @media (max-width: 1024px) {
        flex-direction: column;
      }

      .qty_discount {
        display: none;
      }

      .product-pack-labelDisc {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px solid @gray-border;
        position: relative;
        cursor: pointer;
        padding: 0;
        height: 128px;
        max-width: 220px;
        width: 100%;
        @media (max-width: 992.98px) {
          max-width: 100%;
          margin-bottom: 20px;
        }

        &.checked {
          border: 2px solid @black;
          background-color: @lightgreen2;

          .checked-label, .percentage {
            display: block;
          }
        }

        .checked-label {
          display: none;
          font-family: @font-base;
          font-weight: 600;
          font-size: 15px;
          color: @white;
          background: @lightgreen;
          padding: 3px 0;
          text-align: center;
          margin-bottom: 5px;
          width: 100%;
          top: 0;
          position: absolute;
        }

        .img-popup2 {
          position: absolute;
          bottom: 100%;
          right: -40px;
          width: 312px;
          min-width: 312px;
          height: auto;
          overflow: hidden;
          opacity: 0;
          display: none;
          transition: all 0.3s ease-in;
          @media (max-width: 1360px) {
            right: 0;
          }
        }

        &:hover {
          .img-popup2 {
            opacity: 1;
            overflow: visible;
            display: block;
          }
        }

        .label {
          font-family: @font-base;
          font-weight: 600;
          font-size: 15px;
          color: @black;
        }

        .price {
          font-family: @font-base;
          font-weight: 700;
          font-size: 30px;
          color: @green2;
        }

        .percentage {
          display: none;
          font-family: @font-base;
          font-weight: 700;
          font-size: 18px;
          color: @white;
          background-image: url("@{img}bgeco.png");
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          bottom: -20px;
          right: 50%;
          transform: translateX(50%);
          width: 100%;
          text-align: center;
          @media (max-width: 992.98px) {
            width: auto;
            padding: 0 44px;
          }
        }
      }
    }

  }
  .blockcart-modal + .ns-show ,.ns-show + .blockcart-modal {
    opacity: 1;
    display: block;
    background-color: rgba(0, 0, 0, 0.6);
  }
  div#blockcart-notification {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0;
    max-width: 1220px;
    width: 100%;
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: @white;
    opacity: 1;
    visibility: visible;

    @media screen and (max-width: 968px) {
      width: 90%;
      height: 90vh;
    }
    &.hide {
      visibility: hidden;
      opacity: 0;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100vw;
      height: 100vh;
      z-index: -1;
      background-color: rgba(@black,.6);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    div.bartop {
      width: 100%;
      padding: 41px 15.8px 41px 68px;
      background-color: @beige9;
      // @media screen and (max-width: 1440px) {
      //   padding: 10px 20px;
      // }
      @media screen and (max-height: 650px) {
        padding: 20px 15.8px 10px 68px;
      }
      @media screen and (max-width: 1207px) {
        padding: 20px 15.8px 10px 30px;
      }

      @media screen and (max-width: 968px) {
        padding: 16.8px 16px 14px 16px;
      }
    
    }
    div#blockcart-close.btn-close {
      position: static;
      display: flex;
      justify-content: start;
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      z-index: 5;
      color: #fff;
      opacity: 1;
      text-shadow: none;
      height: initial;
      line-height: .5;
      font-weight: bold;
      width: auto;
      cursor: pointer;
      @media screen and (max-width: 1440px) {
      }
      @media screen and (max-width: 768px) {
      }
      &:hover {
        opacity: .8;
      }
      span {
        font-size: 30px;
        position: absolute;
        right: 20px;
        color: @purple;
        font-family: cursive;
        font-weight: 400;
        height: 24px;
      }
      p {
        font-family: @font-base;
        font-size: 14px;
        font-weight: 400;
        font-stretch: normal;
        font-style: normal;
        line-height: 16px;
        letter-spacing: normal;
        text-align: left;
        color: @purple;
        text-transform: uppercase;
        @media screen and (max-width: 1440px) {
          font-size: 21px;
          margin: 0;
          line-height: initial;
        }
        @media screen and (max-width: 968px) {
          font-size: 14px;
          line-height: 1.98;
        }
      }
    }

    div.ns-box-inner {
      padding: 0px 38px 0 68px;
      background-color: @beige9;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-top: -1px;
      @media screen and (max-width: 1207px) {
        padding-left: 30px;
      }
      @media screen and (max-width: 968px) {
        padding: 15px 15px 21px 15px;
        height: calc(100% - 70px);
        overflow-y: auto;
      }
      @media screen and (max-width: 992px) {
       flex-wrap: wrap;
      }
      // &:after {
      //   content: '';
      //   background-image: url("@{img}ModalCart1.svg");
      //   background-size: contain;
      //   background-repeat: no-repeat;
      //   position: absolute;
      //   right: 0;
      //   top: 0;
      //   display: block;
      //   width: 362px;
      //   height: 471px;
      //   pointer-events: none;
      //   z-index: 0;
      //   @media screen and (max-width: 1024px) {
      //     content: initial;
      //   }
      // }
      // &:before {
      //   content: '';
      //   background-image: url("@{img}ModalCart2.svg");
      //   background-size: contain;
      //   background-repeat: no-repeat;
      //   position: absolute;
      //   left: 0;
      //   bottom: 20%;
      //   transform: translateY(-50%);
      //   display: block;
      //   width: 131px;
      //   height: 113px;
      //   pointer-events: none;
      //   z-index: 0;
      //   @media screen and (max-width: 1024px) {
      //     content: initial;
      //   }
      // }
      .cart_produit-wrapper {
        max-width: 304px;
        width: 100%;
        background-color: @white3;
        padding: 24px 26px 27px;
        @media screen and (max-height: 650px) {
          padding-top: 14px 26px 17px;
        }
        @media screen and (max-width: 992px) {
          margin: 0 auto;
        }
        h4 {
          font-family: @font-base;
          font-size: 20px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: normal;
          text-align: center;
          color: @green2;
          margin-bottom: 27px;
        }
        .cart_produit {
          background: @white;
          margin: 0 5px;
          div.ns-thumb {
            animation: none;
            margin-bottom: 13px;
            position: relative;

            @media screen and (max-height: 650px) {
              img {
                width: 80%;
                display: block;
                margin: 0 auto;
              }
            }
            @media screen and (max-width: 968px) {
              max-width: 100%;
              flex: 0 0 100%;
              text-align: center;
            }
            img.img-fluid {
              border-radius: 5px;
            }
            &::before {
              content: "";
              position: absolute;
              right: 16px;
              top: 14px;
              width: 34px;
              height: 34px;
              border-radius: 5px;
              background-image: url("@{img}checked-popup.jpg");
              background-size: contain;
              background-repeat: no-repeat;
              // @media (max-width: 992.98px) {
              //   left: 10px;
              //   top: 28%;
              // }
            }

          }
          .content {
            padding: 0 11px 12px;
            p.ns-title {
              font-family: @font-base;
              font-size: 14px;
              font-weight: 700;
              font-stretch: normal;
              font-style: normal;
              line-height: 18px;
              letter-spacing: normal;
              text-transform: uppercase;
              text-align: left;
              color: @green2;
              margin-bottom: 10px;
            }
            p.qty {
              font-family: @font-base;
              font-size: 14px;
              font-weight: 400;
              font-stretch: normal;
              font-style: normal;
              line-height: 18px;
              letter-spacing: normal;
              text-align: left;
              color: @black;
              margin-bottom: 14px;
            }
            p.price {
              font-family: @font-base;
              font-size: 18px;
              font-weight: 700;
              font-stretch: normal;
              font-style: normal;
              line-height: 21px;
              letter-spacing: normal;
              text-align: right;
              color: @black;
              margin-bottom: 0px;
              sup {
                font-size: 17px;
                margin-left: 5px;
                color: #CCC;
              }
            }
          }
        }
      }
      
      .ns-interesser {
        padding-left: 38px;
        padding-top: 24px;
        width: calc(100% - 304px);
        @media screen and (max-height: 650px) {
          padding-top: 14px;
        }
        @media screen and (max-width: 992px) {
         width: 100%;
         padding-left: 0;
        }
        .title-prod-popup {
          font-family: @font-base;
          font-size: 20px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 24px;
          letter-spacing: normal;
          text-align: left;
          color: @green2;
          margin-bottom: 27px;
          text-transform: initial;
          @media screen and (max-height: 650px) {
            margin-bottom: 17px;
          }
        }
        .products.row {
          margin: 0;
          @media screen and (max-height: 767px) {
            padding: 0 25px;
          }
          .slick-slide {
            padding: 0;
            
          }
          div.cart-product {
            padding: 10px;
            max-width: 100%;
            flex: 0 0 100%;
            article {
              background: @white;
              padding: 10px;
              border: 1px solid rgba(@black , .2);
              box-shadow: 0 0 10px -4px rgba(0, 0, 0, 0.6);
              overflow: visible;
              .tages {
                top: 10px;
                left: 10px;
              }
            }
          }
        }
        .prod-popup {
          width: 100%;
        
        }
        .product-price {
          sup {
            font-size:8px;
            margin-left:2px;
            color:#CCC;
          }
        }
      }

      .ns-footer {
        margin-top: 38px;
        margin-bottom: 59px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: @white3;
        border-radius: 10px;
        overflow: hidden;
        @media screen and (max-height: 650px) {
          margin-top: 15px;
          margin-bottom: 25px;
        }
        @media screen and (max-width: 992px) {
          flex-wrap: wrap;
          justify-content: center;;
         }
        .modal_panier {
          width: (100% - 297px);
          display: flex;
          align-items: center;
          @media screen and (max-width: 992px) {
            flex-wrap: wrap;
            justify-content: left;
            padding-top: 20px;
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;
          }
          .qty-prduit {
            font-family: @font-base;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 18px;
            letter-spacing: normal;
            text-align: left;
            text-transform: uppercase;
            color: @black;
            margin-bottom: 0px;
            padding-left: 58px;
            position: relative;
            padding-right: 50px;
            @media screen and (max-width: 992px) {
             margin-bottom: 20px;
             padding-left: 0;
            }
            @media screen and (max-width: 767px) {
             margin-bottom: 20px;
             padding-left: 0;
             padding-right: 0;
            }
            &:before {
              content: "";
              width: 21px;
              height: 20px;
              background-image: url("@{img}Ncart-btn-hover.svg");
              display: block;
              transition: all 0.3s ease-out;
              background-repeat: no-repeat;
              background-position: center;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              left: 28px;
            }
          }
          .shipping {
            font-family: @font-base;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 18px;
            letter-spacing: normal;
            text-align: left;
            text-transform: uppercase;
            color: @green2;
            margin-bottom: 0px;
            padding-left: 20px;
            @media screen and (max-width: 992px) {
              margin-bottom: 20px;
              padding-left: 0;
              width: 100%;
             }
            @media screen and (max-width: 767px) {
              margin-bottom: 20px;
              padding-left: 0;
             }
          }

          div.barprogress {
            width: 247px;
            height: 7px;
            border-radius: 14px;
            position: relative;
            background-color: @greenDark;
            @media screen and (max-width: 992px) {
              margin-bottom: 20px;
              width: calc(100% - 250px);
            }
            @media screen and (max-width: 767px) {
              margin-bottom: 20px;
              width: 100%
            }
            span.stepprpgress {
              background-color: @green2;
              height: 100%;
              display: block;
              border-radius: 14px;
              width: 0;
            }
            span.icon-panier {
              position: absolute;
              top: 0%;
              left: 0;
              transform: translate(-0%, -70%);
              display: flex;
              align-items: center;
              justify-content: center;
              width: 28px;
              height: 28px;
              // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
              background-color: @lightgreen;
              background: none;
              border-radius: 50%;
              z-index: 1;
              margin-left: -14px;
            }
            span.icon-right {
              position: absolute;
              top: 0%;
              right: 0;
              transform: translate(50%, -70%);
              display: flex;
              align-items: center;
              justify-content: center;
              width: 28px;
              height: 28px;
              // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
              background-color: @greenDark;
              background: none;
              border-radius: 50%;
            }
          }
        }
        .btn-wrapper {
          @media screen and (max-width: 992px) {
            width: 100%;
           }
          .btn-commande {
            background-color: @purple;
            color: @white;
            width: 297px;
            height: 50px;
            line-height: 50px;
            font-family: @font-base;
            font-size: 16px;
            font-weight: 400;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: center;
            text-transform: uppercase;
            display: block;
            border: 1px solid @purple;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            @media screen and (max-width: 992px) {
              width: 100%;
              border-top-right-radius: 0px;
             }
            &:hover {
              color: @purple;
              background-color: @white;
            }
          }
        }
      }
      div.ns-content {
        padding-left: 24px;
        animation: none;
        @media screen and (max-width: 968px) {
          max-width: 100%;
          flex: 0 0 100%;
          padding: 0;
        }

        span.ns-title {
          font-family: @font-base;
          font-size: 34px;
          text-align: left;
          color: @green2;
          display: block;
          line-height: 1.2;
          margin-bottom: 4px;
          text-transform: uppercase;
          @media screen and (max-width: 968px) {
            font-size: 28px;
          }
        }
        .ns-sub-title  p {
          font-family: @font-base;
          font-size: 18px;
          font-weight: 700;
          text-align: left;
          color: @gray-darker2;
          display: block;
          margin-bottom: 14px;
          text-transform: uppercase;
          line-height: 1.3;
          @media screen and (max-width: 968px) {
            font-size: 16px;
          }
          @media screen and (max-width: 768px) {
            font-size: 13px;
          }
        }
        div.ns-info-panier {
          padding: 1vh 11px 1.5vh 28px;
          border: solid 1px rgba(@green2, 30%);
          margin-bottom: 12px;
          @media screen and (max-width: 768px) {
            padding: 15px 5px;
          }
          p:first-child {
            font-family: @font-base;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
            color: @gray-darker2;
            margin-bottom: 7px;
            line-height: 1;
          }
          p:nth-child(2) {
            font-family: @font-base;
            font-size: 14px;
            font-weight: 500;
            color: @green2;
            margin-bottom: 10px;
            &:before {
              content: "";
              background-image: url("@{img}iconsQ2.svg");
              background-repeat: no-repeat;
              background-size: contain;
              width: 11px;
              height: 7px;
              display: inline-block;
              transform: rotate(90deg);
              margin-right: 5px;
            }
          }
         
        }
        .btn-commande {
          display: block;
          width: 100%;
          padding: 11px 0px 11px 0px;
          border: 1px solid @green2;
          background-color: @green2;
          font-family: @font-base;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
          text-transform: uppercase;
          &:hover {
            color: @green2;
            background-color: #fff;
          }
        }
      }
      div.ns-delivery {
        animation: none;
      }
    }
    .title-prod-popup {
      font-family: @font-base;
      font-size: 19px;
      font-weight: bold;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
      color: @green2;
      display: block;
      margin-bottom: 1vh;
      width: 100%;
      text-transform: uppercase;
      @media screen and (max-height: 650px) {
        margin-bottom: 17px;
      }
      @media screen and (max-width: 768px) {
        margin-top: 10px;
      }
    }
    .prod-popup-item {
      text-align: center;
      img {
        max-width: 153px;
        display: block;
        margin: 0 auto;
      }
      .name {
        font-family: @font-base;
        font-size: 13px;
        font-weight: bold;
        line-height: 1.23;
        text-align: center;
        color: @green2;
        display: block;
        margin-bottom: 7px;
        text-transform: uppercase
      }
      .btn {
        max-width: 190px;
        height: 40px;
        line-height: 40px;
        font-family: @font-base;
        font-size: 13px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        i {
          &:before {
            content: "";
            width: 16px;
            height: 15px;
            background-image: url("@{img}cart-btn_1.svg");
            display: block;
            transition: all .3s ease-out;
            background-size: contain;

          }
        }
        &:hover {
          i {
            &:before {
              background-image: url("@{img}cart-btn-hover.svg");
            }
          }
        }
      }
    }
    .products.row {
      width: 100%;
      position: relative;
      .slick-arrow {
        @media (max-width: 992.92px) {
          &.slick-prev  {
            left: 0;
            right: initial;
            &::after {
              content: "\F104";
            }
          }
        }

      }
    }
    .slick-list {
      overflow: hidden;
      width: 100%;
      .slick-track {
        display: flex;
        justify-content: space-between;
        .slick-slide {
          padding: 0 6px;
        }
      }
    }
  }
}

.products.row {
  .slick-arrow {
    &::after {
      content: none !important;
    }
    &.slick-prev {
      left: 0;
      right: initial;
      @media screen and (max-width: 620px) {
        left: 0px;
      }
    }
    &.slick-next {
      left: initial;
      right: 0;
      transform: translateY(-50%) scale(-1);
      @media screen and (max-width: 620px) {
        right: 20px;
      }
    }
    &:hover {
      opacity: .8;
    }

  }

}