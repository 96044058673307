header#header {
  background-position-y: 61px;
  box-shadow: none;
  border-top: none;
  background: none;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;

  .header-banner {
    background-color: #005745;
    height: 30px;
    .elementor-container {
      max-width: 100%;
      .elementor-column-wrap {
        padding: 0;
        .link-wapper {
          text-align: right;
          a {
            background-color: #4b9823;
            height: 30px;
            padding: 0 15px;
            line-height: 30px;
            font-size: 18px;
            font-weight: 700;
            color: @white;
            display: inline-block;
            &:hover {
              background-color: #fff;
              color: #4b9823;
              opacity: .8;
            }
          }
        }
      }
    }
  }

  div.header-top {
    padding: 0;
  }
  .sticky-desktop-wrapper {
    height: initial !important;
  }

  #mobile-header-sticky {
    @media (max-width: 992px) {
      position: relative;
    }
  }

  div#desktop-header {
    background-color: @white;

    svg {

      &.logo,
      &.icon-search,
      &.icon-account,
      &.icon-basket,
      &.icon-phone {
        color: @green2;
      }
    }

    &.dark-header:not(.stuck-header) {
      svg {

        &.logo,
        &.icon-search,
        &.icon-account,
        &.icon-basket,
        &.icon-phone {
          color: @green;
        }
      }

      div.iqitmegamenu-wrapper nav.cbp-hrmenu li.cbp-hrmenu-tab>a {
        color: @green;
        border-color: @green;
      }
    }

    &.header-sticky {
      &.dark-header {
        svg {

          &.logo,
          &.icon-search,
          &.icon-account,
          &.icon-basket,
          &.icon-phone {
            color: @green2;
          }
        }

        div.iqitmegamenu-wrapper nav.cbp-hrmenu li.cbp-hrmenu-tab>a {
          color: @green2;
        }

        div.iqitmegamenu-wrapper nav.cbp-hrmenu li.cbp-hrmenu-tab:hover>a {
          color: @green;
        }

        div.icon .fa-phone {
          color: @green2;
        }

        a.nav-link {
          border-color: @green2;
        }

        #header-user-btn .fa-user:before {
          color: @green;
        }
      }
    }
  }

  div.container {
    max-width: 1388px;
  }

  div.col-header-left {
    margin: 0 41px 0 0;
    max-height: 132px;

    @media (max-width: 1350px) {
      margin: 0 10px 0 0;
    }

    div#desktop_logo {
      h1 {
        margin: 0; 
      }
      svg.logo {
        width: 230px;
        max-height: unset;
        fill: #005745;

        @media (min-width: 993px) and (max-width: 1100px) {
          width: 160px;
        }
      }
    }
  }

  div.col-header-center {
    div.col-header-right {
      div.header-icon-container {
        min-width: 130px;

        @media (max-width: 1400px) {
          margin-right: 0;
        }

        >div {
          padding: 0;
          width: 30px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  div.col-header-menu {
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title-menu {
      display: none;
      text-align: center;
      font-size: 38px;
      color: @green2;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 0;
      line-height: 1;
      width: 100%;
      padding: 0 24px;
      text-align: left;
    }

    div.iqitmegamenu-wrapper {
      width: 100%;

      nav.cbp-hrmenu {
        li.cbp-hrmenu-tab {
          &.pull-right {
            position: relative;
            top: 5px;
            margin-right: 33px;

            >a.nav-link {
              font-family: @font-base;
              font-weight: 500;
              font-size: 16px;
              border: 1px solid @white;
              line-height: 41px;
              height: 41px;
              padding: 0 17px;

              span {
                text-transform: uppercase;
                font-weight: 500;
              }

              @media screen and (max-width: 992px) {
                border-color: black;
                width: 100%;
                text-align: center;
                background-color: @green;

                span {
                  color: @black;
                  font-weight: 500;
                }
              }
            }
          }

          &.cbp-hropen,
          &:hover {
            >a {
              color: @green2;
              font-weight: 600;
            }

            .cbp-hrsub {
              @media screen and (max-width: 1024px) {
                position: fixed;
              }

              @media screen and (max-width: 992px) {
                position: relative;
              }
            }
          }

          >a {
            font-family: @font-base;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: @green2;
            max-width: initial;
            z-index: 1000;
            text-transform: uppercase;
            padding: 0 12px 0 17.5px;
            transition: 0.5s ease;

            @media (max-width: 1306px) {
              padding: 0 12px 0 0;
            }

            @media (max-width: 1025px) {
              padding: 0 5px;
            }

            @media (max-width: 992px) {
              height: 51px;
              line-height: 51px;
            }

            span {
              text-transform: uppercase;
              font-weight: 500;

              @media (min-width: 993px) and (max-width: 1195px) {
                font-size: 12px;
              }

              @media (min-width: 993px) and (max-width: 1030px) {
                font-size: 10px;
              }

              @media (max-width: 992px) {
                font-weight: 500;
                text-transform: initial;
              }

              i {
                display: inline-block !important;
                color: @green2;

                @media (max-width: 992px) {
                  display: none !important;
                }
              }
            }
          }

          //&.cbp-hrmenu-tab-9 {
          //	div.iqitmegamenu-submenu-container {
          //		div.menu_row {
          //			@media screen and (min-width: 992px) {
          //				div.cbp-menu-column {
          //					margin: 20px 0 0;
          //					padding: 0;
          //					flex: 0 0 16.66%;
          //					max-width: 16.66%;
          //				}
          //			}
          //		}
          //	}
          //}

          &.cbp-hrmenu-tab-10 {
            div.iqitmegamenu-submenu-container {
              div.menu_row {
                div.cbp-menu-column {
                  margin: 0 auto;
                  padding: 0;

                  @media (max-width: 992px) {
                    margin: 0 auto;
                  }
                }
              }
            }
          }

          &.cbp-hrmenu-tab-11 {
            div.iqitmegamenu-submenu-container {
              div.menu_row {
                justify-content: center;
                align-items: flex-start;

                div.cbp-menu-column {
                  margin: 0 auto;
                  padding: 0;

                  div.cbp-menu-column-inner {
                    a.cbp-column-title {
                      width: max-content;
                    }
                  }
                }
              }
            }
          }
        }

        .cbp-hrsub {
          left: 0;

          .iqitmegamenu-submenu-container {
            >.menu_row>.cbp-menu-column {
              padding: 20px 10px;
              display: flex;
              align-items: center;

              &:last-child {
                padding-left: 30px;

                @media (max-width: 992px) {
                  padding-left: 0;
                }

                &:before {
                  // content: "";
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 1px;
                  height: 90%;
                  background-color: @gray3;
                  opacity: 30%;

                  @media (max-width: 1360px) {
                    content: initial;
                  }
                }

                .cbp-menu-column-inner {
                  width: 100%;
                }

                .card-description {
                  padding: 12px 23px;
                  border-radius: 5px;
                  background-image: url("@{img}bg-menu.svg");
                  background-size: cover;
                  margin: 16px 0 33px 22px;
                  min-height: 208px;

                  @media (max-width: 992px) {
                    margin: 0 0 20px;
                    padding: 10px;
                  }

                  h3 {
                    font-family: @font-base;
                    font-size: 20px;
                    font-weight: 600;
                    text-align: left;
                    color: @black;
                    margin-bottom: 6px;
                    @media (max-width: 992px) {
                      color: @white;
                    }
                  }

                  h4 {
                    font-family: @font-base;
                    font-size: 12px;
                    font-weight: 600;
                    text-align: left;
                    color: @black;
                    text-transform: uppercase;
                    margin-bottom: 3px;
                    @media (max-width: 992px) {
                      color: @white;
                    }
                  }

                  p {
                    font-family: @font-base3;
                    font-size: 14px;
                    font-weight: 600;
                    text-align: left;
                    color: @black;
                    line-height: 1.29;

                    @media (max-width: 992px) {
                      font-size: 12px;
                      color: @white ;
                    }
                  }
                }

                .cbp-products-big {
                  margin: 0;
                  width: 100%;

                  @media (max-width: 992px) {
                    flex-direction: column;
                  }

                  div[id^="trustpilot-widget-trustbox-"] {
                    display: none;
                  }

                  .product-grid-menu {
                    z-index: 1;

                    @media (max-width: 992px) {
                      flex: 0 0 100%;
                      max-width: 100%;
                    }
                  }

                  .product-miniature-container {
                    .btn {
                      width: 100%;
                      height: 34px;
                      font-family: @font-base;
                      font-size: 12px;
                      font-weight: bold;
                      line-height: 34px;
                      max-width: 180px;
                      background-color: @purple;
                      color: @white;
                      border: 1px solid @purple;
                      border-radius: 10px;

                      i {
                        &:before {
                          content: "";
                          width: 21px;
                          height: 20px;
                          background-image: url("@{img}Ncart-btn_1.svg");
                          display: block;
                          transition: all 0.3s ease-out;
                          background-repeat: no-repeat;
                          background-position: center bottom;
                        }

                        @media (max-width: 767.92px) {
                          display: none;
                        }
                      }

                      &:hover {
                        background-color: @white;
                        color: @purple;

                        i {
                          &:before {
                            color: @purple;
                            background-image: url("@{img}Ncart-btn-hover.svg");
                          }
                        }
                      }
                    }

                    .product_img_link {
                      height: 100%;

                      &:after {
                        content: initial;
                      }

                      img {
                        height: 100%;
                        width: 100%;
                        max-width: 157px;
                      }
                    }
                  }

                  p.product-title {
                    margin: 9px 0 2px;

                    a {
                      font-size: 17px !important;
                      font-weight: bold;
                      line-height: 20px;
                      text-align: center;
                      color: @green2;
                      text-transform: uppercase;
                      font-family: @font-base;
                      height: 40px;
                      display: block;

                      &:after {
                        content: initial;
                      }
                    }
                  }

                  div.product-description-short {
                    font-family: @font-base3;
                    font-size: 11px;
                    font-weight: 600;
                    line-height: 1.27;
                    text-align: center;
                    color: @black;
                  }

                  div.product-price-and-shipping {
                    margin: 6px 0;

                    span {
                      font-family: @font-base;
                      font-size: 18px;
                      font-weight: bold;
                      line-height: 1.22;
                      text-align: center;
                      color: @green2;
                    }
                  }
                }

                .cbp-products-list-one {
                  .product-miniature-container {
                    margin: 0;
                    width: 100%;

                    div[id^="trustpilot-widget-trustbox-"] {
                      display: none;
                    }

                    .product-add-cart {
                      flex-direction: column !important;
                      width: 100%;

                      form {
                        margin: 0 0 6px;
                        max-width: 100%;

                        .btn-transparent {
                          display: block;
                          border: solid 1px @gray-border;
                          font-family: @font-base;
                          font-size: 12px;
                          font-weight: 500;
                          text-align: center;
                          color: @green2;
                          text-transform: uppercase;
                          max-width: 100%;
                          height: 30px;
                          line-height: 30px;
                          background: transparent;

                          &:hover {
                            background: @green2;
                            color: @black;
                            border-color: @green2;
                          }
                        }
                      }

                      .btn-green {
                        height: 100%;
                        display: block;
                        font-family: @font-base;
                        font-size: 12px;
                        font-weight: bold;
                        max-width: 100%;
                        height: 36px;
                        line-height: 36px;

                        &:after {
                          content: initial;
                        }
                      }
                    }

                    .row {
                      @media (max-width: 1360px) {
                        display: flex;
                        flex-direction: column;
                      }

                      @media (max-width: 992px) {
                        flex-direction: row;
                      }

                      @media (max-width: 600px) {
                        flex-direction: column;
                      }
                    }

                    .reviews-loading {
                      flex: 0 0 215px;
                      max-width: 215px;
                    }

                    .product-thumbnail {
                      height: 100%;

                      &:after {
                        content: initial;
                      }

                      img {
                        width: 100%;
                        max-width: 215px;
                        height: 100%;
                      }
                    }

                    p.product-title {
                      margin: 0;
                      line-height: 1;
                      font-size: 18px;
                      margin-bottom: 11px;
                      display: block;

                      a {
                        font-size: 18px !important;
                        line-height: 1;

                        &:after {
                          content: initial;
                        }
                      }
                    }

                    div.product-description-short {
                      font-size: 17px;
                      margin-bottom: 20px;
                      line-height: 1;
                      font-weight: 500;
                    }

                    div.product-price-and-shipping span {
                      font-size: 22px;
                      font-weight: 700;
                      color: @purple;
                    }

                    div.product-description {
                      margin: 0;

                      ul {
                        li {
                          margin-bottom: 0;
                          font-size: 14px;
                          padding-left: 30px;

                          &:after {
                            content: "";
                            background-image: url("@{img}Picto-coche.jpg");
                            background-repeat: no-repeat;
                            background-size: contain;
                            width: 23px;
                            height: 23px;
                            display: inline-block;
                            position: absolute;
                            left: 0;
                            top: 2px;

                            @media (max-width: 768px) {
                              width: 15px;
                              height: 15px;
                              left: 6px;
                              top: -2px;
                            }
                          }
                        }
                      }
                    }

                    .btn.details {
                      height: 40px;
                      line-height: 40px;
                      background: @white;
                      color: @purple;
                      border: 1px solid @purple;
                      padding: 0 40px;
                      text-transform: initial;
                      font-weight: 700;
                      margin-top: 10px;

                      &::after,
                      &::before {
                        content: none !important;
                      }

                      &:hover {
                        background-color: @purple;
                        color: @white;
                      }
                    }
                  }
                }
              }
            }
          }

          .cbp-menu-column:nth-of-type(odd) {
            .cbp-column-title {
              margin-right: 30px;
            }
          }

          .cbp-hrsub-inner {
            padding: 30px;
            box-shadow: 0 3px 6px 0 rgba(@black, 0.16);

            @media (max-width: 1360px) {
              padding: 30px 10px;
            }
          }

          div.cbp-menu-column-inner {

            .x-product,
            .o-product,
            .a-product {
              display: none;
            }

            .active {
              display: block;

              @media (max-width: 992px) {
                display: none;
              }
            }

            

            .cbp-menu-column-inner {
              display: flex;
              padding: 0;
              align-items: center;
              margin-top: 5px;

              &[id^="pelement-"] {
                cursor: pointer;

                a {
                  @media (max-width: 992px) {
                    pointer-events: initial;
                    z-index: 1;
                  }
                }
              }

              &:hover {
                .cbp-column-title {
                  // background: @purple;
                  // color: @white !important;
                  // border-radius: 4px;
                  font-weight: 600;
  
                  span {
                    font-weight: 600;
                    // background: @purple;
                    // color: @black !important;
                    // border-radius: 4px;
                  }
                }
  
                a {
                  &:not(.cbp-column-title) {
                    &:after {
                      opacity: 1;
                      width: 57px;
                      height: 57px;
                    }
                  }
  
                  img {
                    max-width: 57px;
                    height: 57px;
                  }
                }
              }
              a {
                &:not(.cbp-column-title) {
                  height: 59px;

                  &:after {
                    content: "";
                    width: 100%;
                    background: transparent;
                    border: 2px solid @purple;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    transition: all 0.3s ease-in-out;
                    width: 49px;
                    height: 49px;

                    @media (max-width: 992px) {
                      content: none;
                    }
                  }
                }

                img {
                  max-width: 49px;
                  height: 49px;
                  transition: all 0.3s ease-in-out;
                }
              }



              .cbp-column-title {
                order: 2;
                font-family: @font-base;
                font-weight: 400;
                color: @black  !important;
                transition: all 0.3s ease-in-out;
                font-size: 16px;
                margin: 0;
                padding: 0px 0px 10px 0px;
                margin-top: 0;
                margin-bottom: 12px;
                margin-right: 10px;
                margin-left: 10px;
                border-bottom: 1px solid @green2;
                text-align: left;
                width: 100%;
                display: inline-block;
                position: relative;
                text-transform: uppercase;

                @media (max-width: 1440px) {
                  font-size: 14px;
                }
                @media (max-width: 992px) {
                  border: none;
                  padding: 5px 0;
                }

                span {
                  background: transparent;
                  box-shadow: none;
                  text-align: center;
                  display: block;
                  top: 0;
                  font-family: @font-base;
                  font-weight: 400;
                  color: @black;
                  font-size: 14px;
                  margin: 0;
                  padding: 5px 15px 5px 20px;
                  position: relative;
                  left: -10px;
                }
              }
            }
          }

          .iqitmegamenu-submenu-container {
            .menu_row {
              justify-content: flex-start;
              margin: 0;

              .cbp-menu-column {
                margin: 0;
                padding: 0;

                .all-product {
                  display: flex;
                  justify-content: center;
                  margin: 22px 0 0;

                  @media (max-width: 992px) {
                    margin: 10px 0;
                  }

                  a.nav-link {
                    border: solid 1px @purple;
                    border-radius: 10px;
                    background: transparent;
                    font-family: @font-base;
                    font-size: 12px;
                    font-weight: 600;
                    text-align: center;
                    color: @purple  !important;
                    height: 30px;
                    line-height: 30px;
                    text-transform: uppercase;
                    padding: 0 65px;
                    transition: all 0.3s ease-out;

                    @media (max-width: 992px) {
                      padding: 0;
                      left: 0;
                    }

                    &:hover {
                      background: @purple;
                      color: @white  !important;
                    }
                  }
                }

                .more {
                  margin: 0;

                  a.nav-link {
                    border: solid 1px @green2;
                    border-radius: 0;
                    background: @green2;
                    font-family: @font-base;
                    font-size: 11px;
                    font-weight: 700;
                    text-align: center;
                    color: @black !important;
                    height: 33px;
                    line-height: 33px;
                    text-transform: uppercase;
                    padding: 0 28px;
                    transition: all 0.3s ease-out;
                    margin-left: auto;
                    @media (max-width: 992px) {
                      color: @white !important;
                    }
                    &:hover {
                      background: @white;
                      color: @green2  !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  div.sticky-desktop-wrapper div#desktop-header.stuck-header {
    background-color: transparent;

    @media (min-width: 992px) {
      // background-image: url("@{img}bg-header-2.png");
      background-color: @white;
      min-height: 132px;
      z-index: 99999;
    }
  }

  div.sticky-desktop-wrapper div#desktop-header {
    background-repeat: no-repeat;
    background-position: top;
    box-shadow: none;
  }
}

body#module-ph_simpleblog-page,
body#module-ph_simpleblog-categorypage,
body#module-ph_simpleblog-category,
body#module-ph_simpleblog-list,
body#cms.cms-id-19,
body#category:not(.headerbg),
body#cms:not(.headerbg),
body#index {
  header#header {
    &.header-sticky {
      div.sticky-desktop-wrapper div#desktop-header.stuck-header {
        @media (min-width: 992px) {
          // background-image: url("@{img}bg-header-2.png");
          background-color: @white;
          min-height: 123px;
          padding: 0;
        }
      }
    }
  }
}

body:not(#index, #category, #module-ph_simpleblog-list, body#cms.cms-id-19, body#module-ph_simpleblog-page, body#module-ph_simpleblog-categorypage, body#module-ph_simpleblog-category),
body#category.headerbg {
  header#header div.sticky-desktop-wrapper #desktop-header {
    // background-image: url("@{img}bg-header-2.png");
    background-color: @white;

    background-repeat: no-repeat;
    background-position: top;
    box-shadow: none;

    @media (min-width: 992px) {
      padding: 0 0 17px;
    }
  }
}

section#wrapper {
  padding-top: 0;

  nav.breadcrumb {
    background-color: transparent;
    margin: 0;
    padding-top: 40px;

    @media (max-width: 992px) {
      padding-top: 10px;
      padding-left: 0;
      padding-bottom: 33px;
    }
    .container {
      padding: 0;
    }
    ul,
    ol {
      padding-left: 4px;


      li {

        a,
        span {
          font-size: 12px;
          color: @black;

          @media (max-width: 767.98px) {
            font-size: 10px;
            font-weight: 500;
          }
        }

        &:last-child {

          a,
          span {
            font-weight: 600;
          }
        }

        &:after {
          content: ">";
          color: @black;
          opacity: 1;
          position: relative;
          top: 1px;

          @media (max-width: 767.98px) {
            margin: 0 2px;
          }
        }
      }
    }

    .btn-back {
      @media (max-width: 992px) {
        display: none;
      }

      .btn {
        width: 100%;
        text-transform: initial;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(@gray10, 0.48);
        color: @green2;
        border-color: rgba(@gray10, 0.48);
        height: 34px;
        line-height: 34px;
        font-size: 12px;
        font-weight: 600;
        padding: 0 17px;

        @media (max-width: 668px) {
          margin: 5px 0;
        }

        &:hover {
          background-color: @green2;
          color: @white;
          border-color: @green2;

          i {
            &:before {
              filter: brightness(0) invert(1);
            }
          }
        }

        i {
          margin-right: 7px;
          color: @green2;
          //&:before {
          //	content: "";
          //	width: 9px;
          //	height: 18px;
          //	background-image: url("@{img}back-icon.svg");
          //	display: block;
          //	transition: all .3s ease-out;
          //}
        }
      }
    }

    .col:not(.col-md-12) {
      @media (max-width: 992px) {
        width: 100%;
        flex: 0 0 80%;
      }
    }

    #iqitproductsnav {
      @media (max-width: 768px) {
        text-align: right;
      }

      >a {
        &:first-child {
          margin-right: 40px;

          @media (max-width: 992px) {
            margin-right: 0;
          }
        }

        i {
          color: @green2;
        }
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  div.col-header-left {
    div#desktop_logo {
      svg.logo {
        max-width: 227px;
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  header#header {
    background-size: cover;
  }
}

@media screen and (max-width: 991.98px) {
  .header-mobile-sticky {
    padding-top: 154px;

    header#header {
      position: fixed !important;
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  header#header {
    background-size: cover;
    background-position-y: 0;
    background-color: @white;

    nav.header-nav {
      display: none;
    }

    div.col-header-menu .iqitmegamenu-wrapper .cbp-hrmenu .cbp-hrmenu-tab.pull-right {
      width: 100%;
      padding: 0 10px;

      >a.nav-link {
        padding: 0 20px;
        color: white;
      }
    }

    div#mobile-header {
      .m-nav-btn {
        color: @green2;
      }

      .col-mobile-menu-dropdown.show {
        background: transparent;

        .m-nav-btn {
          color: @white;
        }
      }

      svg.logo {
        max-height: 50px;
        height: 100%;
        fill: @green2;
      }

      .mobile-main-bar {
        padding: 10px 0;
        height: 70px;
      }

      div.col-mobile-logo {
        height: 100%;
      }
    }

    div#desktop-header,
    div.sticky-desktop-wrapper {
      display: block;
      height: 0;
    }

    div#_mobile_iqitmegamenu-mobile {
      display: none;
      opacity: 0;
    }

    div.header-top {
      padding: 0;
    }

    div.col-header-left,
    div.col-header-right {
      display: none;
    }

    div.col-header-menu {
      position: fixed;
      top: 0;
      left: -100%;
      height: 100vh;
      width: 100%;
      background-color: @gray-light2;
      padding: 15px 0 30px;
      transition: all 0.4s ease-in-out;
      z-index: 99999;
      flex-direction: column;


      &.open-menu {
        left: 0;
      }
      .title-menu {
        display: block;
      }

      .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        color: @purple;
        background-color: transparent;
        z-index: 102;

        i {
          font: normal normal normal 14px/1 FontAwesome;

          &:before {
            background-image: url("@{img}Delete.svg");
            background-repeat: no-repeat;
            content: "";
            width: 30px;
            height: 30px;
            background-size: contain;
            display: block;
          }
        }
      }

      div.iqitmegamenu-wrapper {
        top: 0;
        height: 100vh;
        overflow-y: auto;
        padding-bottom: 70px;
        padding-top: 50px;

        &::-webkit-scrollbar {
          display: none;
        }

        nav.cbp-hrmenu {
          ul {
            display: flex;
            flex-direction: column;
            padding: 0 20px 0;

            li.cbp-hrmenu-tab {
              font-size: 16px;
              text-align: left;

              &.pull-right {
                a.nav-link {
                  color: white;

                  span.cbp-tab-title {
                    color: white;
                  }
                }
              }

              a.nav-link {
                width: 100%;
                color: @black;
                background-color: @gray-light2;
                border-bottom: 1px solid @green2;
                margin: 5px 0;

                .cbp-tab-title {
                  font-size: 18px;
                  color: @black;
                }
              }

              span {
                font-size: 16px;
                text-align: left;
                text-transform: uppercase;
              }

              &.cbp-has-submeu {
                a.nav-link {
                  position: relative;

                  &:after {
                    content: "\f078";
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: 18px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 20px;
                    color: @black;
                    transition: all 0.4s ease-in-out;
                  }

                  &.open {
                    &:after {
                      transform: translateY(-50%) rotate(180deg);
                    }
                  }
                }
              }
            }
          }

          .cbp-hrsub {
            position: relative;
            visibility: visible;
            opacity: 1;
            left: 0;
            top: 0;
            display: none;
            //
            //&.open {
            //  display: block;
            //}

            .cbp-hrsub-inner {
              box-shadow: none;
              padding: 0;
              background-color: @gray-light2;
            }

            div.iqitmegamenu-submenu-container {
              div.menu_row {
                display: flex;
                align-items: center;

                div.cbp-menu-column {
                  flex: 0 0 100%;
                  max-width: 100%;
                  margin: 0;
                }

                div.cbp-menu-column-inner {
                  flex-direction: row;
                  margin: 0;
                  z-index: 1;

                  a.nav-link {
                    &:after {
                      display: none;
                    }
                  }

                  .cbp-column-title {
                    text-align: left;

                    span {
                      text-align: left;
                    }
                  }

                  .cbp-banner-image {
                    max-width: 40px;
                    margin-right: 15px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

body#module-ph_simpleblog-page,
body#module-ph_simpleblog-categorypage,
body#module-ph_simpleblog-category,
body#module-ph_simpleblog-list,
body#cms.cms-id-19,
body#category:not(.headerbg),
body#cms:not(.headerbg),
body#index {
  header#header {
    position: static;
  }
}

.banner {
  padding: 0px;
}

nav.header-nav {
  div.elementor-displayTop {
    .elementor-column-gap-default .elementor-element-populated {
      padding: 0;
    }
  }

  p {
    a {
      text-decoration: underline;
    }
  }
}

each(range(11), {
  @media screen and (min-width: 992px) {
    .cbp-menu-element.produit-@{value} {
      order: @value;
    }
  }
});