#blockcart-content {
  .cart-totals {
    border-top: 1px solid @green2;
  }
  .cart-subtotals {
    border-color: @green2;
  }
  .cart-products .col-info {
    .pb-1 {
      a {
        color: black;
      }
    }
    div.input-group {
      position: relative;

      .block-cart-product-quantity {
        border: 2px solid @gray-border2;
        width: 35px;
        height: 52px;
        border-radius: 1px;
      }
      .input-group-btn-vertical {
        margin-left: 5px;
      }
      button.btn.btn-touchspin {
        padding: 0;
        border: none;
        width: 25px;
        height: 25px;
        background-color: #fff;
        box-shadow: 0 2px 6px rgb(0 0 0 / 16%);
        border-radius: 50%;
        i {
          &:after,
          &:before {
            content: initial;
          }
        }
        &.bootstrap-touchspin-up {
          margin: 0 0 2px;
          &:before {
            content: "";
            background-image: url("@{img}up.svg");
            background-repeat: no-repeat;
            background-size: contain;
            width: 21px;
            height: 21px;
            display: inline-block;
            position: absolute;
            left: 7px;
            pointer-events: none;
            top: 52%;
            transform: translateY(-50%);
          }
        }

        &.bootstrap-touchspin-down {
          margin: 0;
          &:before {
            content: "";
            background-image: url("@{img}down.svg");
            background-repeat: no-repeat;
            background-size: contain;
            width: 21px;
            height: 31px;
            display: inline-block;
            position: absolute;
            top: 46%;
            transform: translateY(-50%);
            left: 8px;
            pointer-events: none;
          }
        }
      }
    }
  }
}