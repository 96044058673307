body,
html {
  height: 100%;
}

body {
  font-family: @font-base;
  font-size: 16px;
  line-height: 1.25em;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  padding-left: 0;
  list-style: none;
}

a:hover {
  color: @green;
  text-decoration: none;
}

p {
  font-size: 0.9375rem;
  font-weight: 400;
  color: @black;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: none;
}

.color,
.custom-checkbox input[type="checkbox"]+span.color {
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  margin: 0.3125rem;
  margin-right: 0.5rem;
  cursor: pointer;
  background-size: contain;
  border: 1px solid @green;

  &.active,
  &:hover {
    border: 2px solid @black;
  }
}

.facet-label {

  &.active,
  &:hover {
    .custom-checkbox span.color {
      border: 1px solid @green;
    }
  }
}

table {
  width: 100%;
  max-width: 420px;
  margin-top: 7px;
  thead {
    background: @beige3;
    tr {
      td {
        padding: 3px 10px !important;
        background: @beige3;
      }
      td p ,
      td {
        font-size: 12px;

        &:first-of-type {
          strong {
            text-align: left;
            display: block;
          }
        }
        &:last-of-type {
          p {
            strong {
              font-weight: initial;
            }
          }
        }
      }
    }

  }
  tr {
    background: @beige6;
    font-weight: 700;
    &:nth-child(even) {
      background: @white1;
    }

    td {
      padding: 1px 10px !important;
      font-size: 10px;
      border: none;
      &:last-of-type ,
      &:last-of-type p {
        text-align: center;
        font-weight: bold;
      }
      &:last-of-type {
        border-left: 1px solid rgba(@gray3, 20%);
      }
      p {
        text-align: right;
        font-size: 10px;
        @media (max-width:768px) {
          font-size: 10px !important;
        }
        strong {
          text-align: right;
        }
      }
    }
  }

  .thead-default {
    th {
      color: @black;

      &[scope=row] {
        color: @black;
        font-weight: 500;
      }

    }
  }

  tbody {
    th {

      &[scope=row] {
        color: @black;
        font-weight: 500;
      }

    }
  }
}


.h1,
.h2,
.h3 {
  color: @green2;
  text-transform: uppercase;
}

.h4 {
  color: @green2;
}

.btn-primary,
.btn-secondary {
  padding: 0.5rem 1.25rem;
  font-weight: 600;
  text-transform: uppercase;
}



.card {
  border: none;
}

label,
.label {
  font-size: 0.875rem;
  color: @black;
  text-align: right;
  @media screen and (max-width: 768px) {
    text-align: left;
  }
}

small.label,
small.value {
  font-size: 0.8125rem;
}

.form-control-label {
  padding-top: 0.625rem;
}

.form-control {
  padding: 0.5rem 1rem;
  color: @black;
  background: @gray-light;
  border: 1px solid @black;

  &:focus {
    color: @black;
    background-color: @white;
    outline: 0.1875rem solid @green;
  }
}

.input-group {
  &.focus {
    outline: none;
  }

  .form-control:focus {
    outline: none;
  }

  .input-group-btn {
    height: 100%;

    >.btn {
      padding: 0.625rem 1rem;
      margin-left: 0;
      font-size: 0.6875rem;
      font-weight: 500;
      color: #fff;
      text-transform: uppercase;
      border: 0;
      line-height: 20px;
      box-shadow: none;
      background-color: @purple;
      border: 1px solid @purple;
      &:hover {
        background-color: @white;
        color: @purple;
      }
      &[data-action="show-password"] {
        font-family: @font-base;
                font-weight: 600;
        padding: 0 10px;
        background-color: @purple;
        border: 1px solid @purple;
        font-size: 16px;
        &:hover {
          background-color: @white;
          color: @purple;
        }
      }
    }
  }
}

input,
textarea {

  /* stylelint-disable */
  &::-webkit-input-placeholder {
    color: @black;
  }

  &::-moz-placeholder {
    color: @black;
  }

  &:-ms-input-placeholder {
    color: @black;
  }

  &:-moz-placeholder {
    color: @black;
  }

  &:active {
    &::-webkit-input-placeholder {
      color: @green;
    }

    &::-moz-placeholder {
      color: @green;
    }

    &:-ms-input-placeholder {
      color: @green;
    }

    &:-moz-placeholder {
      color: @green;
    }
  }

  /* stylelint-enable */
}

.form-control-select {
  height: 2.625rem;
  padding: 0 0.5rem;
  background: @gray-light url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=") no-repeat scroll right 0.5rem center / 1.25rem 1.25rem;
}

.form-control-valign {
  padding-top: 0.5rem;
  display: flex;
}

.form-control-comment {
  padding-top: 0.5rem;
  font-size: 0.875rem;
  color: @gray;
  font-family: @font-base;

  @media screen and (max-width: 1110px) {
    padding-right: 0;
  }
}

.form-control-submit {
  &.disabled {
    color: @white;
    background: @green;
  }
}

form {
  .form-group {
    margin-bottom: 15px;
    &.has-error {
      input,
      select {
        outline: 0.1875rem solid @brand-danger;
      }

      .help-block {
        color: @brand-danger;
      }
    }
    &.form-birthday {
      .form-control-comment {
        display: none;
      }
    }
    &.form-optin,
    &.form-customer_privacy,
    &.form-newsletter {
      .form-control-label,
      .form-control-comment {
        display: none;
      }
      label,p,em {
        margin: 0;
        font-family: @font-base;
        font-size: 15px;
        line-height: 15px;
        font-weight: 400;
        color: #777;
        font-style: initial;
      }
    }
  }
}


.group-span-filestyle {
  label {
    margin: 0;
  }

  .btn-default {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: #fff;
    text-transform: uppercase;
    background: @green;
    border-radius: 0;
  }
}

.bootstrap-touchspin {
  display: inline-block;
  float: left;
  width: auto;

  input {
    &:focus {
      outline: none;
    }

    &.form-control,
    &.input-group {
      float: left;
      width: 3rem;
      height: 40px;
      padding: 0.175rem 0.5rem;
      color: @gray-darker;
      background-color: @white;
      border: 1px solid @green;
      text-align: center
    }
  }

  .btn-touchspin {
    @extend .btn-default;
    height: 40px / 2 + 0.0625rem;
    background-color: @white;
    border: 1px solid @green;

    &:hover {
      border: 1px solid @green;

      i {
        &:after {
          color: @green;
        }
      }
    }

  }

  .input-group-btn-vertical {
    float: left;
    width: auto;
    color: @gray-darker;

    .bootstrap-touchspin-up {
      border-radius: 0;
    }

    .bootstrap-touchspin-down {
      border-radius: 0;
    }

    .touchspin-up {
      &::after {
        content: "\E5CE";
      }
    }

    .touchspin-down {
      &::after {
        content: "\E5CF";
      }
    }

    i {
      // top: 0.0625rem;
      left: 0.1875rem;
      font-size: 0.9375rem;
      color: @green;
    }
  }
}

.radio-inline {
  font-family: @font-base;

  .custom-radio {
    margin-right: 0.5rem;
  }

  &:first-of-type {
    margin-right: 1rem;
  }
}

form {
  section {
    .form-informations {
      display: inline-block;
      margin-top: 0.25rem;
      margin-bottom: 1rem;
      color: @gray-darker;

      &-title,
      &-option {
        display: inline-block;
        margin-bottom: 1rem;
        font-size: 1.25rem;
      }
    }
  }

  .form-control-label {
    font-size: 0.875rem;
    font-weight: 600;
    color: @gray-darker;
    font-family: @font-base;
  }
}

.custom-radio {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid @gray-darker;
  background: white;
  margin-right: 1.25rem;

  input[type="radio"] {
    opacity: 0;
    cursor: pointer;
  }

  input[type="radio"] {
    &:checked+span {
      display: block;
      background-color: @gray9;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 0;
    }

    &:focus+span {
      border-color: @gray9;
    }
  }
}

.custom-checkbox {
  position: relative;
  display: flex;

  >label {
    display: flex;
  }

  input[type="checkbox"] {
    margin-top: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;

    ~span {
      display: inline-block;
      width: 21px;
      height: 21px;
      margin: 0;
      vertical-align: middle;
      cursor: pointer;
      border: 1px solid @gray12 !important;
      border-radius: 5px;
      position: relative;
      flex: 0 0 auto;

      .checkbox-checked {
        display: inherit;
        opacity: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: @pink;
        margin: 0;
        font-size: 0;
        position: relative;
        border-radius: 3px;
        padding: 0;
        &:before {
          content: "";
          background-image: url("@{img}Checked.png");
          background-repeat: no-repeat;
          background-size: contain;
          width: 15px;
          height: 15px;
          display: inline-block;
          position: absolute;
          top: 2px;
          left: 2px;
          pointer-events: none;
          z-index: 3;
          filter: brightness(0) invert(1);
        }
      }
    }

    &:checked~span {
      .checkbox-checked {
        opacity: 1;
      }
    }

    &:focus~span {
      border-color: @gray;
    }
  }

  label {
    text-align: left;
    cursor: pointer;
    padding-left: 0;
    margin: 0 0 0 8px;

    label {
      padding-left: 10px;
      margin: 0;

    }
    p,a {
      font-size: 14px;
      font-family: @font-base;
      text-align: left !important;
    }
  }

  p {
    margin: 0;
  }
}

.text-muted {
  font-size: 0.875rem;
}

.done {
  display: inline-block;
  padding: 0 0.8125rem;
  margin-right: 1.563rem;
  color: @brand-success;
}

.thumb-mask {
  >.mask {
    position: relative;
    width: 3.438rem;
    height: 3.438rem;
    margin: @small-space 0;
    overflow: hidden;
    border: @gray-light 1px solid;

    img {
      width: 55px;
      height: 55px;
    }
  }
}

.definition-list {
  dl {
    display: flex;
    flex-wrap: wrap;

    dt {
      font-weight: 500;
      font-family: @font-base;

    }

    dd,
    dt {
      flex: 0 0 45%;
      padding: 0.625rem;
      margin: 0.125rem;
      background: @gray-light;
      font-family: @font-base;

      &:nth-of-type(even) {
        background: @gray;
      }
    }
  }
}

.help-block {
  margin-top: @small-space;
}

.btn.disabled,
.btn.disabled:hover {
  color: @btn-disabled-color;
  background: @btn-disabled-background;
  opacity: 1;
}

.alert-warning {
  .material-icons {
    padding-top: @extra-small-space;
    margin-right: @small-space;
    font-size: 2rem;
    color: @warning;
  }

  .alert-text {
    padding-top: @small-space;
    font-size: 0.9375rem;
  }

  .alert-link {
    padding: @extra-small-space @medium-space;
    margin-left: @small-space;
    font-size: 0.8125rem;
    font-weight: 600;
    color: @btn-tertiary-color;
    border-width: 2px;
    border-radius: 2px;
  }

  ul li:last-child .alert-link {
    color: @white;
  }

  .warning-buttons {
    margin-top: @extra-small-space;
  }
}

.btn-warning {
  @include transition(all 0.4s ease-in-out);
}

.btn-tertiary-outline {
  color: @btn-tertiary-color;
  background-color: transparent;
  background-image: none;
  border: 0.15rem solid @btn-tertiary-color;
  border-color: @btn-tertiary-color;
  @include transition(all 0.4s ease-in-out);

  &:hover {
    color: @btn-tertiary-hover;
    border-color: @btn-tertiary-hover;
  }
}

.alert {
  font-size: 0.8125rem;
  a {
    font-size: 0.8125rem;
  }
}

.nav-item {

  .nav-link,
  .nav-separtor {
    font-weight: 600;
    color: @gray;

    &.active {
      color: @gray-darker;
    }
  }
}

.separator {
  margin: 0;
  border-color: rgba(@black, 0.25);
}

// modal-dialog-centered class added from bootstrap 4.0.0, adapted for bootstrap 4.0.0-alpha.5
.modal-dialog-centered {
  display: flex;
  align-items: center;
  // 30px corresponds to the default margin size from bootstrap 4.0.0-alpha.5 for modal-dialog class
  min-height: calc(100% - (30px * 2));
}

.popover {
  font-family: inherit;
}

input[type="number"] {
  /* stylelint-disable */
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  /* stylelint-enable */
}

#g-recaptcha {
  display: none;
}

.custom-title {
  margin: 0 0 40px;
  line-height: 1;
  text-align: center;
  position: relative;
  @media screen and (max-width: 641px) {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-bottom: 20px;
    background-color: @green;
    padding: 15px 0;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 1px;
    background-color: @green;
    @media screen and (max-width: 641px) {
      content: initial;
    }
  }
  span {
    position: relative;
    font-family: @font-base;
    line-height: 1;
    display: inline-block;
    background-color: @white;
    padding: 0 20px;
    font-size: 28px;
    text-transform: uppercase;
    font-weight: 700;
    color: @black;
    @media screen and (max-width: 641px) {
      background: none;
      color: @white;
      font-weight: 500;
    }
  }
}

.tabs {

  .tab-content {
    border: 2px solid @gray-light;
    margin-top: -2px;
    p {
      font-size: 16px;
      font-family: @font-base2;
      color: @black;
      line-height: 20px;
      margin: 0;
    }
  }
  .tab-pane {
    padding: 35px 14px 25px;
  }

  .nav-tabs {
    border: none;
    border-bottom: 2px solid @gray-light;
    .nav-item {
      float: left;
      margin: 0;
      max-width: 233px;
      width: 100%;
      @media screen and (max-width: 1110px) {
        width: 190px;
      }
      @media screen and (max-width: 992px) {
        width: 150px;
      }
      @media screen and (max-width: 575px) {
        width: auto;
      }
      &:not(:last-child) {
        margin: 0 30px 0 0;
        @media screen and (max-width: 992px) {
          margin: 0 10px 0 0;
        }
      }
    }
    .nav-link {
      position: relative;
      font-family: @font-base;
      font-size: 18px;
      text-transform: uppercase;
      background-color: @gray-light;
      color: @black;
      border: 2px solid @gray-light;
      border-bottom: 0;
      border-radius: 4px;
      display: block;
      text-align: center;
      height: 100%;
      transition: 0.3s ease-in-out;
      padding: 12px 10px;
      margin-bottom: -2px;
      @media screen and (max-width: 1110px) {
        font-size: 16px;
      }
      @media screen and (max-width: 992px) {
        font-size: 15px;
      }
      @media screen and (max-width: 575px) {
        font-size: 14px;
        padding: 12px 15px;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -3px;
        width: 64px;
        height: 4px;
        border-radius: 5px;
        background-color: transparent;
        transition: 0.3s ease-in-out;
      }
      &.active {
        background-color: @white;
        border-color: @gray-light;
        &:before {
          background-color: @green2;
        }
        &:hover {
          color: @green2;
          border-color: @gray-light;
          background-color: @white;
        }
      }

      &:hover {
        background-color: @white;
      }
    }
  }
}