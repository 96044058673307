footer#footer {
    background-color: @bgcolor-footer;

    div.footer-container {
        padding: 51px 0;

        > div.container {
            padding: 0 26px;
            max-width: 1265px;
            div.img-footer {
                max-width: 234px;
                width: 100%;
                svg {
                    color: @white;
                    fill: @white;
                }
            }
        }

    }
    .elementor-container {
        max-width: 1270px !important;
    }
    .elementor-widget-social-icons {
        .elementor-social-icons-wrapper {
            text-align: left;
            .elementor-icon {
                margin-right: 10px;
            }
            .elementor-social-icon {
                background: @white;
                i {
                    &:before {
                        color: @bgcolor-footer;
                    }
                }
            }
        }
    }

    div.payment-certif {
        margin: 0 auto;
        max-width: 1242px;

        div.block {
            display: flex;
            justify-content: center;

            .block-title {
                margin-bottom: 13px;
            }

            img {
                max-height: 40px;
                margin: 0 6px 0 0;
            }
        }
    }

    div.block-group {
        display: flex;
        justify-content: center;
        padding-left: 27px;

        &:first-child {
            justify-content: flex-start;

            .block-title {
                position: relative;
                padding-left: 34px;

                &::before {
                    content: "";
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMC40ODkiIGhlaWdodD0iMjguOTk3IiB2aWV3Qm94PSIwIDAgMzAuNDg5IDI4Ljk5NyI+DQogIDxwYXRoIGlkPSJzdGFyIiBkPSJNNDcxLjMyMSwyNjIuODVsMy42LDExLjA3NmgxMS42NDZsLTkuNDIyLDYuODQ1LDMuNiwxMS4wNzZMNDcxLjMyMSwyODVsLTkuNDIyLDYuODQ1LDMuNi0xMS4wNzYtOS40MjItNi44NDVoMTEuNjQ2WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ1Ni4wNzYgLTI2Mi44NSkiIGZpbGw9IiMyMGI2N2EiLz4NCjwvc3ZnPg0K);
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-size: contain;
                    height: 30px;
                    width: 30px;
                    position: absolute;
                    top: 0%;
                    left: 0;
                }

                span {
                    border-bottom: 1px solid transparent;
                }
            }

        }

        &:last-child {
            justify-content: flex-end;
        }

        div.block {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    div.block {
        &.block-toggle {
            margin-top: 36px;
            padding: 0 5px;
            justify-content: flex-start;

            &.block-newsletter {
                margin-top: 0;

                .block-title {
                    margin-bottom: 14px;
                    span {
                        @media (max-width:767.98px) {
                            font-size: 20px;
                        }
                        &:after {
                            @media (max-width:767.98px) {
                                content: initial;
                            }
                        }
                    }
                }
            }
        }

        .block-title {
            margin-bottom: 20px;
            padding: 0;

            span {
                display: inline-block;
                font-family: @font-base;
                font-weight: 600;
                font-size: 15px;
                line-height: 19px;
                color: @white;
                padding-bottom: 8px;
                text-transform: uppercase;
                border-bottom: 1px solid @white;
                letter-spacing: 0px;
            }
        }

        div.block-content {
            @media (max-width:767.98px) {
                opacity: 1;
                visibility: visible;
                height: auto;
                display: none;
            }
           
            ul {
                li {
                    margin: 0;

                    >span ,
                    >a {
                        font-family: @font-base;
                        font-weight: 400;
                        font-size: 14px;
                        color: @white;
                        padding: 0;
                        margin: 0;
                        line-height: 22px;
                        transition: all 0.4s ease-in-out;
                        cursor: pointer;
                        &:hover {
                            padding-left: 5px;
                            opacity: 0.8;
                        }

                        &:before {
                            content: initial;
                        }
                    }
                }
            }
        }
    }

    div.newsletter-columns {
        flex: 0 0 50%;
        width: 50%;
        padding: 36px 10px 0 91px;


        div.block {
            .block-title {
                span {
                    border: none;
                    font-family: @font-base;
                    font-weight: 600;
                    text-transform: initial;
                    font-size: 26px;
                    line-height: 29px;
                    color: @white;
                    margin-bottom: 0;
                    padding: 0;
                    letter-spacing: 0px;
                }
            }

            >p {
                font-family: @font-base;
                font-weight: 400;
                font-size: 12px;
                color: @white;
                margin-bottom: 17px;
                line-height: 23px;
                letter-spacing: 0.1px;
            }
        }
    }

    div.ps-emailsubscription-block {
        .error-news {
            margin-top: -20px;
            display: block;
            color: @red;
            padding-left: 5px;
        }
        div.newsletter-input-group {
            background-color: transparent;
            border: none;

            input {
                height: 45px;
                max-width: 306px;
                line-height: 45px;
                padding: 0 22px;

                &::placeholder {
                    color: @doveGray;
                    font-size: 18px;
                    font-family: @font-base;
                    font-weight: 500;
                }
                @media (max-width:768px) {
                    max-width: 240px;
                }

            }

            .btn {
                height: 45px;
                margin-left: 2px;
                padding: 0 46px;
                font-family: Arial;
                font-size: 18px;
                line-height: 45px;
                font-weight: 400;
                color: @green2;
                border-radius: 10px;
                background-color: @white;
                @media (max-width:768px) {
                    padding: 0;
                    min-width: 141px;
                }

                &:hover {
                    background-color: @green2;
                    color: @white;
                }
            }
        }

        div.ps-emailsubscription-conditions {
            display: none;
        }

        .gdpr_module {
            // display: none !important;
        }
        
    }

    #footer-container-main.footer-style-1 .ps-emailsubscription-block .gdpr_module {
        max-width: unset;
        width: 100%;
        .psgdpr_consent_message {
            color: @white;
        }
    }
    .block-title:after {
        display: none;
    }
}
div.elementor-displayFooterAfter {
    width: 100%;
    div.block-title {
        div.elementor-text-editor {
            p {
                display: inline-block;
                font-family: @font-base;
                font-weight: 700;
                font-size: 18px;
                color: @white;
                padding-bottom: 10px;
                border-bottom: 1px solid @white;
                letter-spacing: 0px;
                @media (max-width:768px) {
                    border: none;
                    font-size: 18px !important;
                    padding-bottom: 0;
                    margin-bottom: 30px;
                }
            }
        }
        iframe {
            position: relative;
            left: -61px;
        }
    }
    div.elementor-column-gap-default div.elementor-element-populated {
        padding: 0;
    }
}

@media screen and (max-width: 1024px) {
    footer#footer {



        div.newsletter-columns {
            padding: 0;
        }

        div.ps-emailsubscription-block {
            div.newsletter-input-group {
                .btn-light {
                    padding: 0 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    footer#footer {
        div.newsletter-columns {
            flex: 0 0 100%;
            width: 100%;
            order: -1;
            margin-top: 30px;
        }
        .ps-emailsubscription-block .newsletter-input-group {
            margin: 0 0 33px;
        }
        #footer-container-main.footer-style-1 .ps-emailsubscription-block .gdpr_module .custom-checkbox {
            width: 100%;
        }
        .elementor-displayFooterAfter {
            .elementor-col-16  {
                width: 33%;
                flex: 0 0 33%;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    footer#footer {
        div.payment-certif {
            margin: 0 -15px;
            div.block-group {
                padding: 0;
                flex: 0 0 100%;

                div.block {
                    width: 100%;
                    display: block;
                }
            }
        }
        .elementor-displayFooterAfter {
            .elementor-col-16  {
                width: 100%;
                flex: 0 0 100%;
            }
        }

        div.newsletter-columns {
            flex: 0 0 100%;
            width: 100%;
            order: -1;
            margin-bottom: 30px;

            div.block.block-toggle.block-newsletter {
                p.block-title {
                    margin-bottom: 5px;
                    font-size: 13px;
                    line-height: 23px;
                }
                div.block-title {
                    span {
                        font-size: 18px;

                        &::after {
                            content: initial;
                        }
                    }
                }

                >p {
                    font-size: 13px;
                    margin-bottom: 22px;
                }
            }
        }

        div.footer-container {
            padding: 30px 0;
        }


        div.img-footer {
            margin: 0 auto;
            margin-bottom: 50px;
            height: 70px;

            svg {
                height: 100%;
                width: 100%;
            }

        }

        div.block {
            margin-bottom: 15px;

            &.block-toggle {
                margin-top: 0;
                padding: 0;

                &.block-newsletter {
                    .block-content {
                        opacity: 1;
                        visibility: visible;
                        height: auto;
                        display: block;
                    }
                }

            }

            .block-title {
                span {
                    display: block;

                    &:after {
                        right: 0;
                        position: absolute;
                    }
                }
            }
        }


        div.ps-emailsubscription-block .newsletter-input-group input {
            margin-bottom: 0;
            flex: 1;
            height: 50px;
            max-width: 100%;
            width: 100%;
            padding: 0 15px;
        }

        div.ps-emailsubscription-block .newsletter-input-group .btn {
            min-width: 120px;
            flex: 0 0 120px;
            height: 50px;
            padding: 0 10px;
        }

    }

}
div#adveg-grecaptcha-legal {
    background-color: @bgcolor-footer;
    color: @white;
    font-size: 12px;
    @media (max-width:767.98px) {
        font-size: 0.5rem;
    }
    a {
        color: @white;
        font-size: 12px;
        @media (max-width:767.98px) {
            font-size: 0.5rem;
        }
    }
}

@media screen and (max-width: 541px) {
    footer#footer {
        div.ps-emailsubscription-block {
            div.newsletter-input-group {
                flex-direction: row;
                justify-content: center;
                align-items: center;



                .btn-light {
                    margin: 0;
                }
            }
        }
    }
}