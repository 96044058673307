h1.page-title {
  font-size: 60px !important;
  line-height: 60px ;
}
h2.page-title {
    font-size: 50px !important;
  line-height: 50px ;
}
h3.page-title {
    font-size: 40px !important;
  line-height: 40px;
}
h4.page-title {
    font-size: 30px !important;
  line-height: 30px;
}
h5.page-title {
    font-size: 25px !important;
  line-height: 25px;
}
h6.page-title {
    font-size: 20px !important;
  line-height: 20px;
}

@media screen and (max-width: 456px) {
  h1.page-title {
    font-size: 30px !important;
    line-height: 30px !important;
  }
  h2.page-title {
    font-size: 26px !important;
    line-height: 26px !important;
  }
  h3.page-title {
    font-size: 24px !important;
    line-height: 24px !important;
  }
  h4.page-title {
    font-size: 20px !important;
    line-height: 20px !important;
  }
  h5.page-title {
    font-size: 18px !important;
    line-height: 18px !important;
  }
  h6.page-title {
    font-size: 16px !important;
    line-height: 16px !important;
  }
}