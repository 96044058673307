@import "./partials/fonts";
@import "./partials/variables";
@import "./partials/_commons";
@import "./helper/form";
@import "./helper/type";
@import "./components/partials/header";
@import "./components/partials/footer";
@import "./components/miniature";
@import "./components/category";
@import "./font";
@import "./cards/cards";
@import "./components/elemontor";
@import "./components/product_carousel";
@import "./components/product";
@import "./components/temoignage";
@import "./components/mini-cart";
@import "./components/cart";
@import "./components/checkout";
@import "./components/customer";
@import "./components/cms";
@import "./components/banner";
@import "./components/orderConfirmation";
@import "./components/espace_presse.less";
@import "./components/home";
@import "./components/faq";
@import "./components/stores.less";
//@import "./components/NewCart";
@import "./components/popup";
@import "./components/NewCart";
////////// New Refonte //////////
@import "./components/Refonte/category";
@import "./components/Refonte/engagement";
@import "./components/Refonte/equipe";
@import "./components/Refonte/qualite";
@import "./components/Refonte/reassurance";
@import "./components/Refonte/histoire";
@import "./components/Refonte/event";
@import "./components/Refonte/pharma.less";


html, body {
	overflow-x: hidden;
	overflow-y: auto;
	height: auto;
	scroll-behavior: smooth;
	scroll-padding-top: 140px;
}

.transition {
	transition: opacity .3s cubic-bezier(.645, .045, .355, 1), visibility .3s cubic-bezier(.645, .045, .355, 1), transform .5s cubic-bezier(.23, 1, .32, 1);
}


div#cmpbox {
	border-top-color: @green;

	a.cmpboxbtn {
		background-color: @green;
		color: white;
	}
}

#back-to-top {
	width: 45px;
    height: 45px;
}
textarea, textarea.form-control {
	height: 80px;
}
.form-control:focus {
	outline: none;
}

#contact h1.page-title {
	font-size: 35px !important;
    line-height: normal;
	
}
#contact .breadcrumb  {
	padding-left: 0;
	.container {
		padding-left: 0;
	}
}

#contact #main {
	margin-bottom: 50px;
}

#cms #wrapper {
    margin-bottom: 50px;
}


.cart-grid-body .btn-secondary{
	background-color: #fff;
    border-color: #005845;
    color: #005845;
	padding-right: 50px;
	&::after {
		content: '\203A';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 20px;
		font-size: 35px;
		font-weight: 600;
	}
	&:hover {
		background-color: #005845;
		color: #fff;
		&::after {
			color: #fff;
		}
	}
}

.tp-mask-wrap {
	padding: 0 10px;
}

html, body {
    overflow: hidden;
    overflow-y: auto;
}

.elementor-element.elementor-element-aexhnbu {
	overflow: hidden;
}

section#wrapper div.elementor-displayWrapperBottom div.trustpilot-widget {
	padding: 30px 0;
	background: white;
}

article.product-miniature {
	overflow: hidden;

	div.trustpilot-widget {
		pointer-events: none;
		transition: all .2s cubic-bezier(.7, 0, .3, 1), transform .2s cubic-bezier(.7, 0, .3, 1);
		opacity: 1;

		iframe:first-child {
			// width: 160px !important;
		}

		iframe:last-child {
		}

		> a {
			display: none !important;
		}
	}

	div.trustpilot-widget {
		position: relative;
		&::after {
			// content: "";
			// background-color: white;
			// width: calc(56% - 70px);
			// height: 24px;
			// position: absolute;
			// right: 0;
			// top: 50%;
			// transform: translateY(-50%);
		}
		iframe {
			margin: 0 auto;
			transform: translateX(28px);
		}
	}
}
div#blockcart-modal-wrap {

	div#blockcart-notification {

		div#blockcart-close {
			position: absolute;
			right: 5px;
			top: 5px;
			width: 20px;
			height: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
body#contact {
	.contact-page-info {
		> .part {
			>p {
				font-size: 13px;
				line-height: 15px;
				strong {
					font-size: 18px;
					line-height: 22px;   
					margin-bottom: 10px;
					display: inline-block;
				}
			}
		}
		
	}
	.contact-rich {
		.part {
			@media (max-width: 768px) {
				display: flex;
				align-items: center;
			}
			.fa-map-marker:before {
				content: "\f041";
			}
		}
	}
	.page-content > .row{
		@media (max-width: 992px) {
			flex-direction: column-reverse;
		}
	}
	.contact-form {
		.form-group {
			.input-group .input-group-btn {
				height: 40px;
				> label {
					height: 100%;
				}
			}
		}
	}
}
ul.slick-dots {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px 0;
	li {
		&.slick-active {
			button {
				opacity: 1;
			}
		}
		button {
			width: 8px;
			height: 8px;
			display: inline-block;
			border-radius: 100%;
			background: @black;
			opacity: .2;
			font-size: 0;
			padding: 0;
			margin: 0 5px;
		}
	}
}
body {
	.by-wrapper,
	.by-hbg0001m.by-wrapper {
		z-index: 10;
	}
}
.policy {
	font-family: @font-base;
	font-size: 11px;
	line-height: 1.5;
	margin: 15px 0;
	a {
		font-weight: 700;
		text-decoration: underline;
		font-size: 11px;
	}
}