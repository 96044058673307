body#stores,
body#cms {
  div.category-header {
    padding: 28px 0 72px;
    min-height: 240px;
    display: flex;
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    div.category-image {
      margin-bottom: 0;
      width: 100vw;
      position: absolute;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      z-index: 0;
      top: 0;
      padding: 0;
      height: 100%;
      min-height: 240px;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        @media (max-width: 1400px) {
         object-fit: cover;
        }
      }
    }
    div.category-description {
      background-color: @green2;
      padding: 54px 26px 55px;
      max-width: 455px;
      margin: 0 0 0 162px;
      height: fit-content;
      width: 100%;
      z-index: 3;

      @media screen and (max-width: 1024px) {
        margin: 0;
        padding: 20px;
      }
      h1 {
        font-family: @font-base;
        font-weight: 600;
        font-size: 25px !important;
        text-align: center;
        color: @white;
        line-height: 1.2;
        margin: 0;

        @media screen and (max-width: 992px) {
          font-size: 19px !important;
        }
      }
    }
  }
  header.page-header {
    display: none;
  }
  section#wrapper {
    padding-bottom: 0;
  }
  section#main section.page-content {
    margin-bottom: 0;
  }
  div.elementor-row {
    z-index: 1;
  }
  &.temoignage {
    background-color: @beige2;
    .trustpilot-footer {
      display: none; 
    }
  }
}
div.etiquette-r {
  position: relative;
  &:after {
    content: '';
    background-image: url("@{img}etiquette-left.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: -10%;
    bottom: 0;
    display: block;
    width: 434px;
    height: 440px;

    @media (max-width:768px) {
      width: 334px;
    }
  }
}
div.etiquette-l {
  position: relative;
  &:before {
    content: '';
    background-image: url("@{img}etiquette-right.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: -7%;
    bottom: 0;
    display: block;
    width: 310px;
    height: 295px;
    @media (max-width:768px) {
      width: 210px;
    }
  }


}