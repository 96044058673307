.swiper-button-next,
.swiper-button-prev {
    top: 34.5%;
    color: @green2;
    &.swiper-button-disabled {
        opacity: .6;
    }

    &::after {
        font-size: 76px;
    }
}

.swiper-button-next {
    right: -67px; 
}

.swiper-button-prev {
    left: -42px; 
}

div.inline-text {
    .elementor-widget-container  {
        display: inline-block;
    }
}