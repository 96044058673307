.card-help {
    padding: 28px 30px;
    width: 100%;
    max-width: 757px;
    margin: 0 auto;

    @media (max-width:991.98px) {
        padding: 16px 30px;
        max-width: 600px;
    }

    @media (max-width:767.98px) {
        padding: 16px 0px;
    }

    .elementor-widget-wrap {
        display: flex;
        align-items: center;
        max-width: 548px;
        margin: 0 auto;
        @media (max-width:767.98px) {
            justify-content: center;
        }
    }

    &-img {
        flex: 0 0 152px;
        min-width: 152px;
        height: 152px;
        margin-right: 6px;
        z-index: 2;

        @media (max-width:991.98px) {
            flex: 0 0 100px;
            min-width: 100px;
            height: 100px;
            margin-right: 42px;
        }

        @media (max-width:767.98px) {
            flex: 0 0 71px;
            min-width: 71px;
            height: 71px;
            margin-right: 8px;
        }
    }

    &-text {
        font-size: 22px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 28px;
        position: relative;

        @media (max-width:991.98px) {
            font-size: 21px;
            line-height: 28px;
            letter-spacing: 1.6px;
        }

        @media (max-width:767.98px) {
            font-size: 16px;
            line-height: 18px;
            letter-spacing: 1.2px;
        }
        &:before {
            content: "";
            width: 110%;
            height: 100%;
            background-color: @lightgreen;
            display: inline-block;
            position: absolute;
            right: 0;
            pointer-events: none;
            top: 50%;
            transform: translateY(-50%);
            z-index: 0;
            @media (max-width:767.98px) {
                width: 120%;
            }
        }
        .elementor-text-editor {
            position: relative;
            padding: 12px 24px 12px 0;
            @media (max-width:767.98px) {
                padding: 6px 24px 6px 0;
            }
        }
        p {
            margin-bottom: 0;
            font-size: 25px;
            font-weight: 600;
            color: @white;
            @media (max-width:767.98px) {
                font-size: 12px;
            }

            a {
                font-size: 25px;
                font-weight: 600;
                color: @white;
                @media (max-width:767.98px) {
                    font-size: 12px;
                }

            }
        }
    }
}