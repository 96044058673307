.s-temoignage {
    &.elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 1243px;
    }
    .h2 {
        .elementor-heading-title {
            text-transform: uppercase;
            font-weight: 600;
            color: @green2;
            font-family: @font-base;
        }
    }
    .swiper-slide-inner {
        background-color: white;
        padding: 35px 30px 10px;

        @media (max-width:767.98px) {
            width: auto;
        }
    }

    .swiper-slide {
        padding: 0 33.5px;
    }



    .elementor-testimonial-wrapper {
        .elementor-testimonial-content {
            margin: 0;
            strong {
                font-size: 40px;
                line-height: 49px;
                display: inline-block;
                margin-bottom: 20.6px;
                color: @green;
                font-weight: normal;
                letter-spacing: -1px;

                @media (max-width:991.98px) {
                    font-size: 22px;
                    line-height: 31px;
                    margin-bottom: 12px;
                }
                @media (max-width:767.98px) {
                    font-size: 20px;
                    line-height: 28px;
                    margin-bottom: 7px;
                }
            }

            .h3 {
                font-family: 'Architects Daughter', 'cursive';
                font-size: 33px;
                margin-bottom: 25px;
                text-transform: initial;
            }

            p:not(.h3) {
                font-size: 15px;
                line-height: 19px;
                min-height: 114px;
                @media (max-width:767.98px) {
                    font-size: 13px;
                    line-height: 18px;
                }
            }
        }

        div.elementor-testimonial-meta {

            div.elementor-testimonial-meta-inner {
                display: flex;
                justify-content: start;
                align-items: center;
                @media (max-width:767.98px) {
                    justify-content: center;
                }

                div.elementor-testimonial-image {
                    img {
                        height: auto;
                        width: 120px;
                        border-radius: 100%;

                        @media (max-width:767.98px) {
                            width: 100px;
                        }
                    }
                }

                div.elementor-testimonial-details {
                    .elementor-testimonial-name {
                        font-size: 16px;
                        font-weight: bold;
                        margin-bottom: 5px;

                        @media (max-width:768px) {
                            font-size: 13px;
                            line-height: 16px;
                        }
                    }

                    div.elementor-testimonial-job {
                        color: #E5D826;
                        font-size: 1rem;
                    }
                }
            }
        }
    }


    .swiper-button-next,
    .swiper-button-prev {
        background-color: transparent;

        @media (max-width:1024px) {
            display: none;
        }

        &:after {
            @media (max-width: 768px) {
                font-size: 26px;
                top: 50%;
            }
        }
    }
    .swiper-button-next {

        @media (max-width:991.98px) {
            right: -20px;
        }
        @media (max-width: 768px) {
            right: 0;
        }
    }
    .swiper-button-prev {

        @media (max-width:991.98px) {
            left: -20px;
        }
        @media (max-width: 768px) {
            left: 0;
            right: initial;
        }
    }
    &:before {
        content: '';
        background-image: url("@{img}s-temoignage-left.png");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -30px;
        bottom: 0;
        display: block;
        width: 310px;
        height: 295px;
        @media (max-width:768px) {
            width: 115px;
            bottom: -100px;
        }

    }

    &:after {
        content: '';
        background-image: url("@{img}s-temoignage-right.png");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        right: -170px;
        bottom: 0;
        display: block;
        width: 434px;
        height: 440px;

        @media (max-width:768px) {
            width: 130px;
            right: -45px;
            top: 50px;
            bottom: initial;
        }

    }
    .why-btn {
        .elementor-button {
            margin: 0 auto;
        }
    } 
        
}
.testimonial-profile {
    display: flex;
    align-items: center;
    margin-top: 31px;
    &-info {
        padding: 0 20px;
    }
    .name {
        font-size: 15px;
        font-weight: bold;
        display: block;
        text-align: left;
        padding-left: 2px;
        margin: 0 0 15px;
        line-height: 1;
    }
    &-rating {
        img {
            max-width: 110px;
        }
    }
    &-img {
        position: relative;
        bottom: 8px;
        margin-left: auto;
    }
}

.box-temo-white {
    border-radius: 5px;
    box-shadow: 0 0 6px 0 #0000003b;
    overflow: hidden;
}