body#cms.page-cms.cms-id-32  ,
body#cms.page-cms.cms-id-29  {
    #wrapper {
        margin-bottom: 0;
    }
    #inner-wrapper {

        max-width: initial;
    }
    nav.breadcrumb .container  {
        max-width: 1367px;
    }
    div.category-header {
        max-width: 1270px;
        margin: 0 auto;
    }
}
div.category-header div.category-image img {
    object-position: right;
    object-fit: cover;
}
body#cms.page-cms.cms-id-32 section#main ,
body#cms.page-cms.cms-id-29 section#main {
    div h3{
        @media (max-width: 767px) {
            font-size: 19px;
        }
    }
    .mini-card {
        margin: 0 auto;
        h3 {
            @media (max-width: 767px) {
                font-size: 14px;
            }
        }
    }
    .efficacite-produit {
        @media (max-width: 767px) {
            width: 50% !important;
        }
    }
    .title-page {
        h2 {

            font-size: 38px;
            font-weight: bold;
            font-family: 'Montserrat';
            line-height: 47px;
            letter-spacing: 0px;
            text-transform: initial;
            color: #005845;
            text-align: center;
            strong {
                font-weight: 400;
                font-size: 50px;
                @media (max-width: 992px) {
                    font-size: 45px;
                }
            }
            @media (max-width: 992px) {
                font-size: 22px;
                line-height: 26px;
            }
        }
    }
    .contact-pharma {
        .right-text-form {
            p {
                font-size: 16px;
                line-height: 26px;
            }
        }
        .title {
            text-align: center;
            span {
                font-size: 23px;
                font-weight: normal;
                font-family: 'Montserrat';
                line-height: 28px;
                letter-spacing: 0px;
                color: #005845;
                text-align: center;
                span {
                    font-size: 29px;
                    font-weight: bold;
                }
            }
        }

        .Par-tele {
            margin-bottom: 13px;
            display: flex;
            @media (max-width: 767px) {
                justify-content: space-between;
            }
            label {
                font-size: 16px;
                font-weight: 700;
                font-family: 'Montserrat';
                line-height: 29px;
                letter-spacing: 0px;
                color: #005845;
                margin-right: 31px;
                @media (max-width: 992px) {
                    margin-right: 21px;
                    font-size: 14px;
                }
                @media (max-width: 767px) {
                    font-size: 14px;
                    margin-right: 20px;
                }
            }
            .col-lg-12 {
                padding: 0 !important;
            }
            .phone {
                p {
                    border-radius: 30px;
                    outline: 2px solid #939AA1;
                    background: #fff;
                    padding-right: 13px;
                    font-size: 24px;
                    font-weight: 700;
                    font-family: 'Arial';
                    line-height: 29px;
                    letter-spacing: 0px;
                    color: #939AA1;
                    max-width: 332px;
                    display: flex;
                    align-items: center;
                    @media (max-width: 992px) {
                        font-size: 12px;
                    }
                    a {
                        font-size: 24px;
                        font-weight: 700;
                        font-family: 'Arial';
                        line-height: 29px;
                        letter-spacing: 0px;
                        color: #939AA1;
                        &:hover {
                            opacity: .8;
                        }
                        @media (max-width: 992px) {
                            font-size: 12px;
                        }
                    }
                    > span {
                        background: #939AA1;
                        border-radius: 30px;
                        margin-right: 16px;
                        font-size: 23px;
                        line-height: 29px;
                        color: white;
                        padding: 0 7px;
                        @media (max-width: 992px) {
                            font-size: 11px;
                        }
                    }
                }
                > span {
                    display: block;
                    text-align: right;
                    max-width: 332px;
                    padding-right: 13px;
                    font-size: 12px;
                    font-weight: 600;
                    font-family: 'Arial';
                    line-height: 14px;
                    letter-spacing: 0px;
                    color: #939AA1;
                }
            }
        }
        h3 {
            text-align: center;
            margin: 0;
        }
        .Par-form {
            label {
                font-size: 16px;
                font-weight: 700;
                font-family: 'Montserrat';
                line-height: 29px;
                letter-spacing: 0px;
                color: #005845;
                @media (max-width: 992px) {
                    font-size: 14px;
                    margin-top: 20px;
                    text-align: center;
                    display: block;
                }
            }
        }
        .form-control-label {
            text-transform: uppercase;
            @media (max-width: 992px) {
                font-size: 11px;
                padding-right: 0;
            }
            span {
                color: #B42B6A;
            }
        }
        .Obligatoire {
            margin-top: -25px;
            span {
                color: #B42B6A;
            }
        }
        input.form-control {
            background: #fff;
            border: 1px solid #005845;
            height: 40px;
            padding: 12px 10px;
            &::-webkit-input-placeholder {
                color: @doveGray;
            
            }

            &::-moz-placeholder {
                color: @doveGray;
            }

            &:-ms-input-placeholder {
                color: @doveGray;
            }

            &:-moz-placeholder {
                color: @doveGray;
            }
        }
        textarea.form-control ,
        input.form-control {
            &::-webkit-input-placeholder {
                color: @doveGray;
                @media (max-width: 992px) {
                    font-size: 11px;
                }
            }

            &::-moz-placeholder {
                color: @doveGray;
                @media (max-width: 992px) {
                    font-size: 11px;
                }
            }

            &:-ms-input-placeholder {
                color: @doveGray;
                @media (max-width: 992px) {
                    font-size: 11px;
                }
            }

            &:-moz-placeholder {
                color: @doveGray;
                @media (max-width: 992px) {
                    font-size: 11px;
                }
            }
        }
        .form-footer  {
            padding-right: 9%;
            @media (max-width: 767px) {
                padding-right: 0;
            }
        }
        label.error {
            font-size: 10px;
            bottom: -12px;
            margin-left: 10px;
            color: #B42B6A;
        }
        .policy {
            font-size: 9px;
            font-weight: 400;
            font-family: 'Montserrat';
            line-height: 13px;
            letter-spacing: 0px;
            color: @black;
            padding: 0 16px;
            margin-top: 15px;
            a {
                font-weight: 700;
                font-size: 9px;
                line-height: 13px;
                text-decoration: underline;

            }
        }
    }
    .reassurance {
        &::after {
            content: none;
        }
    }
    .block-actifeProduit {
        .title-normal {
            span {
                span {
                    font-size: 40px;
                    @media (max-width: 767px) {
                        font-size: 30px;
                    }
                }
            }
        }
        .products-actife {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            @media (max-width: 992px) {
                display: block;
            }
            .slick-list {
                overflow: hidden;
            }
            .slick-slide {
                float: left;
            }
            .produit-wapper {
                max-width: 218px;
                padding: 0 8px;
                margin-bottom: 12px;
                &:first-of-type {
                    @media (min-width: 992px) {
                        margin-left: 5%;
                    }
                }
                .description {
                    p {
                        font-size: 13px;
                        font-weight: normal;
                        font-family: 'Arial';
                        line-height: 15px;
                        letter-spacing: 0px;
                        color: @black;
                        text-align: center;
                        strong {
                            font-weight: 700;
                            color: #005845;
                        }
                    }
                }
            }
        }
    }
    .border-bottom {
        position: relative;
        border: none !important;
        &::after {
            content: "";
            position: absolute;
            height: 1px;
            width: 1440px;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            background-color: #005845;
        }
    }
    .directeur-block {
        .directeur-name {
            span {
                display: block;
                font-size: 15px;
                font-weight: normal;
                font-family: 'Montserrat';
                line-height: 19px;
                letter-spacing: 0px;
                color: #005845;
                &:first-line {
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }
        .directeur-description {

        }
    }
    .garanties {
        .title-cart {
            p {
                text-align: center;
                font-size: 14px;
                font-weight: normal;
                font-family: 'Montserrat';
                line-height: 18px;
                letter-spacing: 0px;
                color: #005845;
            }
        }
    }
    .box-title {
        text-align: center;
        h2 {
            display: inline-block;
            max-width: 529px;
            width: 100%;
            height: 136px;
            padding-top: 27px;
            background: @green2;
            font-size: 40px;
            font-weight: 700;
            color: @white !important;
            text-transform: uppercase;
            line-height: 40px;
            @media (max-width: 767px) {
                padding: 20px;
                font-size: 25px;
                height: auto;
                width: 90%;
                line-height: 35px;
            }
            span > span {
                font-size: 26px;
                text-transform: initial;
                font-weight: normal;
                @media (max-width: 767px) {
                    font-size: 15px;
                }
            }
        }
    }
}
