body#order-confirmation {
  .container{
    max-width: 1193px;
  }
  .order-confirmation-title-payment {
    border: none;
  }
  h1.page-title {
    text-align: center;
    font-size: 40px !important;
    font-weight: 700;
    padding: 0;
    margin: 0 0 5px;
    @media (max-width: 768px) {
      font-size: 28px!important;
      line-height: 1.2;
      text-transform: uppercase;
      margin-bottom: 50px;
      text-align: left;
    }
    span {
      position: relative;
      padding-left: 46px;
      @media (max-width: 768px) {
        padding: 0;
      }
      i {
        position: absolute;
        left: -9px;
        &:before {
          content: "";
          width: 57px;
          height: 46px;
          background-image: url("@{img}check.svg");
          display: block;
          @media (max-width: 768px) {
            content: initial;
          }
        }
      }
    }

  }
  div.mail-sent-info {
    background-color: transparent;
    padding: 0;
    margin: 0 0 43px;
    text-align: center;
    @media (max-width: 768px) {
      margin-bottom: 33px;
    }
    p {
      background-color: rgba(@beige2, 0.4);
      font-size: 16px;
      color: @black;
      font-weight: 400;
      display: inline-block;
      padding: 18px 30px;
      margin: 0;
      @media (max-width: 768px) {
        font-size: 14px;
        padding: 0px 20px;
      }
      i {
        &:before {
          content: "";
          width: 22px;
          height: 22px;
          background-image: url("@{img}mail.svg");
          display: block;
          margin-right: 7px;
          background-size: contain;
        }
      }
    }
  }
  .order-confirmation-table {
    background-color: @beige;
    padding: 0 0 5px 0;
    margin-bottom: 23px;
    box-shadow: 10px 10px 13px -9px rgb(@black , 35%);
  }
  #order-details ,
  #content-hook_payment_return{
    background-color: @beige2;
    padding: 20px 31px 1px;
    margin-bottom: 18px;
    box-shadow: 10px 10px 13px -9px rgb(@black , 35%);
    h3.card-title {
      font-size: 18px;
      font-weight: 700;
      padding: 0;
      color: @green2;
      margin: 0 0 14px;
    }
    ul li ,
    p {
        font-size: 16px;
        font-weight: 500;
        padding: 0 0 10px;
        color: @black;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
  #order-items {
    .img-miniature {
      @media (max-width: 768px) {
        flex: 0 0 121px;
        max-width: 121px;
        padding: 0;
      }
      .image {
        @media (max-width: 768px) {
          text-align: left;
        }
        img {
          @media (max-width: 768px) {
            margin: 0;
          }
        }
      }
    }
    .details {
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        flex-wrap: wrap;
        padding: 0 0 0 15px;
        flex: 0 0 calc(100% - 121px);
        max-width: calc(100% - 121px);
      }
      > span {
        @media (max-width: 768px) {
          flex: 0 0 60%;
          max-width: 60%;
        }
      }
      > div.unit-price {
        font-family: @font-base;
        font-weight: 400;
        font-size: 19px;
        color: @black;
        @media (max-width: 768px) {
          flex: 0 0 40%;
          max-width: 40%;
          padding: 0;
        }
      }

      .qty-mobile {
        display: none;
        @media (max-width: 768px) {
          padding: 0 7px !important;
          margin: 20px 0 0;
          display: block;
        }
        .row {
          @media (max-width: 768px) {
            padding: 0 15px 0 0;
          }
          .text-center {
            @media (max-width: 768px) {
              text-align: left !important;
            }
          }
          .text-center,
          .text-right {
            @media (max-width: 768px) {
              font-size: 18px;
              font-weight: bold;
            }
            span {
              display: none;
              @media (max-width: 768px) {
                display: inline-block;
                font-size: 16px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
    div.head {
      background-color: @bgcolor-footer;
      padding: 11px 27px;
      margin: 0;
      &-title {
        font-family: @font-base;
        font-weight: 700;
        font-size: 18px;
        color: @white;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        @media (max-width: 1024px) {
          font-size: 18px;
        }
        @media (max-width: 992px) {
          padding: 0;
        }
        @media (max-width: 668px) {
          max-width: 100%;
          flex: 0 0 100%;
        }
        &.text-center,
        &.text-right {
          justify-content: center;
        }
      }
      .qty {
        margin-bottom: 0;

        .head-title {
          @media (max-width: 668px) {
            display: none;
          }
        }
      }
    }
    .order-line {
      margin: 24px 0 14px 0;
      padding: 0 20px;
      @media (max-width: 768px) {
        padding: 0 7px;
        margin: 14px 0 24px 0;
      }
      .image {
        text-align: center;
        display: block;
        img {
          max-width: 121px;
          width: 100%;
          height: 121px;
          object-fit: cover;
          box-shadow: 0 3px 6px rgba(@black, .16);
        }
      }
      .qty {
        padding: 0 40px 0 20px;
        @media (max-width: 768px) {
          display: none;
        }
        .text-center {
          @media (max-width: 768px) {
            text-align: left !important;
          }
        }
      }
      .details span,
      .qty .row > div {
          font-family: @font-base;
          color: @black;
        font-size: 15px;
        line-height: 19px;
        text-transform: uppercase;
        font-weight: 700;
        &.bold {
          font-size: 23px;
          font-weight: bold;
        }
      }
    }
  }
  table {
    max-width: 100%;
    border: none;
    padding: 0 31px;
    display: block;
    width: 100%;
    @media (max-width: 768px) {
      padding: 0 7px;
    }
    tbody {
      width: 100%;
      display: block;
    }
    tr {
      background-color: @white;
      margin: 0 0 16px 0;
      border: none;
      display: table;
      width: 100%;
      td {
        border: none;
        font-size: 20px;
        font-weight: 600;
        color: @green2;
        padding: 13px 31px 16px !important;
        @media (max-width: 668px) {
          font-size: 16px;
          padding: 13px 31px !important;
        }
        &:last-of-type {
          font-width: 700;
        }
      }
      &.totals {
        td {
          font-size: 23px;
          text-transform: uppercase;
          font-weight: 700;
          @media (max-width: 668px) {
            font-size: 18px;
          }
        }
      }
    }
  }
  .buttons-order {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 50px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      padding: 0 0 20px;
    }
    .others {
      width: 100%;
      margin-right: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @beige4;
      color: @bgcolor-footer;
      border-color: @beige4;
      @media (max-width: 768px) {
        margin: 5px 0 14px;
        height: 37px;
        line-height: 37px;
        font-size: 14px;
        font-weight: 700;
        max-width: 80%;
      }
      @media (max-width: 468px) {
        font-size: 13px;
        max-width: 100%;
      }
      &:hover {
        background-color: @bgcolor-footer;
        color: @beige4 ;
        border-color: @bgcolor-footer;
        i {
          &:before {
            filter: brightness(0) invert(1);
          }
        }
      }
      i {
        margin-right: 22px;
        @media (max-width: 768px) {
          margin-right: 12px;
        }
        &:before {
          content: "";
          width: 9px;
          height: 18px;
          background-image: url("@{img}back-icon.svg");
          display: block;
          transition: all .3s ease-out;
        }
      }
    }
    .account {
      width: 100%;
      margin-left: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: @white;
      color: @pink;
      border-color: @pink;
      @media (max-width: 768px) {
        margin: 0;
        height: 37px;
        line-height: 37px;
        font-size: 16px;
        font-weight: 700;
        flex-direction: row-reverse;
        max-width: 80%;
      }
      @media (max-width: 468px) {
       font-size: 13px;
        max-width: 100%;
      }
      &:hover {
        background-color: @pink;
        color:@white;
        border-color: @pink;
        i {
          &:before {
            background-image: url("@{img}account-icon-hover.svg");
          }
        }
      }
      i {
        margin-left: 22px;
        @media (max-width: 768px) {
          margin: 0 12px 0 0;
        }
        &:before {
          content: "";
          width: 16px;
          height: 17px;
          background-image: url("@{img}account-icon.svg");
          display: block;
          transition: all .3s ease-out;
        }
      }
    }
  }
}