.my-account-page-content-wrapper {
    margin: 0 auto;
    .my-account-side-links {

        >a {
            font-size: 19px;
            font-weight: 500;
            padding: 20px 5px;
            color: @green2;
            &:hover {
                color: @green2;
            }
            .link-item {
                display: flex;
                >i {
                    margin-right: 5px;
                }
            }
        }
    }
    form#customer-form {
        span.custom-checkbox > label {
            display: initial;
        }
    }
    div.form-group {
        button.btn-outline-secondary {
            height: 40px;
            padding: 0 10px 0;
            line-height: 47px;
            border: 1px solid @purple;
            i {
                position: relative;
                top: -3px;
                margin: 0;
            }
        }
    }
}
body#order-detail ,
body.page-addresses ,
body.page-customer-account {
    section#wrapper {
        padding-top: 0;
    }
    nav.breadcrumb {
        margin: 0;
    }
    div#content-wrapper {
        h1.page-title {
            font-size: 30px !important;
            padding-bottom: 5px;
        }
    }
    h2 {
        font-size: 27px;
        @media (max-width: 922px) {
            font-size: 18px;
            line-height: normal;
        }
    }
}
body#password, 
body#pagenotfound {
    section#wrapper div.container {
        max-width: 1100px;
    }
}
body#authentication {
    section#wrapper div.container { 
        max-width: 940px;
    }
    div#content-wrapper {
        h1.page-title {  
            font-size: 30px !important;
            padding-bottom: 5px;
        }
    }
    div.form-group {
        button.btn-outline-secondary {
            height: 40px;
            padding: 0 10px 0;
            line-height: 47px;
            i {
                position: relative;
                top: -3px;
                margin: 0;
            }
        }
    }
    .iqitsociallogin {
        display: flex;
        flex-flow: wrap;
        justify-content: center;
        a.btn-iqitsociallogin {
            margin: 15px;
            text-transform: initial;
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 0;
            @media (max-width: 768px) {
                font-size: 14px;
            }


            &.btn-facebook {
                border: none;
                background-color: @fb-btn;
                padding: 0 24px;

                &:hover {
                    i {
                        color: white;
                    }
                }

                i {
                    font-size: 25px;
                    margin-right: 0;
                }
            }

            &.btn-google {
                border: none;
                background-color: @purple !important;
                padding: 0 30px;
                display: flex;

                span {
                    display: flex;
                    align-items: center;
                    background: transparent;
                    margin-right: 17px;
                    position: relative;
                    top: -2px;
                    padding: 0;
                    img {
                        filter:  brightness(0) invert(1);
                        width: 23px;
                    }
                }
            }
        }
    }
    #login-form {

    }
}
body#history {
    #content {
        table {
            max-width: initial;

            tr {
                border: none;
            }

            thead {
                border: none;

                th {
                    vertical-align: bottom;
                    border-bottom: 2px solid @beige5;
                }
            }

            .label {
                text-align: left;
            }

            .order-actions {
                display: flex;
                flex-direction: column;
                border: none;

                a {
                    padding: 5px 0;
                    font-size: 13px;
                    text-decoration: underline;
                }
            }
        }
    }
}
body#authentication {
    label.col-form-label {
        @media (max-width: 1024px) {
            padding: 5px 15px;
        }
    }
}
body#password {
    .form-fields .btn.btn-primary {
        @media (max-width: 1024px) {
            font-size: 15px;
        }
        @media (max-width: 768px) {
            font-size: 12px;
            width: 100%;
        }
    }
    .trustpilot-footer {
        display: none;
    }
}