body#product {
  .container {
    max-width: 1387px;
  }
  #axeptio_main_button {
    bottom: 70px !important;
  }
  nav.breadcrumb {
    padding-top: 15px;
    padding-bottom: 25px;
    @media (max-width: 768px) {
      padding-top: 00px;
      .container {
        padding: 0;
      }
    }
  }
  #content-wrapper {
    .section-title.h2 {
      @media (max-width: 768px) {
        font-size: 16px;
        text-transform: uppercase;
        padding: 30px;
        line-height: 1.5;
      }
    }
    .swiper-button-next,
    .swiper-button-prev {
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .products-grid .product-miniature .desc-min p {
    text-transform: initial;
  }
  .h2 {
    .elementor-heading-title {
      text-transform: uppercase;
      font-weight: 600;
      color: @green2;
      font-family: @font-base;
    }
  }
  .reco {
    h3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      color: @black;
    }
  }
  .first-price {
    font-family: @font-base;
    font-weight: 700;
    font-size: 35px;
    color: @lightgreen4;
    margin: 0 0 18px 0;
    display: block;
    line-height: 1;
  }
  section#wrapper {
    padding-top: 6px;

    .breadcrumb {
      margin: 0;
    }
  }
  .tabs {
    margin: 0;
    @media screen and (max-width: 992px) {
      display: none;
    }
    .tab-content {
      margin: 0;
      border: 0;
    }
  }

  .ps-emailsubscription-block .psgdpr_consent_message {
    font-size: 16px;
    font-weight: 500;
  }

  div.product_header_container {
    border-bottom: none;
    padding-bottom: 0;

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  div#col-product-info {
    padding-left: 0px;
    position: unset;

    @media (max-width: 991.98px) {
      padding-left: 0;
    }

    .product-description {
      max-width: 575px;
      margin-bottom: 18px;
    }

    div[id^="trustpilot-widget-trustbox"] {
      position: static;
      right: 0;
      top: 0;
      width: 196px;
      margin-bottom: 16px;
      cursor: pointer;
      
      @media (max-width: 1440px) {
        right: 10px;
      }
      @media (max-width: 768px) {
        right: initial;
        left: 2px;
        top: 0;
        margin-bottom: 26px;
       
      }
      iframe {
        height: 27px;
        position: absolute !important;
        left: 0;
        top: -16px;
      }
    }
  }

  .product_header_container {
    margin: 0;
    .h1.page-title {
      margin-bottom: 9px;
      font-family: @font-base;
      font-weight: 700;
      font-size: 25px !important;
      line-height: 40px;
      color: @green2;
      text-transform: uppercase;
      @media (max-width: 992px) {
        padding-top: 15px;
      }
      @media (max-width: 767.98px) {
        font-size: 22px !important;
      }
    }
  }

  div.product-info-row {
    @media (max-width: 767.98px) {
      margin-bottom: 0;
    }

    .images-container,
    .after-cover-tumbnails2 {
      margin: 0;
      .swiper-wrapper {
        @media (max-width: 767.98px) {
          justify-content: center;
        }
      }
    }
  }
  .new-title {
    font-family: @font-base;
    font-weight: 700;
    font-size: 20px;
    color: @green2;
    margin: 15px 0;
    max-width: 575px;
  }

  div.product-information {
    div.product-description {
      p {
        font-family: @font-base;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        color: @black;
        margin-bottom: 30px;
        text-transform: initial;

        @media (max-width: 768px) {
          font-size: 14px;
          line-height: 1.23;
          margin-bottom: 0;
        }
      }
    }

    div.product-description-all {
      position: relative;
      margin-bottom: 37px;
      @media (max-width: 768px) {
        margin-bottom: 21px;
      }
      .inner {
        @media (max-width: 768px) {
          overflow: hidden;
          -webkit-transition: all 0.5s ease;
          -moz-transition: all 0.5s ease;
          -o-transition: all 0.5s ease;
          transition: all 0.5s ease;
        }

        &:before {
          @media (max-width: 768px) {
            // content: "";
            display: block;
            position: absolute;
            font-size: 12px;
            left: 0;
            right: 0;
            bottom: 0;
            height: 20px;
            width: 100%;
            text-align: center;
            background: linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0.2) 0%,
              rgba(255, 255, 255, 0.8) 50%,
              rgba(255, 255, 255, 1) 100%
            );
          }
        }
        &.opened {
          @media (max-width: 768px) {
            height: auto;
            overflow: visible;
            &:before {
              bottom: -80px;
            }
          }
        }
      }
      .less-more-show-btn {
        display: none;
        @media (max-width: 768px) {
          background: none;
          border: 0;
          position: absolute;
          left: 0px;
          bottom: -16px;
          font-family: @font-base3;
          font-size: 15px;
          font-weight: 400;
          text-align: left;
          color: @green2;
          cursor: pointer;
          text-decoration: underline;
          z-index: 3;
          padding: 0;
        }
        &.show-more {
          bottom: -10px;
        }
      }

      ul,
      ol {
        list-style: none !important;
        margin-bottom: 0;

        li {
          font-family: @font-base;
          font-weight: 500;
          font-size: 17px;
          line-height: 20px;
          color: @black;
          position: relative;
          padding-left: 36px;
          margin-bottom: 11px;
          @media (max-width: 767px) {
            font-size: 13px;
            line-height: 13px;
            padding-left: 27px;
            margin-bottom: 7px;
          }
          &:after {
            content: "";
            background-image: url("@{img}Picto-coche.jpg");
            background-repeat: no-repeat;
            background-size: contain;
            width: 23px;
            height: 23px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 2px;
            @media (max-width: 768px) {
              width: 15px;
              height: 15px;
              left: 6px;
              top: -2px;
            }
          }
        }
      }

      p {
        font-family: @font-base3;
        font-weight: 400;
        font-size: 15px;
        color: @black;
        margin: 0;
        line-height: 23px;
        strong {
          font-size: 20px;
          color: @green2;
          display: inline-block;
          padding: 14px 0 7px;
          font-family: @font-base;
        }
      }
    }
    .gelule p {
      font-weight: 400;
      margin: 0;
      line-height: 23px;
      font-size: 20px;
      color: @green2;
      display: inline-block;
      padding: 14px 0 7px;
      font-family: @font-base;
    }
    div#medsaveonproduct {
      .btn-lg {
        background-color: #a2bf5e;
        padding: 0;
        width: 212px;
        height: 25px;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        min-width: 212px;

        &:before {
          content: "!";
          font-family: @font-base3;
          font-size: 10px;
          font-weight: bold;
          background-color: white;
          color: @orange2;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px;
        }

        span {
          font-size: 13px;
          letter-spacing: 0;
          color: @white;
          font-weight: 600;
          text-transform: initial;
        }
      }
    }
  }

  div.product-additional-info {
    display: none;
  }

  div.product-actions {
    margin-bottom: 21px;
    @media (max-width: 768px) {
      // position: fixed;
      // bottom: 0;
      // left: 0;
      // width: 100%;
      // margin: 0;
      // background: @white;
      // z-index: 5;
      // box-shadow: 0 -10px 30px rgba(@gray3, 0.27);
    }

    form {
      display: flex;
      align-items: center;
      width: 100%;
      flex-wrap: wrap;

      section.product-pack {
        width: 100%;
        padding: 15px 15px 7px;
        margin: 35px 0 0;

        p.h4 {
          margin-top: -35px;
          text-align: center;
          font-weight: 700;
          font-size: 18px;
        }
        article {
          padding: 0;
        }
      }

      div.price-group {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      div.product_p_price_container {
        width: 25%;
        flex: 0 0 25%;
        border: none;
        margin: 0;
        padding: 0 40px 0 0;
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;

        @media (max-width: 767.92px) {
          flex: 0 0 40%;
          width: 40%;
          padding: 0;
          margin-top: 0;
        }
        &:before {
          @media (max-width: 767.92px) {
            content: "";
            width: 1px;
            height: 33px;
            top: 50%;
            transform: translateY(-50%);
            background-color: @gray11;
            position: absolute;
            left: 20%;
          }
        }
        &:after {
          @media (max-width: 767.92px) {
            content: "";
            width: 1px;
            height: 33px;
            top: 50%;
            transform: translateY(-50%);
            background-color: @gray11;
            position: absolute;
            right: 20%;
          }
        }
        #medpriceqtyid {
          width: 185px;
          min-width: initial;
          .btn {
            font-size: 11px;
          }
        }

        div.product-prices {
          order: 2;
          .price-countdown-wrapper {
            display: none;
          }
        }

        div#medpriceqtyid {
          order: 1;
          margin-bottom: 2px;

          #medReversePrice {
            margin: 0;
          }
        }

        div.tax-shipping-delivery-label {
          display: none;
        }

        span.product-price {
          font-family: @font-base;
          font-weight: 700;
          font-size: 26px;
          color: @gray4;
          @media (max-width: 767.92px) {
            font-size: 18px;
            color: @lightgreen;
            margin: 0;
            display: block;
            line-height: 1;
          }
        }

        div.discount-group {
          display: inline-flex;
          flex-direction: column-reverse;

          .badge {
            font-family: @font-base;
            font-weight: 700;
            font-size: 13px;
            color: @white;
            display: block;
            max-width: fit-content;
            padding: 4px 10px;
            margin: 0 auto;
          }

          .product-discount {
            .regular-price {
              color: @gray2;
              font-family: @font-base;
              font-weight: 600;
              font-size: 18px;
            }
          }
        }
      }

      section.product-discounts {
        display: none;
      }

      div.product-add-to-cart {
        width: 100%;
      }

      div.product-quantity {
        display: flex;
        margin: 0;
        flex: 0 0 100%;
        align-items: center;
        justify-content: end;
        .qty {
          width: initial;
          position: relative;
          left: 0px;
          margin-bottom: 20px;
          display: none;
          @media (max-width: 767.98px) {
            left: initial;
          }
        }
      }

      div.col-add-qty {
        margin: 0;
        width: 100%;
        .qty_precise {
          font-family: @font-base;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          color: @black;
          margin-bottom: 11px;
          text-align: right;
          cursor: pointer;
          @media (max-width: 767.98px) {
            margin-bottom: 23px;
          }
        }
      }
      div.col-add-qty {
        div.input-group {
          position: relative;
          margin-left: 43px;
          position: relative;
          margin-bottom: 0;
          @media (max-width: 767.98px) {
            overflow: hidden;
            margin: 0;
            margin-bottom: 10px;
          }

          span.input-group-btn-vertical {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
          }

          input.input-group {
            border: 1px solid rgba(@gray3, 0.16);
            width: 60px;
            height: 40px;
            border-radius: 1px;
            font-size: 25px;
            font-weight: bold;

            @media (max-width: 767.98px) {
              height: 42px;
              width: 88px;
              font-size: 18px;
              text-align: left;
              padding: 10px;
            }
          }

          button.btn.btn-touchspin {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            padding: 0;
            border: none;
            width: 41px;
            height: 41px;
            background-color: @white;
            box-shadow: 0 2px 6px rgba(#000, 0.16);
            @media (max-width: 767.98px) {
              width: 21px;
              height: 21px;
              background-color: @white2;
            }
            &.bootstrap-touchspin-up {
              right: -45px;
              @media (max-width: 767.98px) {
                right: 0;
                top: -20px;
                transform: initial;
              }
              i {
                &:after,
                &:before {
                  content: initial;
                }
              }

              &:before {
                content: "";
                background-image: url("@{img}up.svg");
                background-repeat: no-repeat;
                background-size: contain;
                width: 21px;
                height: 21px;
                display: inline-block;
                position: absolute;
                left: 13px;
                top: 11px;
                pointer-events: none;
                @media (max-width: 767.98px) {
                  width: 14px;
                  height: 14px;
                  left: 5px;
                  top: 3px;
                }
              }
            }

            &.bootstrap-touchspin-down {
              left: -45px;
              @media (max-width: 767.98px) {
                right: 0;
                left: initial;
                bottom: 0;
                transform: initial;
              }
              i {
                &:after,
                &:before {
                  content: initial;
                }
              }

              &:before {
                content: "";
                background-image: url("@{img}down.svg");
                background-repeat: no-repeat;
                background-size: contain;
                width: 21px;
                height: 31px;
                display: inline-block;
                position: absolute;
                top: 5px;
                left: 15px;
                pointer-events: none;
                @media (max-width: 767.98px) {
                  width: 14px;
                  height: 14px;
                  left: 7px;
                  top: 3px;
                }
              }
            }
          }
        }
      }

      div.col-add-btn {
        .add-to-cart {
          font-family: @font-base;
          font-weight: 700;
          width: 310px;
          font-size: 14px;
          height: 42px;
          line-height: 42px;
          text-align: center;
          background-color: #b42b6a;
          border-radius: 5px;
          border: 1px solid #b42b6a;

          @media (max-width: 992px) {
            font-size: 12px;
            height: 50px;
            line-height: 50px;
            padding: 0 25px;
            margin: 10px 0;
          }
          @media (max-width: 767.92px) {
            height: 36px;
            line-height: 36px;
            padding: 0 5px;
            margin: 0;
            font-size: 13px;
            font-weight: 600;
            width: 100%;
          }
          i {
            &:before {
              content: "";
              width: 21px;
              height: 20px;
              background-image: url("@{img}Ncart-btn_1.svg");
              display: block;
              transition: all 0.3s ease-out;
              background-repeat: no-repeat;
              background-position: center;
            }
            @media (max-width: 767.92px) {
              display: none;
            }
          }
          &:hover {
            background-color: @white;
            color: @purple;
            i {
              &:before {
                color: @purple;
                background-image: url("@{img}Ncart-btn-hover.svg");
              }
            }
          }
        }
      }
    }

    &.hide {
      .product_p_price_container {
        display: none;
      }

      .product-add-to-cart {
        max-width: 100%;
        flex: 0 0 100%;

        .col-add-qty {
          margin: 0;
        }

        .col-add-btn {
          width: 100%;
          flex: 0 0 100%;
          text-align: right;
        }
      }
    }
  }
  .payment-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(@lightgreen3, 0.47);
    padding: 17px 18px;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;
    }
    &-text {
      font-family: @font-base;
      font-size: 14px;
      font-weight: 500;
      color: @gray-darker2;
      position: relative;
      padding-left: 60px;
      margin: 0;
      @media (max-width: 768px) {
        font-size: 12px;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 41px;
        height: 41px;
        background-image: url("@{img}paimenx3.svg");
        background-repeat: no-repeat;
        @media (max-width: 768px) {
          top: 65%;
        }
      }
      &:after {
        @media (max-width: 768px) {
          content: "";
          position: absolute;
          left: 52px;
          top: 65%;
          transform: translateY(-50%);
          width: 1px;
          height: 39px;
          background-color: @beige2;
        }
      }
    }
    &-link {
      font-family: @font-base;
      font-weight: 500;
      font-size: 15px;
      color: @green2;
      text-decoration: underline;
      cursor: pointer;
      @media (max-width: 768px) {
        font-size: 12px;
        padding-left: 60px;
        margin-top: -11px;
      }
    }
  }
  div#main-product-wrapper {
    div.product-lmage-large {
      flex: 0 0 100%;
      max-width: 100%;

      .easyzoom-product {
        pointer-events: none;
      }
    }

    div.fp-accordion-body.reviews-container {
      padding-top: 25px;
      div.elementor-widget-wrap {
        div.elementor-widget-wrap {
          display: none;
        }
      }
      .egio-accordion-box {
        .elementor-column-wrap {
          display: none;
        }
      }

      div.elementor-container {
        div.elementor-row {
          div[id^="trustpilot-widget-trustbox"] {
            width: 100%;

            & + div.elementor-column {
              display: none;
            }
          }
        }
      }
    }
  }

  .col-product-image {
    // margin-top: -27px;

    @media (max-width: 991.98px) {
      margin-top: 0;
    }
    .col-left-product-thumbs {
      @media (max-width: 991.98px) {
        margin-top: 0;
        margin-bottom: 9px;
      }
    }
    .product-flags,
    .tages {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      z-index: 10;
      @media (max-width: 767px) {
        left: 8px;
        top: 8px;
      }
      .product-flag,
      .plantes {
        width: 73px;
        height: 22px;
        text-align: center;
        display: inline-block;
        background-color: rgba(@green2, 0.1);
        font-family: "arial";
        font-size: 13px;
        line-height: 22px;
        color: @green2;
        padding: 0;
      }
      .prefere {
        width: 73px;
        height: 22px;
        text-align: center;
        display: inline-block;
        background-color: @green2;
        font-weight: 300;
        font-family: "arial";
        font-size: 10px;
        line-height: 22px;
        color: @white;
      }
    }
    .product-flags {
      right: 0;
      left: initial;
    }

    .product-cover {
      @media (max-width: 991.98px) {
        margin-bottom: 0;
      }
      &:hover {
        .product-flags {
          opacity: 1;
          visibility: visible;
        }
      }
      video {
        height: auto;
        width: 100%;
        margin-top: 87px;
      }
    }
    #product-images-thumbs {
      .swiper-wrapper {
        @media (max-width: 992px) {
          justify-content: center;
        }
      }
      .swiper-slide {
        height: 90px !important;
        @media (max-width: 992px) {
          margin: 0 8px;
        }
      }
      .thumb-container {
        @media (max-width: 992px) {
          padding: 0;
          margin: 0;
        }
        img {
          width: 79px;
          height: 79px;
          object-fit: cover;
          border: 1px solid rgba(@gray3, 0.2);
          padding: 2px;
        }
      }
      .swiper-slide-thumb-active {
        .thumb-container {
          img {
            border-color: @green2;
          }
        }
      }
    }
  }

  .elementor-displayFooterProduct {
    padding-left: 35px;
    @media (max-width: 767.98px) {
      padding: 0 0 15px;
    }
  }

  .col-product-info {
    padding-right: 0;
    padding-top: 13px;
    @media (max-width: 1440px) {
      padding-right: 15px;
      padding-left: 15;
      #col-product-info {
        padding: 0;
      }
    }
    .elementor-column-gap-default .elementor-element-populated {
      padding: 0;
    }

    .elementor-element.elementor-element-v2gnl1v {
      margin-top: 12px;
      margin-bottom: 0px;
    }

    .card-reasurance {
      margin-bottom: 20px;

      @media (max-width: 767.98px) {
        margin-bottom: 20px;
      }

      div.card-reasurance-title {
        p.elementor-heading-title {
          a {
            font-size: 13px;
          }
        }
      }

      &:nth-child(1) {
        flex: 0 0 35%;
        @media (max-width: 1025px) {
          flex: 0 0 33.33%;
          min-width: 33.33%;
          margin-left: 20px;
          padding: 0;
        }
        @media (max-width: 991.98px) {
          flex: 0 0 33.33%;
          min-width: 33.33%;
          margin-left: 20px;
          padding: 0;
        }

        @media (max-width: 767.98px) {
          flex: 0 0 100%;
          min-width: 100%;
          padding-left: 0px;
          margin-left: 0px;
        }

        .card-reasurance-img {
          flex: 0 0 80px;

          @media (max-width: 767.98px) {
            flex: 0;
          }
        }
      }

      &:nth-child(2) {
        flex: 0 0 46%;
        padding-left: 35px;

        @media (max-width: 1025px) {
          flex: 0 0 33.33%;
          min-width: 33.33%;
          margin-left: 20px;
          padding: 0;
        }
        @media (max-width: 991.98px) {
          flex: 0 0 33.33%;
          min-width: 33.33%;
          margin-left: 20px;
          padding: 0;
        }

        @media (max-width: 767.98px) {
          flex: 0 0 100%;
          min-width: 100%;
          padding-left: 0px;
          margin-left: 0px;
        }

        .card-reasurance-img {
          flex: 0 0 52px;

          @media (max-width: 767.98px) {
            flex: 0;
          }
        }
      }

      &:nth-child(3) {
        flex: 1;
        padding-left: 13px;

        @media (max-width: 1025px) {
          flex: 0 0 33.33%;
          min-width: 33.33%;
          margin-left: 20px;
          padding: 0;
        }
        @media (max-width: 991.98px) {
          flex: 0 0 33.33%;
          min-width: 33.33%;
          margin-left: 20px;
          padding: 0;
        }

        @media (max-width: 767.98px) {
          flex: 0 0 100%;
          min-width: 100%;
          padding-left: 0px;
          margin-bottom: 20px;
          margin-left: 0px;
        }

        .card-reasurance-img {
          flex: 0 0 40px;

          @media (max-width: 767.98px) {
            flex: 0;
          }
        }
      }
    }
  }
  div.wrapper-p {
    position: relative;
    padding-left: 25px;

    p {
      font-family: @font-base;
      font-weight: 500;
      font-size: 17px;
      color: @green2;
      position: relative;
      margin-bottom: 5px;
      @media (max-width: 767.98px) {
        font-size: 13px;
        line-height: 1.2;
      }
      strong {
        font-weight: 700;
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      background-image: url("@{img}info.png");
      background-repeat: no-repeat;
    }
  }
  div.model2.row {
    margin: 0;
  }
  div.product-pack {
    border: none;
    padding: 0;
    margin: 0;

    &-group {
      border: 1px solid @lightgreen;
      padding: 0 21px 0 13px;
      display: flex;
      align-items: center;
      @media (max-width: 767.98px) {
        flex-direction: column;
        padding: 25px 20px;
      }
    }

    &-img {
      max-width: 155px;
      width: 100%;
      text-align: center;
    }

    &-desc {
      max-width: 418px;
      width: 100%;
      padding: 8px 10px 0 0;
      @media (max-width: 1360px) and (min-width: 1024px) {
        max-width: initial;
        width: auto;
      }
      p.label {
        font-family: @font-base;
        font-weight: 700;
        font-size: 19px;
        color: @lightgreen3;
        margin-bottom: 4px;
        text-align: left;
        text-transform: uppercase;
        line-height: 1;

        @media (max-width: 1024px) {
          text-align: center;
        }
      }
      p.month {
        font-family: @font-base;
        font-weight: 700;
        font-size: 17.5px;
        color: @green2;
        margin-bottom: 1px;
        text-align: left;

        @media (max-width: 1024px) {
          text-align: center;
        }
        span {
          font-size: 20px;
          color: @lightgreen3;
        }
      }

      p.description {
        font-family: @font-base;
        font-weight: 400;
        font-size: 14px;
        color: @gray-darker2;
        line-height: 18px;
        strong {
          font-weight: 600;
        }
        @media (max-width: 1024px) {
          text-align: center;
        }
      }
    }

    &-price {
      padding: 0 41px 0 25px;
      @media (max-width: 1360px) and (min-width: 1024px) {
        padding: 0 10px 0 10px;
      }
      .old-price {
        font-family: @font-base;
        font-weight: 600;
        font-size: 20px;
        color: @gray5;
        margin: 0;
        padding: 0;
        text-align: right;
        text-decoration: line-through;
        @media (max-width: 767.98px) {
          text-align: center;
        }
      }

      .price {
        font-family: @font-base;
        font-weight: 700;
        font-size: 28px;
        color: @lightgreen;
      }
    }

    &-cart {
      @media (max-width: 1360px) and (min-width: 1024px) {
        min-width: 150px;
      }
      input.qty_discount {
        display: none;
      }

      p.econome {
        font-family: @font-base;
        font-weight: 700;
        font-size: 16px;
        color: @green2;
        margin-bottom: 5px;
        text-align: center;

        span {
          position: relative;
          padding-left: 25px;

          &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 18px;
            height: 18px;
            background-image: url("@{img}econome.png");
          }
        }
      }

      button.btn {
        font-family: @font-base;
        font-weight: 700;
        font-size: 12px;
        padding: 0 12px;
        @media (max-width: 1360px) and (min-width: 1024px) {
          font-size: 12px;
        }
        @media (max-width: 1440px) {
          height: initial;
          line-height: initial;
          padding: 10px;
        }
        &:hover {
          border-color: @lightgreen;
        }
      }
    }

    div.img-popup {
      position: relative;
      cursor: pointer;

      img {
        position: absolute;
        bottom: 100%;
        right: -50%;
        width: 312px;
        min-width: 312px;
        width: auto;
        height: auto;
        overflow: hidden;
        opacity: 0;
        transition: all 0.3s ease-in;
        @media (max-width: 1360px) {
          right: 0;
        }
      }

      &:hover {
        img {
          opacity: 1;
          overflow: visible;
        }
      }
    }

    &-group2 {
      margin: 0;
      display: flex;
      justify-content: space-between;
      @media (max-width: 1024px) {
        flex-direction: column;
      }

      .qty_discount {
        display: none;
      }

      .product-pack-labelDisc {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #737474;
        position: relative;
        cursor: pointer;
        padding: 0;
        height: 60px;
        width: 100%;
        margin-bottom: 11px;
        @media (max-width: 767.98px) {
          max-width: 100%;
          margin-bottom: 9px;
          flex-wrap: wrap;
          height: 90px;
          align-items: flex-end;
        }
        span {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
          width: 17px;
          height: 17px;
          border-radius: 5px;
          border: 1px solid @purple;
          @media (max-width: 767.98px) {
            left: 10px;
            top: 28%;
          }
          .checkbox-checked {
           
            &:before {
              color: @white;
              height: 12px;
              width: 15px;
            }
          }
        }
        &.checked {
          span {
            display: inline-block;
            margin: 0;
            vertical-align: middle;
            cursor: pointer;
            border: 1px solid @gray12 !important;
            flex: 0 0 auto;
      
            .checkbox-checked {
              display: inherit;
              opacity: 0;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              background-color: @pink;
              margin: 0;
              font-size: 0;
              position: relative;
              border-radius: 3px;
              padding: 0;
              animation: checkbox-bounce .3s cubic-bezier(.7,0,.3,1);
              display: block;
              &:before {
                content: "";
                background-image: url("@{img}Checked.png");
                background-repeat: no-repeat;
                background-size: contain;
                display: inline-block;
                position: absolute;
                top: 2px;
                left: 2px;
                pointer-events: none;
                z-index: 3;
                filter: brightness(0) invert(1);
              }
            }
          }
      
          span {
            .checkbox-checked {
              opacity: 1;
            }
          }
      
        }

        &::before {
          content: none;
          
        }
        &::after {
          content: none;
         
        }

        .left {
          padding-left: 50px;
          @media (max-width: 767.98px) {
            padding-left: 35px;
            width: 100%;
          }
          .title {
            margin: 0;
            font-family: @font-base;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: @black;
            margin-bottom: 6px;
            text-transform: none;
            text-align: left;
            @media (max-width: 767.98px) {
              font-size: 13px;
            }
            p {
              font-family: @font-base;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              color: @black;
              margin-bottom: 6px;
              text-transform: none;
              text-align: left;
            
              @media (max-width: 767.98px) {
                font-size: 13px;
              }
            }
          }
          .description {
            font-family: @font-base;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: @black;
            text-align: left;
            margin: 0;
            @media (max-width: 767.98px) {
              line-height: 12px;
              height: 24px;
              overflow: hidden;
            }
          }
        }
        .right {
          display: flex;
          align-items: center;
          @media (max-width: 767.98px) {
            width: 100%;
            height: 33px;
            justify-content: flex-end;
            align-items: center;
            padding: 0 10px;
            &.between {
              justify-content: space-between;
              background: @purple;
              .price {
                color: @white;

                .old-price {
                  color: @white;
                }
              }
            }
          }
          .block-percentage {
            font-family: @font-base;
            font-weight: 400;
            font-size: 11px;
            line-height: 22px;
            color: @white;
            background: @purple;
            width: 106px;
            padding: 5px 20px 0;
            text-align: center;
            height: 60px;
            display: block;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 0;
            display: block;
            padding: 5px 15px 0;
            strong {
              font-weight: 700;
              font-size: 25px;
              line-height: 25px;
              font-weight: bold;
            }
            @media (max-width: 767.98px) {
              width: auto;
              height: 30px;
              width: auto;
              padding: 0;
              br {
                display: none;
              }
              strong {
                font-size: 18px;
              }
            }
          }

          .price {
            font-family: @font-base;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: @black;
            width: 106px;
            padding-right: 20px;
            padding-left: 20px;
            text-align: right;
            margin: 0;
            height: 60px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            padding-bottom: 7px;

            .old-price {
              font-family: @font-base;
              font-weight: 400;
              font-size: 15px;
              line-height: 17px;
              color: @gray5;
              margin: 0;
              padding: 0;
              text-align: right;
              text-decoration: line-through;
              @media (max-width: 767.98px) {
                text-align: center;
                font-size: 14px;
                padding-left: 5px;
                color: @black;
              }
            }
            @media (max-width: 767.98px) {
              padding: 0;
              margin: 0;
              width: auto;
              height: 33px;
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
              color: @black;
            }
          }
          sup {
            font-size: 12px;
            font-weight: 500;
            color: #ccc;
            left: -15px;
            top: 0;
          }
        }
      }
    }
  }
  .timeline {
    position: relative;
    display: flex;
    align-items: flex-start;
    max-width: 955px;
    width: 100%;
    margin: 0 auto;
    @media (max-width: 1250px) {
      padding: 0 0 0 74px;
    }
    @media (max-width: 992px) {
      margin: 70px 0;
      padding: 0;
    }
    &-content {
      position: relative;
      display: flex;
      align-items: flex-start;
      left: -92px;
      @media (max-width: 992px) {
        flex-direction: column;
        width: 100%;
        align-items: center;
        left: 0;
        top: -65px;
      }
      > .arrow {
        color: @white;
        position: relative;
        top: 199px;
        font-size: 20px;
        font-weight: 600;
        padding: 0 25px;

        @media (max-width: 1250px) {
          padding: 0 10px;
        }
        @media (max-width: 992px) {
          left: -1px;
          top: 0;
          transform: rotate(90deg);
          padding: 15px 0;
        }
      }
    }
    progress {
      height: 28px;
      width: 100%;
      position: absolute;
      top: 194px;
      z-index: -1;
      -webkit-appearance: none;
      appearance: none;
      @media (max-width: 1250px) {
        width: calc(100% - 85px);
      }
      @media (max-width: 992px) {
        top: 50%;
        width: 800px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
      }
      &::-webkit-progress-bar {
        background-color: @light-green;
        border-radius: 50px;
        box-shadow: inset -3px 2px 5px rgba(@black, 0.3);
      }
    }


    .days {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      @media (max-width: 992px) {
        left: 18px;
        flex-direction: row;
        position: relative;
      }
      &:first-child {
        max-width: 184px;
        width: 100%;
        @media (max-width: 992px) {
          max-width: 100%;
          width: initial;
        }
      }
      .img-timeline {
        min-height: 184px;
        max-height: 184px;
        text-align: center;
        @media (max-width: 992px) {
          text-align: center;
          flex: 0 0 calc(50% - 45px);
          max-width: calc(50% - 45px);
        }
        span  p {
          background-color: @green2;
          font-family: @font-base;
          font-size: 15px;
          font-weight: 600;
          color: @white;
          position: relative;
          top: -40px;
          display: block;
          width: max-content;
          padding: 0 5px;
          @media (max-width: 992px) {
            font-size: 12px;
            margin: 0;
            flex: initial; 
            max-width: initial;
            top: 0;
          }
        }
      }
      img {
        max-width: 180px;
        width: 100%;
        height: 100%;
        max-height: 184px;

      }
      p {
        max-width: 300px;
        font-family: @font-base;
        font-size: 15px;
        line-height: 1.5;
        font-weight: 400;
        color: @black;
        @media (max-width: 1250px) {
          font-size: 14px;
        }
        @media (max-width: 992px) {
          padding: 0 0 0 50px;
          flex: 0 0 calc(50% - 25px);
          max-width: calc(50% - 25px);
        }
        @media (max-width: 768px) {
          padding: 0 0 0 30px;
        }
        @media (max-width: 475px) {
          font-size: 12px;
        }
      }
      > span {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        background: white;
        border-radius: 50%;
        border: 3px solid @orange;
        margin: 0 0 20px;
        position: relative;
        @media (max-width: 992px) {
          flex: 0 0 50px;
          max-width: 50px;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: -22px;
          width: 0;
          height: 65px;
          border: 1px dashed @black;
          z-index: -1;
          @media (max-width: 992px) {
            width: 70px;
            height: 0;
            bottom: 50%;
            transform: translateY(50%);
            left: 0;
          }
        }
      }
    }
  }
}
.bloc-composition {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  &-image {
    max-width: calc(100% - 413px);
    flex: 0 0 calc(100% - 413px);
    display: flex;
    justify-content: flex-end;
    padding: 0 22px;
    @media (max-width: 890px) {
      max-width: 100%;
      flex: 0 0 100%;
      justify-content: center;
    }
    .composition-list {
      position: relative;
      &.parent {
        padding: 128px 0 42px 0 !important;
        max-width: 232px;
        width: 100%;
        &:before {
          content: "";
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translateX(-50%);
          background-image: url("@{img}compoBgH.png");
          width: 232px;
          height: 128px;
          background-size: contain;
          background-repeat: no-repeat;
        }
        &:after {
          content: "";
          position: absolute;
          bottom: 8px;
          left: 50%;
          transform: translateX(-50%);
          background-image: url("@{img}compoBgB.png");
          width: 232px;
          height: 42px;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      ul {
        text-align: center;
        width: 100%;
        background-image: url("@{img}compoBgM.jpg");
        background-size: contain;
        background-repeat-y: repeat;
        background-repeat-x: no-repeat;
        height: fit-content;
        padding: 0 !important;
        position: relative;
        min-height: 278px;
        li {
          position: relative;
          &:last-child {
            p {
              font-weight: 600;
              font-size: 11px;
              padding: 10px 9px 25px 0;

              &:after {
                content: "";
                background-image: url("@{img}FeuilleList2.png");
                width: 165px;
                height: 34px;
                background-repeat: no-repeat;
                background-size: contain;
                display: inline-block;
                position: absolute;
                bottom: 0px;
                right: 16px;
                transition: all 0.2s ease-in-out;
              }
              &:hover {
                color: @lightgreen;
              }
            }
          }
          .bg-underline {
            background-repeat: no-repeat;
            background-size: contain;
            width: 151px;
            height: 34px;
            display: inline-block;
            position: absolute;
            bottom: 0px;
            right: 16px;
            transition: all 0.2s ease-in-out;
          }
          p {
            font-family: @font-base;
            color: @white;
            font-weight: 700;
            font-size: 14px;
            line-height: 15px;
            position: relative;
            padding: 5px 45px 15px 30px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            &:hover {
              color: @orange;
            }
            span {
              display: none;
            }

          }
          
          
        }
        &.Humeur, &.Sommeil , &.Relax {
          li {
            &:last-child {
              p {
                &:hover {
                  color: @lightgreen;
                }
              }
            }
            p {
              &:hover {
                color: #c2aed3;
              }
            }
          }
          
        }
        &.Detox, &.Digestion  {
          li {
            &:last-child {
              p {
                &:hover {
                  color: @lightgreen;
                }
              }
            }
            p {
              &:hover {
                color: #99c77e;
              }
            }
          }
        }
        &.Draineur, &.Minceur  {
          li {
            &:last-child {
              p {
                &:hover {
                  color: @lightgreen;
                }
              }
            }
            p {
              &:hover {
                color: #f3aecd;
              }
            }
          }
        }
        &.Souplesse {
          li {
            &:last-child {
              p {
                &:hover {
                  color: @lightgreen;
                }
              }
            }
            p {
              &:hover {
                color: #7cccf1;
              }
            }
          }
        }
      }
    }
  }
  .elements-description {
    max-width: 413px;
    flex: 0 0 413px;
    padding: 20px 22px;
    @media (max-width: 890px) {
     max-width: initial;
     flex: 0 0 100%;
    }
  }
  &-content {
    display: none;
    @media (max-width: 890px) {
      max-width: 100%;
      flex: 0 0 100%;
    }
    &:first-child {
      display: block;
    }
    .head {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      img {
        max-width: 113px;
        flex: 0 0 113px;
        width: 100%;
        height: 100%;
      }
      .names {
        padding-left: 20px;
        max-width: calc(100% - 113px);
        flex: 0 0 calc(100% - 113px);
        p {
          font-family: @font-base;
          font-size: 18px;
          font-weight: 700;
          color: @green2;
          margin-bottom: 16px;
          span {
            display: block;
            font-family: @font-base;
            font-size: 15px;
            font-style: italic;
            font-weight: 400;
            color: @green2;
          }
        }
        .desc-title p {
          font-family: @font-base;
          font-size: 18px;
          font-style: italic;
          color: @green2;
          font-weight: initial;
          line-height: 1;
        }
      }
    }
    .description {
      p {
        font-family: @font-base;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: @black;
      }
    }
  }
}
body#cart,
body#checkout,
body#product {
  .modal-backdrop {
    z-index: 1;
  }
  .paimentx3-modal {
    .modal-dialog {
      max-width: 912px;
      width: 100%;
      @media screen and (max-width: 556px) {
        width: calc(100% - 20px);
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
      }
      .modal-content {
        background-color: @beige2;
        border: none;
        position: relative;
        overflow: hidden;
        @media screen and (max-width: 556px) {
          min-height: auto;
          max-height: 661px;
          overflow: auto;
        }
        &:after {
          content: "";
          background-image: url("@{img}s-temoignage-right.png");
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          right: -85px;
          top: 15%;
          display: block;
          width: 293px;
          height: 326px;
          pointer-events: none;
          z-index: -1;
          @media screen and (max-width: 1024px) {
            content: initial;
          }
        }
        &:before {
          content: "";
          background-image: url("@{img}conseil1.svg");
          background-size: contain;
          background-repeat: no-repeat;
          position: absolute;
          left: 0;
          top: 45%;
          transform: translateY(-50%);
          display: block;
          width: 347px;
          height: 368px;
          pointer-events: none;
          z-index: 0;
          @media screen and (max-width: 1024px) {
            content: initial;
          }
        }
        .modal-header {
          background-color: @green2;
          border: 0;
          padding-left: 31px;
          padding-right: 17px;
          border-radius: 0;
          @media screen and (max-width: 1024px) {
            padding-right: 57px;
          }
          .modal-title {
            font-family: @font-base;
            font-size: 25px;
            font-weight: bold;
            line-height: 1.46;
            text-align: left;
            text-transform: uppercase;
            color: @white;
            @media screen and (max-width: 556px) {
              font-size: 18px;
            }
          }
          .close {
            text-shadow: none;
            color: @white;
            font-size: 52px;
            opacity: 1;
            padding: 0;
            position: absolute;
            right: 40px;
            top: 35px;
            line-height: 0.5;
            @media screen and (max-width: 556px) {
              font-size: 40px;
              right: 30px;
              top: 35px;
            }
          }
        }
        .modal-body {
          padding: 26px 62px 24px;
          @media screen and (max-width: 650px) {
            padding: 26px 15px 24px;
          }
          .content-wrapper {
            background-color: @beige7;
            padding: 9px 40px 9px 20px;
            margin-bottom: 15px;
            @media screen and (max-width: 768px) {
              padding: 10px;
            }
            .title {
              font-family: @font-base;
              font-size: 18px;
              font-weight: bold;
              line-height: 1.45;
              color: @green2;
              margin: 0;
              @media screen and (max-width: 650px) {
                font-size: 14px;
                font-width: 900;
              }
              &.bold {
                font-weight: 900;
                text-transform: uppercase;
              }
            }
            .description {
              font-family: @font-base;
              font-size: 17px;
              font-weight: 500;
              line-height: 1.36;
              color: @gray-darker2;
              margin: 0 0 15px;
              @media screen and (max-width: 650px) {
                font-size: 12px;
              }
            }
            &.center {
              text-align: center;
              p.description {
                max-width: 631px;
                margin: 0 auto;
              }
            }
            &.footer {
              p {
                font-size: 13px;
                font-style: italic;
                font-weight: 600;
                color: @gray-darker2;
                @media screen and (max-width: 650px) {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.tabs.product-tabs {
  display: none;
}
.under-product-info {
  align-items: end;
  margin-bottom: 59px;
  @media (max-width: 992px) {
    align-items: center;
  }
  @media (max-width: 767.98px) {
    margin-bottom: 31px;
  }
  .contact {
    @media (max-width: 767.98px) {
      margin-bottom: 31px;
    }
    p {
      font-family: @font-base;
      font-size: 19px;
      line-height: 23px;
      font-weight: 400;
      letter-spacing: 0px;
      color: @green2;
      margin: 0;
      padding-left: 40px;
      position: relative;
      @media (max-width: 767.98px) {
        font-size: 14px;
        padding-left: 33px;
      }
      @media (max-width: 413.98px) {
        font-size: 12px;
        padding-left: 10px;
      }
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 28px;
        height: 27px;
        background-image: url("@{img}Picto-tel.jpg");
        @media (max-width: 767.98px) {
          width: 24px;
          background-size: contain;
          background-repeat: no-repeat;
          height: 23px;
        }
      }
    }
  }
  .reassurance-ptoduit {
    position: relative;
    background: 0% 0% no-repeat padding-box;
    @media (max-width: 767px) {
      padding: 0px 0;
    }
    .reassurance-items-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 992px) {
        display: block;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
      }
      @media (max-width: 992px) {
        .reassurance-item {
          margin-bottom: 15px !important;
          width: 50% !important;
        }
      }
      .slick-track {
        .slick-slide {
          float: left;
          > div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      ul.slick-dots {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 0;
        width: 100%;
        line-height: 0;
        li {
          background: @green2;
          height: 2px;
          width: 19px;
          border-radius: 5px;
          margin: 0 3px;
          opacity: 0.8;
          transition: 0.5s ease;
          button {
            border: none;
            box-shadow: none;
            background: none;
            font-size: 0;
          }
          &.slick-active {
            width: 39px;
            height: 3px;
            background: @green2;
            opacity: 1;
          }
        }
      }
      .reassurance-item {
        text-align: center;
        @media (max-width: 992px) {
          width: 33.33%;
          margin-bottom: 10px;
        }
        img {
          display: block;
          margin: 0 auto;
          height: 44px;
          width: auto;
          margin-bottom: 12px;
        }
        .title {
          text-align: center;
          font-family: @font-base;
          font-size: 13px;
          line-height: 17px;
          font-weight: bold;
          letter-spacing: 0px;
          color: @green2;
          text-transform: uppercase;
          margin: 0;
        }
        p.sub-title {
          text-align: center;
          font-family: @font-base;
          font-size: 13px;
          line-height: 17px;
          font-weight: normal;
          letter-spacing: 0px;
          color: @green2;
          text-transform: uppercase;
          margin: 0;
        }
      }
    }
  }
}

#content-wrapper .section-title.h2 {
  margin: 40px 0;
  font-weight: 600;
  padding: 30px 0px 30px 0px;
  background-image: url("@{img}bg-title.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: @green2;
  font-size: 40px;
  text-transform: uppercase;

  &:after {
    content: initial;
  }
}

// _________________ TABS ______________________ //

.tab_produit {
  .tabs {
    background-color: @beige9;
    ul.nav-tabs {
      display: flex;
      align-items: center;
      justify-content: space-between !important;
      margin-bottom: 0;
      @media (max-width: 1390px) {
        flex-wrap: nowrap;
        height: 46px;
      }
      li.nav-item {
        width: 100%;
        max-width: 193.8px;
        text-align: center;
        float: none;
        margin: 0;
        a {
          position: relative;
          font-family: @font-base;
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 400;
          background: none;
          color: @green2;
          border: none;
          border-bottom: 0;
          border-radius: 0;
          display: block;
          text-align: center;
          height: 100%;
          transition: 0.3s ease-in-out;
          padding: 16px 10px;
          opacity: 1;
          &.active,
          &:hover {
            background-color: @green2;
            color: @white;
          }

          &::before {
            content: none;
          }
        }
      }
    }
  }
  .tab-content {
    display: none !important;
  }
}
.efficacite > .elementor-container {
  padding-top: 40px;
  border: 1px solid @green2;
  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
    display: block !important;
    border-top: 0;
  }
  .elementor-widget-spacer {
    .elementor-widget-container {
      margin: 0 70px 40px !important;
      @media screen and (max-width: 992px) {
        margin: 0 0 45px !important;
      }
    }
  }
  .tabs-mobile {
    @media screen and (max-width: 992px) {
      display: block;
      width: calc(100vw - 30px);
      color: @green2;
      background-color: @beige9;
      margin-left: -21px;
      margin-top: -40px;
      margin-bottom: 19px;
      h2 {
        text-align: left;
        font-family: @font-base;
        font-size: 14px;
        line-height: 40px;
        font-weight: 500;
        color: @green2;
        margin-bottom: 0;
        padding: 0 14px;
      }
    }
  }
  .title-h2 {
    h3 {
      text-align: center;
      font-family: @font-base;
      font-size: 26px;
      line-height: 26px;
      font-weight: bold;
      color: @green2;
      margin-bottom: 73px;
      @media screen and (max-width: 992px) {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 23px;
      }
    }
    &.titleoxybiocell {
      margin-bottom: 0;
      h3 {
        line-height: 42px;
        margin-bottom: 23px;
        span {
          span {
            background-color: @white;
            box-shadow: 0 0 9px -5px @black;
            padding: 5px 10px;
            border-radius: 20px;
            text-transform: initial;
          }
        }
        @media screen and (max-width: 992px) {
          font-size: 17px;
          line-height: 32px;
          margin-bottom: 40px;
          text-transform: initial;
        }
      }
    }
    &.titlechoisir {
      margin-bottom: 0;
      h3 {
        margin-bottom: 30px;
        @media screen and (max-width: 992px) {
          font-size: 16px;
          line-height: 32px;
          margin-bottom: 35px;
        }
      }
    }
  }
  .model-cart-plantes {
    max-width: 942px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    .cart-plantes {
      margin-bottom: 25px;
      padding-right: 0px;
      padding-left: 0;
      max-width: 433px;
      width: 100%;
      @media screen and (max-width: 767px) {
        margin-bottom: 33px;
      }
      .cart-plantes-img {
        float: left;
        width: 135px;
        height: 135px;
        @media screen and (max-width: 767px) {
          width: 95px;
          height: 95px;
        }
        img {
          width: 135px;
          height: 135px;
          object-fit: cover;
          @media screen and (max-width: 767px) {
            width: 95px;
            height: 95px;
          }
        }
      }
      .cart-plantes-h3 {
        float: left;
        width: calc(100% - 135px);
        padding-left: 17px;
        @media screen and (max-width: 767px) {
          width: calc(100% - 95px);
          padding-left: 11px;
        }
        p {
          font-family: @font-base;
          font-size: 10px;
          line-height: 15px;
          font-style: italic;
          font-weight: 500;
          color: @black;
          strong {
            font-family: @font-base;
            font-size: 14px;
            line-height: 15px;
            font-weight: bold;
            color: @green2;
            font-style: normal;
            display: block;
          }
        }
      }
      .cart-plantes-span {
        float: left;
        width: calc(100% - 135px);
        padding-left: 17px;
        @media screen and (max-width: 767px) {
          width: calc(100% - 95px);
          padding-left: 11px;
        }
        p {
          font-family: @font-base;
          font-size: 10px;
          line-height: 15px;
          font-weight: 400;
          color: @black;
        }
      }
      .cart-plantes-text {
        float: left;
        width: calc(100% - 135px);
        padding-left: 17px;
        p {
          font-family: @font-base;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          color: @black;
        }
      }
    }
  }
  .oxybiocell {
    margin-bottom: 58px;
    @media screen and (max-width: 992px) {
      margin-bottom: 42px;
      .elementor-row {
        flex-direction: column-reverse;
        align-items: center;
      }
    }
    .oxybiocell-text {
      width: 70%;
      padding-right: 50px;
      @media screen and (max-width: 992px) {
        width: 100%;
        padding: 0;
      }
      p {
        font-family: @font-base;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: @black;
        @media screen and (max-width: 992px) {
          line-height: 24px;
        }
      }
    }
    .oxybiocell-img {
      width: 30%;
      @media screen and (max-width: 992px) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }

  .efficacite-progress {
    max-width: 955px;
    margin: 0 auto;
    margin-bottom: 27px;
    justify-content: center;
    .progress-wrapper {
      @media screen and (max-width: 767px) {
        max-width: 50%;
        margin-bottom: 11px;
      }
      .item-progress {
        margin-bottom: 11px;
        @media screen and (max-width: 767px) {
          width: 100%;
          margin: 0 auto;
          margin-bottom: 6px;
        }

        div.chart {
          position: relative;
          width: 106px;
          height: 106px;
          margin: 0 auto;
          @media screen and (max-width: 767px) {
            width: 80px;
            height: 80px;
          }
          canvas {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
          }
          span {
            color: @green2;
            display: block;
            line-height: 106px;
            text-align: center;
            width: 100%;
            font-family: @font-base;
            font-size: 24px;
            font-weight: 700;
            position: absolute;
            @media screen and (max-width: 767px) {
              line-height: 80px;
              font-size: 18px;
            }
          }

          input {
            width: 200px;
          }
  
          span {
          }
        }
        
      }
      .progress-title {
        p {
          font-family: @font-base;
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          color: @black;
          text-align: center;
          @media screen and (max-width: 992px) {
            line-height: 12px;
            font-size: 10px;
          }
        }
      }
    }
  }
  .choisir {
    margin-bottom: 58px;
    @media screen and (max-width: 992px) {
      margin-bottom: 14px;
      > .elementor-container > .elementor-row {
        flex-direction: column-reverse;
      }
    }
    .img-choisir {
      .elementor-widget-container {
        height: 342px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 16px;
        @media screen and (max-width: 992px) {
          height: 172px;
          margin-bottom: 28px;
          .elementor-image {
            width: 33%;
          }
        }
      }
    }
    .elementor-widget-icon-list {
      text-align: center;
    }
    ol,
    ul {
      display: inline-block;
      text-align: left;
      max-width: 360px;
      li {
        margin-bottom: 0;
        height: 48px;
        position: relative;
        padding-left: 20px;
        @media screen and (max-width: 992px) {
          height: auto;
          margin-bottom: 17px;
        }
        &::before {
          content: "";
          position: absolute;
          width: 15px;
          height: 25px;
          top: -4px;
          left: 0;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNyIgaGVpZ2h0PSIyNyIgdmlld0JveD0iMCAwIDE3IDI3Ij4NCiAgPHRleHQgaWQ9Il8iIGRhdGEtbmFtZT0i4pyYIiBmaWxsPSIjZjQwMDRiIiBmb250LXNpemU9IjIwIiBmb250LWZhbWlseT0iU2Vnb2VVSUVtb2ppLCBTZWdvZSBVSSBFbW9qaSI+PHRzcGFuIHg9IjAiIHk9IjIyIj7inJg8L3RzcGFuPjwvdGV4dD4NCjwvc3ZnPg0K");
          background-repeat: no-repeat;
        }
        span {
          display: inline-block;
          font-family: @font-base;
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          color: @black;
          letter-spacing: 0.6px;
        }
      }
    }
    .border-list {
      @media screen and (max-width: 992px) {
        margin-bottom: 42px;
      }
      &::after {
        content: "";
        position: absolute;
        width: calc(100% - 12px);
        height: 120%;
        border: 3px solid @green2;
        bottom: 5px;
        left: 0;
        z-index: -1;
        @media screen and (max-width: 992px) {
          width: calc(100% + 30px);
          bottom: -8px;
          left: -15px;
        }
      }
      li {
        span {
          letter-spacing: 0;
        }
        &::before {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIyNyIgdmlld0JveD0iMCAwIDE1IDI3Ij4NCiAgPHRleHQgaWQ9Il8iIGRhdGEtbmFtZT0i4pyTIiBmaWxsPSIjMDA1ODQ1IiBmb250LXNpemU9IjIwIiBmb250LWZhbWlseT0iU2Vnb2VVSUVtb2ppLCBTZWdvZSBVSSBFbW9qaSI+PHRzcGFuIHg9IjAiIHk9IjIyIj7inJM8L3RzcGFuPjwvdGV4dD4NCjwvc3ZnPg0K");
        }
      }
    }
  }
  .footer {
    ol,
    ul {
      padding: 0 0px 24px 11px;
      li {
        font-style: italic;
        margin-bottom: 0;
        font-family: @font-base;
        font-size: 11px;
        line-height: 14px;
        font-weight: normal;
        color: @gray3;
      }
    }
  }
}

.bienfaits > .elementor-container {
  padding-top: 23px;
  border: 1px solid @green2;
  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
    display: block !important;
    padding-top: 40px;
    border-top: 0;
  }
  .elementor-widget-spacer {
    .elementor-widget-container {
      margin: 0 70px 40px !important;
      @media screen and (max-width: 992px) {
        margin: 0 0 45px !important;
      }
    }
  }
  .tabs-mobile {
    @media screen and (max-width: 992px) {
      display: block;
      width: calc(100vw - 30px);
      color: @green2;
      background-color: @beige9;
      margin-left: -21px;
      margin-top: -40px;
      margin-bottom: 19px;
      h2 {
        text-align: left;
        font-family: @font-base;
        font-size: 14px;
        line-height: 40px;
        font-weight: 500;
        color: @green2;
        margin-bottom: 0;
        padding: 0 14px;
      }
    }
  }
  .title-h2 {
    h3 {
      text-align: center;
      font-family: @font-base;
      font-size: 26px;
      line-height: 35px;
      font-weight: bold;
      color: @green2;
      margin-bottom: 40px;
      &::first-line {
        font-weight: 400;
      }
    }
    &.comment {
      h3 {
        margin-bottom: 30px;
      }
    }
  }
  .Pour-qui {
    .img-wrapper {
      img {
      }
    }
    .title {
      h4 {
        text-align: center;
        font-family: @font-base;
        font-size: 15px;
        line-height: 25px;
        font-weight: bold;
        color: @black;
        margin-bottom: 0px;
        text-transform: uppercase;
      }
    }
    .text {
      p {
        text-align: center;
        font-family: @font-base;
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
        color: @black;
        margin-bottom: 0px;
      }
    }
  }
}

.utilisation > .elementor-container {
  padding-top: 56px;
  border: 1px solid @green2;
  padding-bottom: 51px;
  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
    display: block !important;
    padding-top: 40px;
    border-top: 0;
  }
  .elementor-widget-spacer {
    .elementor-widget-container {
      margin: 0 70px 40px !important;
      @media screen and (max-width: 992px) {
        margin: 0 0 45px !important;
      }
    }
  }
  .tabs-mobile {
    @media screen and (max-width: 992px) {
      display: block;
      width: calc(100vw - 30px);
      color: @green2;
      background-color: @beige9;
      margin-left: -21px;
      margin-top: -40px;
      margin-bottom: 19px;
      h2 {
        text-align: left;
        font-family: @font-base;
        font-size: 14px;
        line-height: 40px;
        font-weight: 500;
        color: @green2;
        margin-bottom: 0;
        padding: 0 14px;
      }
    }
  }
  .title-h2 {
    p {
      font-family: @font-base;
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      color: @black;
      margin-bottom: 20px;
      text-transform: inherit;
    }
  }
  .sub_title {
    p {
      font-family: @font-base;
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      color: @black;
      margin-bottom: 25px;
    }
  }
  .description {
    p {
      font-family: @font-base;
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
      color: @black;
      margin-bottom: 25px;
      &::first-line {
        color: @green2;
        font-weight: 700;
      }
    }
  }
}
.utilise > .elementor-container {
  padding-top: 40px;
  padding-bottom: 40px;
  border: 1px solid @green2;
  // display: none;
  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    border-top: 0;
    padding-right: 20px;
  }
  .elementor-widget-spacer {
    .elementor-widget-container {
      margin: 0 70px 40px !important;
      @media screen and (max-width: 992px) {
        margin: 0 0 45px !important;
      }
    }
  }
  .tabs-mobile {
    @media screen and (max-width: 992px) {
      display: block;
      width: calc(100vw - 30px);
      color: @green2;
      background-color: @beige9;
      margin-left: -21px;
      margin-top: -40px;
      margin-bottom: 19px;
      h2 {
        text-align: left;
        font-family: @font-base;
        font-size: 14px;
        line-height: 40px;
        font-weight: 500;
        color: @green2;
        margin-bottom: 0;
        padding: 0 14px;
      }
    }
  }
}

.composition > .elementor-container {
  padding-top: 40px;
  border: 1px solid @green2;
  padding-bottom: 46px;
  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
    display: block !important;
    border-top: 0;
  }
  .elementor-widget-spacer {
    .elementor-widget-container {
      margin: 0 70px 40px !important;
      @media screen and (max-width: 992px) {
        margin: 0 0 45px !important;
      }
    }
  }
  .tabs-mobile {
    @media screen and (max-width: 992px) {
      display: block;
      width: calc(100vw - 30px);
      color: @green2;
      background-color: @beige9;
      margin-left: -21px;
      margin-top: -40px;
      margin-bottom: 19px;
      h2 {
        text-align: left;
        font-family: @font-base;
        font-size: 14px;
        line-height: 40px;
        font-weight: 500;
        color: @green2;
        margin-bottom: 0;
        padding: 0 14px;
      }
    }
  }
  .title-h2 {
    h3 {
      text-align: center;
      font-family: @font-base;
      font-size: 26px;
      line-height: 35px;
      font-weight: bold;
      color: @green2;
      margin-bottom: 40px;
      &.title-table {
        h3 {
          margin-bottom: 20px;
        }
      }
    }
  }
  .heading-element {
    .heading {
      overflow: hidden;
      margin-top: 95px;
      margin-bottom: 10px;
      img {
      }
      h3,
      p {
        width: calc(100% - 112px);
        float: right;
        padding-left: 10px;
        margin: 0;
      }
      h3 {
        font-family: @font-base;
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        color: @green2;
      }
      p {
        font-family: @font-base;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        color: @green2;
        margin-bottom: 20px;
        &:last-child {
          margin: 0;
        }
        &:first-child {
          width: auto;
          display: inline-block;
          float: left;
          padding: 0;
          margin: 0;
        }
      }
    }
    .text {
      p {
        font-family: @font-base;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: @green2;
        margin: 0;
      }
    }
  }
  .table-section {
    margin-bottom: 15px;
    table {
      width: 100%;
      max-width: 459px;
      margin-top: 7px;
      margin-bottom: 13px;
      thead {
        background: none;
        padding-bottom: 13px;
        tr {
          background: none;
          td {
            padding: 3px 0px 10px !important;
            background: none;
          }
          td {
            font-family: @font-base;
            font-size: 10px;
            line-height: 13px;
            font-weight: 400;
            color: gray3;
            margin-bottom: 0px;

            &:first-of-type {
              font-weight: 700;
            }
            &:last-of-type {
              font-weight: 400;
            }
          }
        }
      }
      tbody {
        tr {
          background: none;
          font-weight: 700;
          &:nth-child(odd) {
            background: @beige9;
          }
          td {
            padding: 3px 10px !important;
            border: none;
            font-weight: 400;
            &:last-of-type,
            &:last-of-type p {
              text-align: left;
            }
            &:first-of-type {
              padding-left: 15px;
            }
            &:last-of-type {
              border-left: 3px solid @white;
              text-align: center;
            }
            strong {
              font-family: @font-base;
              font-size: 10px;
              line-height: 13px;
              font-weight: 700;
              color: @gray3;
              margin-bottom: 0px;
            }
            p {
              font-family: @font-base;
              font-size: 10px;
              line-height: 13px;
              font-weight: 400;
              color: @gray3;
              margin-bottom: 0px;
              @media (max-width: 768px) {
                font-size: 10px !important;
              }
              strong {
                text-align: left;
              }
            }
          }
        }
      }

      .thead-default {
        th {
          color: @black;

          &[scope="row"] {
            color: @black;
            font-weight: 500;
          }
        }
      }

      tbody {
        th {
          &[scope="row"] {
            color: @black;
            font-weight: 500;
          }
        }
      }
    }
    .text {
      p {
        font-family: @font-base;
        font-size: 10px;
        line-height: 13px;
        font-style: italic;
        font-weight: 400;
        color: @gray3;
        margin-bottom: 0px;
      }
    }
  }
  .description-1 {
    p {
      font-family: @font-base;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: @black;
      font-style: italic;
      margin-bottom: 32px;
      &::first-line {
        font-weight: 700;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 5px;
      }
    }
  }
  .description-2 {
    ol,
    ul {
      padding: 0 0px 0px 11px;
      li {
        font-style: italic;
        margin-bottom: 0;
        font-family: @font-base;
        font-size: 11px;
        line-height: 14px;
        font-weight: normal;
        color: @gray3;
      }
    }
  }
}
.O_T{
  &.elementor-active-mode {
    display: block !important;
    position: relative !important;
  }
  &:not(.elementor-active-mode) {
    .section-block.plantes  {
      display: none;
    }
  }
}
.O_T > .elementor-container {
  padding-top: 40px;
  padding-bottom: 55px;
  border: 1px solid @green2;
  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
    display: block !important;
    border-top: 0;
  }

  
  .elementor-widget-spacer {
    .elementor-widget-container {
      margin: 0 70px 40px !important;
      @media screen and (max-width: 992px) {
        margin: 0 0 45px !important;
      }
    }
  }
  .tabs-mobile {
    @media screen and (max-width: 992px) {
      display: block;
      width: calc(100vw - 30px);
      color: @green2;
      background-color: @beige9;
      margin-left: -21px;
      margin-top: -40px;
      margin-bottom: 19px;
      h2 {
        text-align: left;
        font-family: @font-base;
        font-size: 14px;
        line-height: 40px;
        font-weight: 500;
        color: @green2;
        margin-bottom: 0;
        padding: 0 14px;
      }
    }
  }
  .section-block {
    margin-bottom: 35px;
    padding: 43px 0 30px;
    align-items: center;
    position: relative;
    @media (max-width: 992px) {
      flex-wrap: wrap;
    }
    .img-wrapper {
      text-align: right;
      padding-right: 23px;
      @media (max-width: 992px) {
        text-align: center;
        padding-right: 0;
        padding-right: 20px;
        width: 100%;
      }
    }
    .text-wrapper {
      padding-left: 55px;
      padding-right: 55px;
      padding-top: 40px;
      @media (max-width: 992px) {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
      .title-h3 {
        width: 343px;
        height: 108px;
        padding: 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @green2;

        text-transform: uppercase;
        text-align: center;
        transform: translateX(-50%);
        margin-bottom: 35px;
        margin-left: 80px;
        @media (max-width: 992px) {
          transform: translateX(0);
          margin-left: auto;
          margin-right: auto;
        }
        @media (max-width: 767px) {
          font-size: 18px;
          padding: 15px 20px;
          width: auto;
        }
        h3 {
          font-family: @font-base;
          font-weight: bold;
          font-size: 20px;
          line-height: 24px;
          color: @white;

          @media (max-width: 767px) {
            font-size: 18px;
          }
          span {
            span {
              text-transform: initial;
            }
          }
        }
      }
      .text {
        p {
          font-family: @font-base;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: @black;
          margin-bottom: 0;
          margin-bottom: 24px;
          max-width: 560px;
          width: 100%;
        }
      }
    }
  }
  .after-section-block {
    margin-bottom: 70px;
    .elementor-button-link {
      background-color: @white;
      border: 1px solid @purple;
      color: @purple;
      font-family: @font-base;
      font-size: 12px;
      line-height: 40px;
      font-weight: 400;
      width: 100%;
      max-width: 231px;
      text-align: center;
      height: 40px;
      border-radius: 10px;
      &:hover {
        background-color: @purple;
        color: @white;
      }
    }
    p {
      font-family: @font-base;
      font-size: 10px;
      line-height: 13px;
      font-weight: 400;
      color: @black;
      img {
        margin-right: 10px;
      }
    }
  }
  .expert {
    .elementor-column-wrap {
      background-color: @beige9;
      h3 {
        font-family: @font-base;
        font-size: 26px;
        line-height: 26px;
        font-weight: 300;
        color: @green2;
        margin-bottom: 17px;
        text-transform: uppercase;
      }
      .min_desc {
        p {
          font-family: @font-base;
          font-size: 12px;
          line-height: 15px;
          font-weight: 400;
          color: @green2;
          margin-bottom: 18px;
          &::first-line {
            font-weight: 600;
            font-size: 17px;
            line-height: 20px;
            margin-bottom: 5px;
          }
        }
      }
      .long_decs {
        p {
          font-family: @font-base;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          font-style: italic;
          color: @black;
          margin-bottom: 8px;
          max-width: 795px;
          margin: 0 auto;
        }
      }
    }
  }
}

.FAQ > .elementor-container {
  padding-top: 40px;
  border: 1px solid @green2;
  padding-bottom: 50px;
  @media screen and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
    display: block !important;
    border-top: 0;
  }
  .elementor-widget-spacer {
    .elementor-widget-container {
      margin: 0 70px 40px !important;
      @media screen and (max-width: 992px) {
        margin: 0 0 45px !important;
      }
    }
  }
  .tabs-mobile {
    @media screen and (max-width: 992px) {
      display: block;
      width: calc(100vw - 30px);
      color: @green2;
      background-color: @beige9;
      margin-left: -21px;
      margin-top: -40px;
      margin-bottom: 19px;
      h2 {
        text-align: left;
        font-family: @font-base;
        font-size: 14px;
        line-height: 40px;
        font-weight: 500;
        color: @green2;
        margin-bottom: 0;
        padding: 0 14px;
      }
    }
  }
  .title-h2 {
    p {
      text-align: center;
      font-family: @font-base;
      font-size: 26px;
      line-height: 35px;
      font-weight: 400;
      color: @green2;
      margin-bottom: 36px;
      &::first-line {
        font-weight: bold;
      }
    }
  }
  .accordion-content {
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
  }

  .accordion-item {
    display: flex;
    flex-direction: column;
    padding: 0;
    border-bottom: 1px solid @black;
    cursor: pointer;
    margin-bottom: 24px;
    padding-bottom: 15px;
  }
  .item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .item-header.active {
    margin-bottom: 15px;
  }

  .item-icon {
    flex: 0 0 25px;
    display: grid;
    place-items: center;
    font-size: 1.25rem;
    height: 25px;
    width: 25px;
    cursor: pointer;
  }
  .item-icon i {
    transition: all 0.25s cubic-bezier(0.5, 0, 0.1, 1);
  }
  .item-question {
    font-family: @font-base;
    font-size: 15px;
    line-height: 19px;
    font-weight: 600;
    color: @black;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .active .item-icon i {
    transform: rotate(90deg);
  }

  .item-content {
    max-height: 0;
    overflow: hidden;
    transition: all 300ms ease;
    p {
      font-family: @font-base;
      font-size: 15px;
      line-height: 19px;
      font-weight: 400;
      color: @black;
      margin-bottom: 0;
    }
  }
}
.tab_content {
  @media screen and (max-width: 992px) {
    display: block !important;
    height: 40px !important;
    overflow: hidden;
    .tabs-mobile {
      position: relative;
      &::after {
        content: "\f107";
        position: absolute;
        font-family: "Line Awesome Free";
        font-weight: 900;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: @green2;
        transition: 0.5s ease;
      }
    }
    &.active {
      height: auto !important;
      .tabs-mobile {
        background-color: @green2;
        &::after {
          color: @white;
          transform: translateY(-50%) scale(1, -1);
        }
        h2 {
          color: @white;
        }
      }
    }
  }
}
.interesser {
  padding-top: 60px;
  padding-bottom: 54px;
  background-color: @beige9;
  position: relative;
  margin-top: 61px;
  // display: none;
  &::before {
    position: absolute;
    content: "";
    z-index: -1;
    background-color: @beige9;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .title-h2 {
    h2,p {
      text-align: center;
      font-family: @font-base;
      font-size: 30px;
      line-height: 40px;
      font-weight: 600;
      color: @green2;
      margin-bottom: 57px;
      text-transform: uppercase;
    }
  }
  .produit {
    width: calc(100% - 288px);
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .products-grid.row {
      margin: 0;
      @media (max-width: 992px) {
        display: block;
        overflow: hidden;
        margin-bottom: 40px;
      }
      @media (max-width: 992px) {
        .reassurance-item {
          margin-bottom: 31px !important;
        }
      }
      .slick-track {
        .slick-slide {
          float: left;
          padding: 0 60px;
          @media (max-width: 767px) {
            padding: 0 30px;
          }
          > div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      @media (max-width: 992px) {
        .slick-arrow.slick-prev {
          left: 20px !important;
        }
        .slick-arrow.slick-next {
          right: 20px !important;
        }
      }
      @media (max-width: 767px) {
        .slick-arrow.slick-prev {
          left: 0px !important;
        }
        .slick-arrow.slick-next {
          right: 0px !important;
        }
      }
      
      div.cart-product {
        padding: 0 53px 50px 0px;
        @media (max-width: 992px) {
          padding: 0;
        }
        article.product-miniature {
          background-color: @white;
          padding: 25px 8px 25px 25px;
          box-shadow: 0 0 10px -5px @black;
          @media (max-width: 992px) {
            margin-bottom: 30px;
          }
          .thumbnail-container {
            padding-right: 28px;
          }
          .tages {
            top: 8px;
            left: 14px;
          }
        }
      }
    }
  }
  .expert {
    width: 288px;
    @media screen and (max-width: 767px) {
      margin: 0 auto;
    }
    .img-text {
      overflow: hidden;
      p {
        float: left;
        font-family: @font-base;
        font-size: 26px;
        line-height: 40px;
        font-weight: 300;
        color: @green2;
        margin-bottom: 12px;
        text-transform: uppercase;

        img {
          margin-right: 20px;
        }
      }
    }
    .min_desc {
      p {
        font-family: @font-base;
        font-size: 12px;
        line-height: 15px;
        font-weight: 400;
        color: @green2;
        margin-bottom: 18px;
        &::first-line {
          font-weight: 600;
          font-size: 15px;
          line-height: 19px;
          margin-bottom: 0px;
        }
      }
    }
    .long_decs {
      p {
        font-family: @font-base;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        font-style: italic;
        color: @black;
        margin-bottom: 8px;
      }
    }
  }
}

.ps-shoppingcart .cart-products-count-btn {
  background-color: @purple !important;
  line-height: 18px;
  border-radius: 30px;
  min-width: 18px;
  height: 18px;
  font-size: 0.6rem;
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 0px 5px;
}
