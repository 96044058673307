// Custom Buttons
.btn,
.action,
.btn-secondary,
.btn-tertiary,
.btn-blue-light,
.btn-transparent,
.btn-comment,
.btn-unstyle,
.btn-black {
    position: relative;
    display: inline-block;
    font-family: @font-base;
    height: 44px;
    line-height: 44px;
    letter-spacing: 0.5px;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: @white;
    background-color: @purple;
    border: 1px solid transparent;
    border-radius: 5px;
    transition: all .3s ease-out;
    outline: none;
    padding: 0 10px;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    box-shadow: none;
    &:hover {
        border-color: @purple;
        background-color: @white;
        color: @purple;
        i {
            color: @purple;
        }
    }
    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
        border-color: @purple;
        background-color: @white;
        color: @purple;
    }
    &:active:focus {
        background-color: @purple;
    }
    &:active {
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
    i {
        transition: all .3s ease-out;
        font-size: 15px;
        color: @white;
        display: inline-block;
        margin-right: 3px;
    }
    &[disabled]{
        &:hover {
            border-color: @purple;
            background-color: @white;
            color: @purple;

            i {
                color: @purple;
            }
        }
    }
    &.btn-primary
    &.transparent {
        background-color: transparent;
        border-color: @gray-border;
        color: @purple;
        &:hover {
            background: @purple;
            color: @white;
            border-color: @purple;
        }
        &:active:focus {
            background: @purple;
            color: @black;
            border-color: @purple;
        }
    }
    &.btn-light {
        background-color: @lightgreen;
        border-color: @lightgreen;
        color: @white;
        &:hover {
            background: @white;
            color: @lightgreen;
            border-color: @white;
        }
        &:active:focus {
            background: @white;
            color: @lightgreen;
            border-color: @white;
        }
    }
    &.btn-pink{
        background-color: @pink;
        border-color: @pink;
        color: @white;
        &:hover {
            background: @white;
            color: @pink;
            border-color: @pink;
        }
        &:active:focus {
            background: @white;
            color: @pink;
            border-color: @pink;
        }
    }
}
.btn-category .btn {
    height: 72px;
    line-height: 72px;
    padding: 0 80px;
    font-size: 22px;
    font-weight: 600;
    text-align: center;

    @media (max-width: 768px) {
        padding: 0 20px;
        font-size: 19px;
        width: 100%;
    }
    i {
        &:before {
            content: "";
            width: 18px;
            height: 18px;
            background-image: url("@{img}icon-catalogue.svg");
            display: block;
            transition: all .3s ease-out;
            background-size: contain;
            filter: brightness(0) invert(1);
        }
    }
    &:hover {
        i {
            &:before {
                filter: initial;
            }
        }
    }
}
.btn-pink-parent .btn {
    height: 49px;
    line-height: 49px;
    font-size: 23px;
    font-weight: 400;
    text-align: center;
    background-color: @pink;
    border-color: @pink;
    color: @white;
    border-radius: 10px;
    text-transform: inherit;
    padding: 0 34px;
    @media (max-width: 768px) {
        padding: 0 15px;
        font-size: 14px;
        width: 100%;
    }
    i {
        &:before {
            content: "";
            width: 18px;
            height: 18px;
            background-image: url("@{img}icon-catalogue.svg");
            display: block;
            transition: all .3s ease-out;
            background-size: contain;
            filter: brightness(0) invert(1);
        }
    }
    &:active,
    &:focus {
        background: @white !important;
        color: @pink !important;
        border-color: @pink;
    }
    &:hover {
        background: @white;
        color: @pink;
        border-color: @pink;

        i {
            &:before {
                filter: initial;
            }
        }
    }
}



// Custom Inputs
textarea {
    min-height: auto;
    height: 180px;
}

select,
select.form-control,
input[type=text],
input[type=text].form-control,
input[type=email],
input[type=email].form-control,
input[type=password],
input[type=password].form-control,
input[type=tel],
input[type=tel].form-control,
input[type=search],
input[type=search].form-control,
input[type=date],
input[type=date].form-control,
input[type=number],
input[type=number].form-control,
input[type=url],
input[type=url].form-control,
textarea,
textarea.form-control {
    border: 1px solid @green2;
    border-radius: 0;
    box-shadow: none;
    background-color: @white;
    background-image: none;
    font-family: @font-base;
    color: @black;
    font-weight: 400;
    font-size: 16px;
    padding: 12px 10px;
    height: 40px;
    outline: none;

    &::placeholder {
        color: @doveGray;
        font-size: 18px;
    }


    &::-webkit-input-placeholder {
        color: @doveGray;
    }

    &::-moz-placeholder {
        color: @doveGray;
    }

    &:-ms-input-placeholder {
        color: @doveGray;
    }

    &:-moz-placeholder {
        color: @doveGray;
    }

    &:active {
        &::-webkit-input-placeholder {
            color: @doveGray;
        }

        &::-moz-placeholder {
            color: @doveGray;
        }

        &:-ms-input-placeholder {
            color: @doveGray;
        }

        &:-moz-placeholder {
            color: @doveGray;
        }
    }
}

select.form-control:not([size]):not([multiple]) {
    height: 40px !important;
    padding-top: 0;
    padding-bottom: 0;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAAPklEQVR4Ae3TwREAEBQD0V/6do4SXPZg7EsBhsQ8IEmSMOsiuEfg3gL3oXC7wK0bd1G4o8X9F4yIkyQfSrIByQBjp7QuND8AAAAASUVORK5CYII=) no-repeat scroll right .5rem center/1.25rem 1.25rem;
    appearance: none;
     @media screen and (max-width: 840px) {
        height: 35px !important;
    }
    &:focus,
    &:active {
        box-shadow: none;
        outline: none;
        border-color: @doveGray;
    }

}

select {
    border-radius: 4px;
    background-color: @white;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: top calc(50px / 2 - 17px) right 0;

    &::-ms-expand {
        display: none;
    }
}

label {
    &.required {
        position: relative;
        @media (max-width: 1025px) {
            padding-right: 0;
            padding-left: 0;
        }
        &:after {
            content: '*';
            font-size: 12px;
            color: @red;
            position: relative;
            margin-left: 4px;
            top: 2px;
        }
    }
}

// Custom checkbox / radiobutton
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked,
[type="radio"]:not(:checked),
[type="radio"]:checked {
    position: absolute;
    left: 0;
    opacity: 0;
    z-index: -10;
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked,
[type="radio"]:not(:checked),
[type="radio"]:checked {
    +label:not(.error) {
        position: relative;
        display: inline-block;
        padding-left: 20px;
        font-size: 13px;
        line-height: normal;
        font-weight: 400;
        cursor: pointer;

        a {
            font-size: inherit;
            color: inherit;
            text-decoration: underline;
        }
    }
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    +label:not(.error) {
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0px;
            width: 14px;
            height: 14px;
            border: 1px solid @black;
        }
    }
}

[type="radio"]:not(:checked),
[type="radio"]:checked {
    +label:not(.error) {
        &:before {
            content: '';
            position: absolute;
            top: 1px;
            left: 3px;
            width: 14px;
            height: 14px;
            border: 1px solid @black;
            border-radius: 50%;
        }
    }
}

[type="radio"]:checked {
    +label:not(.error) {
        &:before {
            border-color: @gray-darker;
        }
    }
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
    +label:not(.error) {
        &:after {
            content: "\e913";
            // font-family: @font-icon;
            font-size: 10px;
            position: absolute;
            top: 1px;
            left: 1px;
            width: 12px;
            height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: @white;
            color: @green2;
            margin: 0;
            transition: all .2s;
        }
    }
}

[type="radio"]:not(:checked),
[type="radio"]:checked {
    +label:not(.error) {
        &:after {
            content: "";
            position: absolute;
            top: 5px;
            left: 1px;
            width: 9px;
            height: 9px;
            background: @green2;
            margin: 0;
            border-radius: 50%;
            transition: all .2s;
        }
    }
}

[type="checkbox"]:not(:checked),
[type="radio"]:not(:checked) {
    +label:not(.error) {
        &:after {
            opacity: 0;
            transform: scale(0);
        }
    }
}

[type="checkbox"]:checked,
[type="radio"]:checked {
    +label:not(.error) {
        &:after {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.select2-container {
    border-radius: 5px 5px 5px 5px;

    @media screen and (max-width: 575px) {
        border-radius: 5px;
    }

    .select2-choice {
        height: 100%;
        background: transparent;
        font-size: 20px;

        .select2-arrow {
            width: 40px;
            background: @green2;
            border: 0;

            @media screen and (max-width: 992px) {
                width: 35px;
            }

            b {
                //background-image: url(#{@img}arrow-down-select.png) !important;
                background-position: center;
                background-size: initial !important;
            }
        }

    }

}

form {
    label.error {
        font-family: @font-base;
        display: block;
        color: @green2;
        line-height: 1;
        text-align: left;
        margin: 4px 0 0;
        font-size: 13px;
        font-weight: 500;
        position: absolute;
        bottom: -16px;
    }

    .custom-checkbox {
        label {
            &.error {
                bottom: -12px;
                padding: 0;
            }
        }
    }
}

@-webkit-keyframes autofill {
    to {
        background: @white;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

.show-for-mobile {
    display: none;

    @media screen and (max-width: 641px) {
        display: block;
    }
}

.hide-for-mobile {
    display: inherit;

    @media screen and (max-width: 641px) {
        display: none;
    }
}


.alert {
    margin: 10px 0;

    p {
        margin: 0;
    }
}

.tooltip-inner {
    background: @green2 !important;
    color: @green2 !important;
    font-size: 16px;
    font-weight: 500 !important;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: @green2 !important;
}

.required-field {
    padding-left: 6px;

    &:before {
        content: '*';
        font-size: 16px;
        font-weight: 500;
        left: -6px;
        position: relative;
        color: @orange;
    }
}


.top {
    width: 100%;
    height: 1300px;
    background-color: red;
}

.block-subbmission {
    display: none;
    width: 100%;
    background-color: rgba(@black, .2);
    z-index: 20;
    bottom: 0;
    left: 0;
    right: 0;
    transition: all ease-in-out 0.7s;

    @media screen and (max-width: 768px) {
        display: none;
    }
}


// CUSTOM ORDERED/UNORDERED LISTE STYLE
ul {
    ul {
        margin-bottom: 0;
        margin-top: 10px;
    }
}

ol {
    list-style: none;
    counter-reset: section;
    padding-left: 15px;
    margin-bottom: 20px;

    @media screen and (max-width: 1290px) {
        margin-bottom: 14px;
    }

    li {
        position: relative;
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 300;
    }

    ol {
        counter-reset: subsection;
        margin-top: 10px;
        padding-left: 27px;

        li {
            &:before {
                counter-increment: subsection;
                content: counter(section) "."counter(subsection);
                left: -27px;
            }
        }

        ol {
            counter-reset: subsection-1;
            margin-top: 10px;
            padding-left: 40px;

            li {
                &:before {
                    counter-increment: subsection-1;
                    content: counter(section) "."counter(subsection) "."counter(subsection-1);
                    left: -40px;
                }
            }

            ol {
                counter-reset: subsection-2;
                margin-top: 10px;
                padding-left: 50px;

                li {
                    &:before {
                        counter-increment: subsection-2;
                        content: counter(section) "."counter(subsection) "."counter(subsection-1) "."counter(subsection-2);
                        left: -50px;
                    }
                }
            }
        }
    }
}

label.col-form-label {
    @media screen and (max-width: 1024px) {
        padding: 0;
        text-align: left;
        font-size: 13px;
    }
   
}