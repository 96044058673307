body#cms section#main {
    .EP_title {
        h2 {
            margin-bottom: 12.5px;
            font-family: @font-base;
            color: @green2;
            font-size: 46px;
            line-height: 49px;
            text-transform: uppercase;
            font-weight: bold;
            @media (max-width: 922px) {
                font-size: 30px;
                line-height: 37px;
                margin-bottom: 16px;
                text-transform: initial;
            }
        }

    }

    .EP_sub_title {
        p {
            font-family: @font-base;
            color: @green2;
            font-size: 22px;
            line-height: 27px;
            font-weight: 400;
            margin-bottom: 150px;
            @media (max-width: 922px) {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 136px;
            }
        }
    }

    .EP_wrapper {
        position: relative;
        padding: 100px 0;
        margin-bottom: -15px;
        @media (max-width: 922px) {
            padding: 40px 0;
            margin-bottom: 63px;
        }
        &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: 0%;
            width: 100vw;
            transform: translateX(-50%);
            height: 100%;
            background-color: #F3ECE4;
        }
        .elementor-row {
            align-items: flex-start;
            @media (max-width: 922px) {
                flex-direction: column-reverse;
                align-items: center;
            }
        }
    }

    .EP_contact {
        padding-right: 25px;
        @media (max-width: 922px) {
            padding-right: 0px;
        }
        .elementor-element-populated {
            padding: 18px 35px 31px 35px;
            border-radius: 5px;
            border: 1px solid rgba(@black, 0.16);
            background-color: @white;
            box-shadow: 0px 0px 10px -1px rgba(@black, 0.16);
        }

        .title {
            font-family: @font-base;
            color: @green2;
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            margin-bottom: 19px;

            p {
                font-family: @font-base;
                color: @green2;
                font-size: 20px;
                line-height: 24px;
                font-weight: 600;
                margin-bottom: 0;
            }
        }

        .subtitle {
            font-family: @font-base;
            color: @black;
            font-size: 18px;
            line-height: 19px;
            font-weight: 600;
            margin-bottom: 24px;

            p {
                font-family: @font-base;
                color: @black;
                font-size: 18px;
                line-height: 19px;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }

        .EP_info p {
            font-family: @font-base;
            color: @black;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            margin-bottom: 19px;
        }
    }

    .EP_moduls {
        position: static;
        @media (max-width: 922px) {
            margin-bottom: 36px;
        }
        .elementor-column-wrap.elementor-element-populated {
            position: static;
        }

        .elementor-widget-wrap {
            position: static;

            .elementor-widget {
                position: static;
            }
        }

        .filter {
            position: absolute;
            left: 0;
            right: 0;
            top: -156px;
            @media (max-width: 922px) {
                top: -140px;
            }
            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 92px;
                position: relative;
                list-style: none;

                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: @green2;
                }

                li {
                    position: relative;
                    z-index: 2;

                    label {
                        display: block;
                        margin: 0;
                        cursor: pointer;
                        p.radio {
                            width: 18px;
                            height: 18px;
                            border-radius: 30px;
                            border: 1px solid @green2;
                            outline: 2px solid @white;
                            background-color: @white;
                            margin: 0;
                            position: relative;
                            &.active {
                                &::after {
                                    content: "";
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    margin: auto;
                                    border-radius: 50%;
                                    width: 10px;
                                    height: 10px;
                                    background-color: @green2;
                                }
                            }

                        }

                        p.year {
                            position: absolute;
                            top: -27px;
                            left: 50%;
                            transform: translateX(-50%);
                            margin: 0 auto;
                            font-family: @font-base;
                            color: @green;
                            font-size: 16px;
                            line-height: 19px;
                            font-weight: 400;
                            margin-bottom: 0px;
                        }
                    }

                    
                }
            }
            select {
                width: 100%;
                padding: 0 14px;
                font-weight: 600;
                font-size: 22px;
                line-height: 46px;
                max-width: 383;
                margin: 0 auto;
            }
        }

        .carts {
            .cart {
                border: 1px solid rgba(@black, 0.16);
                display: flex;
                align-items: center;
                border-radius: 5px;
                margin-bottom: 22px;
                padding: 13px 56px 13px 49px;
                background-color: @white;
                box-shadow: 0px 0px 10px -1px rgba(@black, 0.16);
                @media (max-width: 922px) {
                    margin-bottom: 18px;
                    padding: 15px 11px 10px 17px;
                }
                .img-wrp {
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 1px;
                        height: 102px;
                        right: -55px;
                        top: 50%;
                        transform: translateY(-50%);
                        background-color: rgba(@black, 0.16);
                        @media (max-width: 922px) {
                            right: -11px;
                         }
                    }

                    img {
                        max-width: 66px;
                        display: inline-block;
                        @media (max-width: 922px) {
                           max-width: 49px;
                        }
                    }
                }

                .content {
                    margin-left: 82px;
                    @media (max-width: 922px) {
                        margin-left: 22px;
                    }

                    .desc {
                        font-family: @font-base;
                        color: @black;
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: 500;
                        margin-bottom: 10px;
                        @media (max-width: 922px) {
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }

                    .date {
                        font-family: @font-base;
                        color: @green2;
                        font-size: 14px;
                        line-height: 18px;
                        font-weight: 500;
                        margin-bottom: 10px;
                        @media (max-width: 922px) {
                            font-size: 14px;
                            line-height: 29px;
                        }
                    }

                    a {
                        width: 164px;
                        line-height: 30px;
                        background-color: @pink;
                        border: 1px solid @pink;
                        font-family: @font-base;
                        color: @white;
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 400;
                        text-align: center;
                        border-radius: 30px;
                        background-repeat: no-repeat;
                        background-size: auto;
                        background-position: 30px 50%;
                        padding-left: 30px;
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNC44MzQiIGhlaWdodD0iMTQuODM0IiB2aWV3Qm94PSIwIDAgMTQuODM0IDE0LjgzNCI+DQogIDxnIGlkPSJJY29uX2ZlYXRoZXItZG93bmxvYWQiIGRhdGEtbmFtZT0iSWNvbiBmZWF0aGVyLWRvd25sb2FkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjUgMC41KSI+DQogICAgPHBhdGggaWQ9IlRyYWPDqV8xNDY2NSIgZGF0YS1uYW1lPSJUcmFjw6kgMTQ2NjUiIGQ9Ik0xOC4zMzQsMjIuNXYzLjY2N0ExLjcsMS43LDAsMCwxLDE2LjgsMjhINi4wMzdBMS43LDEuNywwLDAsMSw0LjUsMjYuMTY3VjIyLjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00LjUgLTE0LjE2NikiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEiLz4NCiAgICA8cGF0aCBpZD0iVHJhY8OpXzE0NjY2IiBkYXRhLW5hbWU9IlRyYWPDqSAxNDY2NiIgZD0iTTEwLjUsMTVsMy4yNSw0LjU4M0wxNywxNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYuODMzIC0xMC4wMTkpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxIi8+DQogICAgPHBhdGggaWQ9IlRyYWPDqV8xNDY2NyIgZGF0YS1uYW1lPSJUcmFjw6kgMTQ2NjciIGQ9Ik0xOCwxNC4wNjRWNC41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTEuMDgzIC00LjUpIiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxIi8+DQogIDwvZz4NCjwvc3ZnPg0K);
                        &:hover {
                            color: @pink;
                            background-color: @white;
                            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNC44MzQiIGhlaWdodD0iMTQuODM0IiB2aWV3Qm94PSIwIDAgMTQuODM0IDE0LjgzNCI+DQogIDxnIGlkPSJJY29uX2ZlYXRoZXItZG93bmxvYWQiIGRhdGEtbmFtZT0iSWNvbiBmZWF0aGVyLWRvd25sb2FkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjUgMC41KSI+DQogICAgPHBhdGggaWQ9IlRyYWPDqV8xNDY2NSIgZGF0YS1uYW1lPSJUcmFjw6kgMTQ2NjUiIGQ9Ik0xOC4zMzQsMjIuNXYzLjY2N0ExLjcsMS43LDAsMCwxLDE2LjgsMjhINi4wMzdBMS43LDEuNywwLDAsMSw0LjUsMjYuMTY3VjIyLjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00LjUgLTE0LjE2NikiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2I0MmI2YSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjEiLz4NCiAgICA8cGF0aCBpZD0iVHJhY8OpXzE0NjY2IiBkYXRhLW5hbWU9IlRyYWPDqSAxNDY2NiIgZD0iTTEwLjUsMTVsMy4yNSw0LjU4M0wxNywxNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTYuODMzIC0xMC4wMTkpIiBmaWxsPSJub25lIiBzdHJva2U9IiNiNDJiNmEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxIi8+DQogICAgPHBhdGggaWQ9IlRyYWPDqV8xNDY2NyIgZGF0YS1uYW1lPSJUcmFjw6kgMTQ2NjciIGQ9Ik0xOCwxNC4wNjRWNC41IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTEuMDgzIC00LjUpIiBmaWxsPSJub25lIiBzdHJva2U9IiNiNDJiNmEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIxIi8+DQogIDwvZz4NCjwvc3ZnPg0K);
                        }
                    }
                }
            }
        }
    }
}