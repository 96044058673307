.card-reasurance {

    @media (max-width:991.98px) {
        flex: 0 0 50%;
        min-width: 50%;
    }

    &:nth-child(2) {
        width: 25%;

        @media (max-width:991.98px) {
            flex: 0 0 50%;
            min-width: 50%;
        }
    }

    &:nth-child(3) {
        width: 25%;

        @media (max-width:991.98px) {
            flex: 0 0 50%;
            min-width: 50%;
        }
    }

    &:nth-child(4) {
        width: 25%;

        @media (max-width:991.98px) {
            flex: 0 0 50%;
            min-width: 50%;
        }
    }

    .elementor-widget-wrap {
        display: flex;
        align-items: center;

        @media (max-width:767.98px) {
            flex-direction: column;
        }
    }

    &-img {
        flex: 0 0 135px;

        @media (max-width:767.98px) {
            flex: 0;
        }

        .elementor-image {
            display: flex;
            justify-content: flex-end;

            @media (max-width:991.98px) {
                justify-content: flex-start;
            }
            > a {
                width: 100%;
            }

            img {
                height: 76px;
                width: auto;
                @media (max-width:767.98px) {
                    height: 48px;
                    width: auto;

                    line-height: 23px;
                    margin-right: 20px;
                    margin-left: -15
                }
                @media (max-width:767.98px) {
                    height: 48px;
                    width: auto;
                    margin: 0;
                    margin-bottom: 10px;
                }
            }
        }
    }

    &-title {
        flex: 1;
        margin-left: 10px;
        @media (max-width:767px) {
            margin: 0;
        }
        .elementor-heading-title {
            margin-left: 0;
            margin-right: 16px;
        }
        .elementor-heading-title ,
        .elementor-heading-title a{
            font-size: 16px;
            line-height: 21px;
            font-weight: 600;
            color: @black;
            display: block;
            text-transform: uppercase;
            @media (max-width:1025px) {
                margin-left: 0;
                font-size: 13px;
                text-align: center;
                line-height: 1.2;
                margin-top: 10px;
                margin-bottom: -4px;
                margin-right: -20px;
            }
            @media (max-width:922.98px) {
               margin: 0;
            }
        }
    }
}
.reassurance-product {

    @media (max-width:991.98px) {
        .elementor-row {
            flex-wrap: initial;
        }
    }
    .swiper-container {
        padding-bottom: 60px;
        .swiper-pagination {
            display: none;
            @media (max-width:991.98px) {
                display: block;
            }
            .swiper-pagination-bullet {
                background: @white;
                border: 1px solid @lightgreen;
                width: 9px;
                height: 9px;
                opacity: 1;
                &.swiper-pagination-bullet-active {
                    background: @lightgreen;
                }
            }
        }
    }
    .elementor-image-box-img {
        display: flex;
        justify-content: flex-end;

        @media (max-width:991.98px) {
            width: 100%;
            justify-content: center;
        }
        > a {
            width: 100%;
        }

        img {
            height: auto;
            width: auto;
            max-width: 60px;
            @media (max-width:767.98px) {
                height: 48px;
                width: auto;

                line-height: 23px;
                margin: 0;
            }
            @media (max-width:767.98px) {
                height: 48px;
                width: auto;
                margin: 0;
            }
        }
    }
}
body#product .elementor-displayFooterProduct .reassurance-product .elementor-column {
    &:nth-child(1) {
        flex: 0 0 35%;
        @media (max-width: 1025px) {
            flex: 0 0 33.33%;
            min-width: 33.33%;
            margin-left: 20px;
            padding: 0;
        }
        @media (max-width: 991.98px) {
            flex: initial;
            min-width: 50%;
            margin-left: initial;
            padding: 0;
        }


    }

    &:nth-child(2) {
        flex: 0 0 46%;
        padding-left: 35px;

        @media (max-width: 1025px) {
            flex: 0 0 33.33%;
            min-width: 33.33%;
            margin-left: 20px;
            padding: 0;
        }
        @media (max-width: 991.98px) {
            flex: initial;
            min-width: 50%;
            margin-left: initial;
            padding: 0;
        }
    }

    &:nth-child(3) {
        flex: 1;
        padding-left: 13px;

        @media (max-width: 1025px) {
            flex: 0 0 33.33%;
            min-width: 33.33%;
            margin-left: 20px;
            padding: 0;
        }
        @media (max-width: 991.98px) {
            flex: initial;
            min-width: 50%;
            margin-left: initial;
            padding: 0;
        }
    }
}