#bg-reassurance {
  padding: 80px 0 !important;
  position: relative;
  .bgimg { 
    width: 100vw;
    position: absolute;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    z-index: 0;
    top: 0;
    padding: 0;
    height: 100%;
    min-height: 440px;
    img {
      width: 100%;
      height: 100%;
      @media (max-width: 1200px) {
        object-fit: cover;
      }
    }
  }
  .reassurance {
    margin: 0;
    padding: 0;
    &-bloc {
      display: flex;
      flex-wrap: wrap;
    }
    &-item {
      max-width: 25%;
      flex: 0 0 25%;
      display: flex;
      justify-content: center;

      @media (max-width: 1024px) {
        max-width: 50%;
        flex: 0 0 50%;
        margin-bottom: 25px;
      }
      @media (max-width: 630px) {
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
    &-content {
      width: 280px;
      min-height: 280px;
      height: 100%;
      background: @green2;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 10px;
    }
    &-image {
      margin-bottom: 12px;
      height: 70px;
      img {
        max-height: 68px;
        width: auto;
      }
    }
    &-desc {
      text-align: center;
      p {
        font-size: 25px !important;
        font-weight: 700 !important;
        text-transform: uppercase;
        color: @white !important;
        text-align: center !important;
        margin-bottom: 35px !important;
        line-height: 30px !important;
      }
      .btn {
        font-family: @font-base;
        font-weight: 400;
        font-size: 17px;
        color: @white;
        background: none;
        text-transform: initial;
        border: none;
        border-bottom: 1px solid @white;
        padding: 14px 0;
        transition: all 0.4s ease-in;
        display: initial;
        border-radius: 0;
        &:hover {
          padding: 14px 5px;
        }
      }
    }
  }
}
