body#cms {
  .history-head {
    max-width: 955px;
    margin: 0 auto 84px;
    h2 span {
      font-size: 38px;
      font-weight: 700;
      color: @green2;
      text-transform: initial;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      color: @black;
      line-height: 22px;
      strong {
        font-weight: 600;
      }
    }
  }
  .story {
    padding: 76px 0 0 0;
    &:after {
      content: "";
      position: absolute;
      top: 106px;
      left: 50%;
      transform: translateX(-50%);
      background: @green2;
      width: 4px;
      height: calc(100% - 150px);
      @media (max-width: 768px) {
        content: initial;
      }
    }
    .event {
      > .elementor-container > .elementor-row {
          .elementor-column {
            &:first-of-type {
              padding-right: 72px;
              @media (max-width: 768px) {
                padding: 0 15px;
              }
            }
            &:last-of-type {
              padding-left: 72px;
              @media (max-width: 768px) {
                padding: 0 15px;
              }
            }
          }
      }
      .description-bloc {

        @media (max-width: 768px) {
          order: -1;
          margin: 0 0 20px;
        }

        h2 span {
          font-size: 25px;
          font-weight: 400;
          text-transform: initial;
          line-height: 35px;

          strong {
            font-size: 38px;
            font-weight: 600;
          }
        }
        p {
          font-size: 14px;
          font-weight: 400;
          strong {
            font-weight: 600;
          }
          em {
            font-size: 11px;
          }
        }
        &.right {
          h2 span {
            text-align: left;
          }
          .icon {
            position: absolute;
            left: -127px;
            top: 0;

            @media (max-width: 768px) {
              position: relative;
              left: 0;
              margin: 0 0 20px;
              text-align: left;
            }
          }
        }
        &.left {
          h2 span {
            text-align: right;
          }
          .icon {
            position: absolute;
            right: -127px;
            top: 0;

            @media (max-width: 768px) {
              position: relative;
              right: 0;
              margin: 0 0 20px;
              text-align: right;
            }
          }
        }
      }
    }
    .last-card {
      max-width: 445px;
      background: @green2;
      margin: 0 auto;
      padding: 25px 0;

      @media (max-width: 768px) {
        margin: 0 15px;
      }
      span {
        font-size: 25px;
        font-weight: 600;
        color: @white;
        line-height: 30px;
        text-align: center;
        display: block;
      }
    }
  }
  .bio-bloc {
    p {
      font-size: 14px;
      font-weight: 400;
      color: @black;
      text-align: center;
      margin-bottom: 25px;
      strong {
        font-weight: 600;
      }
    }
    span {
      font-size: 19px;
      font-weight: 600;
      color: @green2;
      text-align: center;
      display: block;
    }
  }
}