body#cms.cms-id-19 {
  &:after {
    content: '';
    background-image: url("@{img}faq2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: -100px;
    top: 55%;
    transform: translateY(-50%);
    display: block;
    width: 306px;
    height: 205px;
    pointer-events: none;
    z-index: 0;

    @media (max-width:768px) {
      content: initial;
    }
  }
  &:before {
    content: '';
    background-image: url("@{img}faq1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: -185px;
    top: 500px;
    display: block;
    width: 342px;
    height: 365px;
    pointer-events: none;
    z-index: 0;

    @media (max-width:768px) {
      content: initial;
    }
  }
  .breadcrumb {
    display: none;
  }
  div.category-header {
    display: flex;
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 768px) {
      background-size: cover;
    }

    div.category-image {
      width: 100vw;
      position: absolute;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      z-index: 0;
      top: 0;
      padding: 0;
      height: 100%;

      img {
        height: 100%;
        width: 100%;
      }
    }

    div.category-description {
      margin: 0 auto 1px 0;
      padding: 48px 26px;

      @media (max-width: 991.98px) {
        padding: 140px 20px 80px 20px;
      }

      @media (max-width: 767.98px) {
        padding: 80px 20px 80px 20px;
      }

      h1 {
        font-family: @font-base;
        font-size: 36px !important;
        font-weight: 700;
        text-align: center;
        color: @white;
      }
    }
  }
}
body#cms section#main {
  .top-faq {
    padding-bottom: 67px;
    position: relative;
    max-width: 1010px;
    margin: 0 auto;
    .elementor-widget-heading {
      h2 {
        font-family: @font-base;
        font-size: 46px;
        font-weight: bold;
        text-align: center;
        color: @green2;
        margin-bottom: 19px;
        margin-top: 64px;
        @media (max-width: 768px) {
          font-size: 30px;
        }
      }
    }
    h3 {
      font-family: @font-base;
      font-size: 22px;
      font-weight: 400;
      text-align: center;
      color: @green2;
      margin-bottom: 25px;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    p {
      font-family: @font-base;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      color: @black;
      @media (max-width: 768px) {
        font-size: 14px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        line-clamp: 6;
        -webkit-box-orient: vertical;
      }
    }
  }
  .content-faq {
     > .elementor-container {
      max-width: 1430px;
       > .elementor-row {
         @media (max-width: 1024px) {
           flex-direction: column-reverse;
         }
       }
       .elementor-column.elementor-col-33 {
         width: 422px;
         @media (max-width: 1024px) {
           width: 100%;
           padding: 0 15px;
         }
       }
       .elementor-column.elementor-col-66 {
         width: calc(100% - 422px);
         @media (max-width: 1024px) {
           width: 100%;
           padding: 0 15px;
         }
         > .elementor-column-wrap {
           padding: 15px 15px 15px 20px;
           @media (max-width: 1024px) {
             padding: 0;
           }
         }
       }
    }
    //&:after {
    //  content: "";
    //  background-image: url("@{img}DECOR-feuille-f3.png");
    //  background-repeat: no-repeat;
    //  background-size: contain;
    //  width: 465px;
    //  height: 303px;
    //  display: inline-block;
    //  margin: 0;
    //  position: absolute;
    //  left: 0;
    //  bottom: 0;
    //  @media (max-width: 1320px) {
    //    width: 239px;
    //    height: 156px;
    //    left: -71px;
    //    bottom: initial;
    //    top: 35px;
    //    transform: rotate(-10deg);
    //  }
    //}
    //&:before {
    //  content: "";
    //  background-image: url("@{img}DECOR-feuille-f2.png");
    //  background-repeat: no-repeat;
    //  background-size: contain;
    //  width: 360px;
    //  height: 553px;
    //  display: inline-block;
    //  margin: 0;
    //  position: absolute;
    //  right: 0;
    //  top: 500px;
    //  @media (max-width: 1320px) {
    //    display: none;
    //  }
    //}
    //> .elementor-container.elementor-column-gap-default:before {
    //  content: "";
    //  background-image: url("@{img}DECOR-feuille-f1.png");
    //  background-repeat: no-repeat;
    //  background-size: contain;
    //  width: 465px;
    //  height: 303px;
    //  display: inline-block;
    //  margin: 0;
    //  position: absolute;
    //  left: 35%;
    //  top: -85px;
    //  transform: translateX(-50%);
    //  @media (max-width: 1320px) {
    //    display: none;
    //  }
    //}
  }

  .elementor-section.faq-card-accordion {
    background-color: @white;
    margin: 0 0 26px;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(@black , 0.16);
    .elementor-column-gap-default .elementor-element-populated {
      padding: 20px 23px;
    }

    .elementor-widget-image-box {
      .elementor-image-box-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0 0 6px;
        .elementor-image-box-img {
          flex: 0 0 auto;
          width: auto;
          margin: 0;
          img {
            width: auto;
          }
        }
        .elementor-image-box-content {
          flex: 0 0 100%;
          width: 100%;
          h3 {
            font-family: @font-base;
            font-size: 22px;
            font-weight: 500;
            text-align: left;
            color: @green2;
            margin: 0 0 0 10px;
            text-transform: uppercase;
            @media (max-width: 768px) {
              font-size: 18px;
            }
          }
        }
      }
    }
    .elementor-widget-accordion {
      .elementor-accordion .elementor-accordion-item {
        border: 0;
        border-bottom: 1px solid rgba(@purple , 0.6);
        &:last-child {
          border: none;
        }
        .elementor-accordion-title {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          padding: 15px 0;
          font-family: @font-base;
          font-size: 18px;
          font-weight: 500;
          text-align: left;
          color: @gray-darker2;
          @media (max-width: 768px) {
            font-size: 16px;
          }
          .elementor-accordion-icon {
            width: initial;
            i:before {
              content: "";
              background-image: url("@{img}Icon feather-plus.svg");
              width: 14px;
              height: 14px;
              display: block;
              background-size: contain;
            }
          }
        }
        .elementor-accordion-content {
          padding: 15px 0;
          border: none;
          p {
            font-family: @font-base;
            font-size: 14px;
            line-height: 31px;
            text-align: left;
            color: @gray-darker2;

          }
        }
      }
    }
  }
  .elementor-section.contact-bloc {
    background-color: @white;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(@black , 0.16);
    .elementor-column-gap-default .elementor-element-populated {
      padding: 26px 37px;
    }
    h3 {
      font-family: @font-base;
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 26px;
      text-transform: uppercase;
      padding: 0 0 0 11px;
      @media (max-width: 768px) {
        font-size: 21px;
      }
    }
    .contact-item {
      padding: 24px 0 21px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 1px;
        background-color: rgba(@green2 , 0.28);
        max-width: 50%;
      }
      .elementor-image-box-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .elementor-image-box-img {
          flex: 0 0 auto;
          width: auto;
          margin: 0 27px 0 0 !important;
        }
        .elementor-image-box-content {
          flex: 0 0 100%;
          width: 100%;
          h4 {
            font-family: @font-base;
            font-size: 20px;
            font-weight: 500;
            text-align: left;
            color: @green2;
            margin: 0;
            @media (max-width: 768px) {
              font-size: 19px;
            }
          }
          p {
            font-family: @font-base;
            font-size: 15px;
            font-weight: 400;
            text-align: left;
            color: @gray14;
            @media (max-width: 768px) {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
