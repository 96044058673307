body#cms {
  .title-head {
    margin-bottom: 30px;
    @media (max-width: 768px) {
      margin-bottom: 0px;
  }
    p {
      font-size: 38px;
      font-weight: 400;
      color: @green2;
      text-align: center;

      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
  }
  .video-bloc {
    .elementor-row {
      @media (max-width: 1100px) {
        align-items: center;
      }
      @media (max-width: 992px) {
        flex-direction: column-reverse;
        align-items: center;
      }
     > .elementor-column {
       @media (max-width: 992px) {
         width: 100%;
       }
       > .elementor-column-wrap {
         @media (max-width: 992px) {
           margin: 0;
           padding: 20px;
         }
       }
     }
    }
  }
  .desc-equipe {
    h3 {
      font-size: 24px;
      font-weight: 600;
      color: @green2;
      margin: 0;
    }
    span {
      font-size: 19px;
      font-weight: 400;
      color: @green2;
      display: block;
      margin-bottom: 12px;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: 15px;
      }
    }
   p {
      font-size: 14px;
      font-weight: 400;
      color: @black;
     strong {
       font-size: 24px;
       font-weight: 600;
       color: @green2;
       @media (max-width: 768px) {
         font-size: 15px;
       }
     }
    }
  }
  .quote {
    margin-bottom: 65px;
      p {
        font-size: 20px;
        font-style: italic;
        color: @black;
        text-align: center;
        line-height: 1.2;

        @media (max-width: 768px) {
          font-size: 13px;
          line-height: 1.5;
        }
      }
  }
  #main {
    div.equipe {
      padding: 79px 0 44px;
      @media (max-width: 768px) {
        padding: 25px;
      }
       .elementor-container {
         max-width: 1016px;
      }
      h2 {
        display: inline-block;
        padding: 34px 95px 39px;
        background: @green2;
        font-size: 26px;
        font-weight: 700;
        color: @white !important;
        margin-bottom: 79px;
        text-transform: uppercase;
        line-height: 1.2;
        @media (max-width: 768px) {
          padding: 25px;
          font-size: 16px;
          margin-bottom: 26px;
        }
      }
      h3 {
        margin-bottom: 1px;
      }
    }
    .energie {
      @media (max-width: 768px) {
      }
      div.elementor-row {
        align-items: center;
      }
      h2 {
        display: inline-block;
        padding: 36px 55px;
        background: @green2;
        font-size: 26px;
        font-weight: 700;
        color: @white;
        margin-bottom: 0;
        z-index: 2;
        text-transform: uppercase;
        line-height: 1.2;
        @media (max-width: 768px) {
          padding: 25px;
          font-size: 16px;
          margin-bottom: 26px;
        }
      }
      .title-head  {
        p {
          margin-bottom: 25px;
        }
      }
  
    }
    .service {
      p {
        font-size: 31px;
        font-weight: 600;
        color: @green2;
        text-align: center;
        margin-bottom: 18px;
        @media (max-width: 768px) {
          font-size: 20px;
          margin: 0 0 5px;
        }
      }
    }
  }
  .quote-equipe {
    h3,h4 {
      font-size: 38px;
      @media (max-width: 768px) {
        font-size: 25px;
      }
    }
  }
}