#category {
    .elementor-element-vsejtvo {
        padding: 0;
        @media screen and (max-width: 768px) {
            margin-bottom: 30px;
        }
    }
}

.card-alim {

    @media (max-width:991.98px) {
        flex: 0 0 50%;
        min-width: 50%;
        margin-bottom: 20px;
    }
    .elementor-image {
        img {
            height: 82px;
            object-fit: contain;

            @media (max-width:991.98px) {
                height: 90px;
            }

            @media (max-width:767.98px) {
                height: 48px;
                width: auto;
            }
        }
    }

    .elementor-widget-image {
        margin-bottom: 21px;

        @media (max-width:991.98px) {
            margin-bottom: 16px;
        }

        @media (max-width:767.98px) {
            margin-bottom: 0;
        }
    }

    h3.elementor-heading-title ,
    h3.elementor-heading-title a {
        font-size: 16px;
        font-family: @font-base3;
        line-height: 21px;
        color: @green2;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: -1.2px;
        margin-bottom: 2px;

        @media (max-width:991.98px) {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: -1px;
        }

        @media (max-width:767.98px) {
            margin-left: 0;
            text-align: center;
            line-height: 1.2;
            margin-top: 10px;
            font-size: 12px !important;
        }
    }

    span.elementor-heading-title ,
    span.elementor-heading-title a {
        color: @lightgreen;
        font-family: @font-base3;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: -1px;
        font-size: 16px;

        @media (max-width:991.98px) {
            font-size: 12px;
            letter-spacing: -0.6px;
        }
        @media (max-width:767.98px) {
            font-size: 12px !important;
        }
    }

    .elementor-widget-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}