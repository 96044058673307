body#cms {

  &.engagement {
    div:not('.elementor-element-7zfu5qm') {
      .elementor-container {
        max-width: 1170px;
        .elementor-widget-wrap {
          height: fit-content;
        }
      }
    }
    .txt-description {
      @media (max-width:768px) {
        order: 2;
      }
    }
    .leaf {
      &:after {
        content: '';
        background-image: url("@{img}DECOR-feuille-03.png");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: -108px;
        top: 0;
        display: block;
        width: 485px;
        height: 418px;

        @media (max-width:768px) {
          width: 334px;
        }
      }
    }
    .img-title {
      left: -68px;
      @media screen and (max-width: 992px) {
        left: 50%;
        transform: translateX(-50%);
        top: -21px;
      }
      &.left {
        right: -68px;
        left: initial;
        @media screen and (max-width: 992px) {
          left: 50%;
          transform: translateX(-50%);
          top: -21px;
        }
      }
    }
  }
  section#main {
    h2 {
      font-family: @font-base;
      font-weight: 700;
      font-size: 38px;
      color: @green2;
      text-transform: initial;
      @media screen and (max-width: 768px) {
        font-size: 28px;
      }
    }
    h3 {
      font-family: @font-base;
      font-weight: 700;
      font-size: 25px;
      color: @green2;
      text-transform: initial;
      line-height: 1.2;
    }
    .quotidien {
      h3 {
        font-size: 38px;
        font-weight: 400;
        @media (max-width: 768px) {
          font-size: 25px;
        }
      }
    }
    ol li,
    ul li,
    p {
      font-family: @font-base;
      font-weight: 400;
      font-size: 14px;
      color: @black;
      line-height: 21px;
      margin: 0 0 3px;
    }
  }
  
  .img-title {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -25px;
    background: @green2;
    z-index: 2;
    max-width: 152px;
    width: 100%;
    height: 152px;
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 992px) {
      left: 50%;
      transform: translateX(-50%);
      top: -21px;
    }
    &.left {
      right: -35px;
      left: initial;
    }
  }
  section#main {
    .mini-card {
      background-color: @gray13;
      max-width: 888px;
      width: 100%;
      margin: -205px auto 95px;
      padding: 17px 113px  45px;
      box-shadow: 0 5px 6px rgba(@black, 0.16);
      @media screen and (max-width: 768px) {
        padding: 20px;
      }
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: url("@{img}FOND-beige_1.png");
        width: 100%;	
        height: 100%;	
        background-size: contain;	
        background-repeat: no-repeat;
        opacity: 0.6;
      }
      h3 {
        font-family: @font-base;
        font-weight: 600;
        font-size: 14px;
        color: @green2;
        text-transform: uppercase;
      }
      p {
        font-family: @font-base;
        font-weight: 400;
        font-size: 14px;
        color: @black;
        text-transform: initial;
        margin-bottom: 0px;
      }
      hr {
        background-color: @black;
        margin: 8px 0 15px;
      }
    }
  }
}
