body#cms.cms-id-24 {
  background-color: @white;
  padding: 0 !important;

  &:after {
    content: '';
    background-image: url("@{img}faq2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: -100px;
    top: 55%;
    transform: translateY(-50%);
    display: block;
    width: 306px;
    height: 205px;
    pointer-events: none;
    z-index: 0;

    @media (max-width:768px) {
      content: initial;
    }
  }

  &:before {
    content: '';
    background-image: url("@{img}faq1.svg");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    right: -185px;
    top: 500px;
    display: block;
    width: 342px;
    height: 365px;
    pointer-events: none;
    z-index: 0;

    @media (max-width:768px) {
      content: initial;
    }
  }

  div.category-header {
    padding: 28px 0 72px;
    min-height: 240px;
    display: flex;
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 892px) {
      background-position: center;
      padding: 0;
      max-height: 156px;
      min-height: 156px;
      height: 100%;
    }

    div.category-image {
      margin-bottom: 0;
      width: 100vw;
      position: absolute;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      z-index: 0;
      top: 0;
      padding: 0;
      height: 100%;
      min-height: 240px;
      overflow: hidden;

      @media (max-width: 892px) {
        max-height: 156px;
        min-height: 156px;
        height: 100%;
      }

      img {
        height: 100%;
        width: 100%;

        @media (max-width: 1400px) {
          object-fit: cover;

        }

        @media (max-width: 892px) {
          object-fit: cover;
          object-position: 80%;

        }
      }
    }

    div.category-description {
      background-color: @green2;
      padding: 54px 26px 55px;
      max-width: 455px;
      margin: 0 0 0 162px;
      height: fit-content;
      width: 100%;
      z-index: 3;

      @media screen and (max-width: 1024px) {
        margin: 0;
        padding: 25px 10px;
        max-width: 218px;
      }

      h1 {
        font-family: @font-base;
        font-weight: 600;
        font-size: 26px !important;
        text-align: center;
        color: @white;
        line-height: 1.2;
        margin: 0;

        @media screen and (max-width: 992px) {
          font-size: 14px !important;
        }
      }
    }
  }

  section#main {
    h2 {
      font-family: @font-base;
      font-size: 38px;
      line-height: 47px;
      font-weight: 700;
      color: @green2;
      text-align: center;
      margin-bottom: 46px;

      @media screen and (max-width: 992px) {
        font-size: 18px !important;
        line-height: 22px;
        margin-bottom: 37px;
      }
    }

    .description {
      p {

        font-family: @font-base;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: @green2;
        max-width: 954px;
        margin: 0 auto;
        margin-bottom: 64px;

        @media screen and (max-width: 992px) {
          font-size: 12px !important;
          line-height: 22px;
          margin-bottom: 44px;
        }
      }
    }

    .container {
      width: 100%;
      position: relative;
      padding-top: 38px;
      padding-bottom: 64px;

      @media (max-width: 768px) {
        padding: 0 20px;
      }

      &.event-wapper {
        @media (max-width: 768px) {
          padding: 30px 0px;
        }

        &::before {
          content: "";
          position: absolute;
          width: 100vw;
          height: 100%;
          left: 50%;
          transform: translateX(-50%);
          top: 0;
          background-color: #F3ECE4;
          z-index: -1;
        }

        &::after {
          content: "";
          position: absolute;
          width: 100vw;
          height: 100%;
          left: 50%;
          transform: translateX(-50%);
          top: 0;
          z-index: -1;
        }
      }

    }

    .breadcrumb {
      display: none;
    }

    .year {
      margin-bottom: 80px;
      display: flex;
      justify-content: center;
      flex: 0 0 100%;
      max-width: 100%;
      opacity: 0;

      @media (max-width: 768px) {
        justify-content: flex-start;
        margin-top: 40px;
      }

      span {
        width: 96px;
        height: 96px;
        box-shadow: 0 0 15px 0 rgba(@black, 0.16);
        background-color: @green2;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: @font-base;
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        color: @white;
        border-radius: 50%;

        @media (max-width: 768px) {
          width: 94px;
          height: 94px;
        }
      }
    }

    .events {
      display: flex;
      justify-content: space-between;
      flex-flow: wrap;
      padding-top: 28px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        background-color: @bgcolor-footer;
        width: 2px;
        height: calc(100% + 90px);
        top: -80px;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: -1;

        @media (max-width: 768px) {
          left: 45px;
          right: initial;
        }
      }

      &-card {
        flex: 0 0 calc(50% - 72px);
        max-width: calc(50% - 72px);
        flex-flow: wrap;
        box-shadow: 0 3px 10px rgba(@black, 0.16);
        position: relative;
        margin-bottom: 134px;
        padding: 15px 15px 20px 15px;
        background-color: @white;
        height: fit-content;

        @media (max-width: 768px) {
          flex: 0 0 calc(100% - 120px);
          max-width: calc(100% - 120px);
          margin: 75px 0 0;
          margin-left: auto;
        }

        &:nth-child(odd) {
          &:after {
            content: " ";
            background-image: url("@{img}Union.png");
            width: 26px;
            height: 23px;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            right: -25px;
            top: 0;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);

            @media (max-width: 768px) {
              transform: initial;
              right: initial;
              left: -25px;
            }
          }

          .date-event {
            right: -81px;
            top: -8px;

            @media (max-width: 768px) {
              left: -107px;
              right: initial;
              top: -30px;
            }
          }
        }

        &:nth-child(even) {
          margin-top: 175px;

          @media screen and (max-width: 992px) {
            margin-top: 75px;
          }

          &:after {
            content: " ";
            background-image: url("@{img}Union.png");
            width: 26px;
            height: 23px;
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            display: inline-block;
            left: -25px;
            top: 0;

            @media (max-width: 768px) {
              top: 0;
              left: -25px;
            }
          }

          .date-event {
            left: -81px;
            top: -8px;

            @media (max-width: 768px) {
              left: -74px;
              transform: translateX(-50%);
            }
          }
        }

        .date-event {

          position: absolute;

          >span {
            display: flex;
            flex-direction: column;
            font-family: @font-base;
            font-size: 14px;
            font-weight: 300;
            color: @white;
            width: 19px;
            height: 19px;
            background-color: @green2;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            border: 4px solid @beige;


            .day {
              font-family: @font-base;
              font-size: 22px;
              font-weight: 300;
              text-align: center;
              color: @white;
            }
          }
        }

        .image {
          box-shadow: 0 0 3px 0 rgba(@black, 0.16);
          border: solid 1px rgba(@green2, 0.4);
          text-align: center;
          padding: 5px 0;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;

            @media (max-width: 992px) {
              max-width: 180px;
            }
          }
        }

        .title {
          padding: 10px 15px;
          margin: 0 -15px;
          margin-bottom: 18px;
          border-bottom: solid 1px rgba(@green2, 0.2);

          h2 {
            font-family: @font-base;
            font-size: 24px;
            font-weight: bold;
            text-transform: uppercase;
            color: @green2;
            margin-bottom: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            text-align: left;

            @media (max-width: 992px) {
              font-size: 17px;
              line-height: 1;
              margin-bottom: 15px;
            }
          }

          .min-infos {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .brand {
              font-family: @font-base;
              font-size: 16px;
              font-weight: 400;
              text-transform: uppercase;
              color: @green2;

              @media (max-width: 992px) {
                font-size: 14px;
              }
            }

            .date {
              font-family: @font-base;
              font-size: 16px;
              font-weight: 400;
              color: @gray-darker2;

              @media (max-width: 992px) {
                font-size: 14px;
              }
            }
          }
        }

        .description {
          div {
            margin-bottom: 20px;

            p {
              font-family: @font-base;
              color: @gray-darker2;
              font-size: 16px;
              font-weight: 400;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              -webkit-box-orient: vertical;
              display: inline;

              @media (max-width: 992px) {
                font-size: 14px;
              }
            }
          }


          .more {
            font-family: @font-base;
            font-size: 18px;
            font-weight: 500;
            color: @green2;

            @media (max-width: 992px) {
              font-size: 14px;
            }

            i {
              margin: 0 0 0 13px;

              @media (max-width: 992px) {
                margin: 0 0 0 5px;
              }

              &:before {
                content: "";
                background-image: url("@{img}slick-arrow-left.svg");
                background-repeat: no-repeat;
                background-size: contain;
                width: 7px;
                height: 12px;
                display: inline-block;
                transform: rotate(180deg);

                @media (max-width: 992px) {
                  top: 1px;
                  position: relative;
                }
              }
            }
          }
        }
      }
    }

    .loadmore-wrapper {
      .loadmore {
        margin: 0px;
        display: flex;
        justify-content: center;
        flex: 0 0 100%;
        max-width: 100%;
        cursor: pointer;

        @media (max-width: 768px) {
          justify-content: flex-start;
        }

        img {
          width: 80px;
          height: 80px;
          // box-shadow: 0 0 15px 0 rgba(@black, 0.16);
          // background-color: @green2;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: @font-base;
          font-size: 24px;
          font-weight: 600;
          text-align: center;
          color: @white;
          border-radius: 50%;
        }
      }
    }

    .brand {
      position: relative;

      @media (max-width: 1224px) {
        margin: 0 20px;
      }

      .slick-arrow {
        font-size: 0;
        border: none;
        background: none;

        &.slick-prev {
          &:before {
            content: "";
            background-image: url("@{img}slick-arrow-left.svg");
            background-repeat: no-repeat;
            background-size: contain;
            width: 16px;
            height: 26px;
            display: inline-block;
            position: absolute;
            left: -25px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }
        }

        &.slick-next {
          &:before {
            content: "";
            background-image: url("@{img}slick-arrow-left.svg");
            background-repeat: no-repeat;
            background-size: contain;
            width: 16px;
            height: 26px;
            display: inline-block;
            position: absolute;
            right: -25px;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
            cursor: pointer;
          }
        }
      }

      ul.slick-dots {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 0;
        width: 100%;
        line-height: 0;
        margin: 0 0 62px;
        li {
          background: @green2;
          height: 2px;
          width: 19px;
          border-radius: 5px;
          margin: 0 3px;
          opacity: 0.8;
          transition: 0.5s ease;

          button {
            border: none;
            box-shadow: none;
            background: none;
            font-size: 0;
          }

          &.slick-active {
            width: 39px;
            height: 3px;
            background: @green2;
            opacity: 1;
          }
        }
      }

      .slick-list {
        overflow: hidden;
      }

      .slick-track {
        display: flex;
        justify-content: center;
        padding: 10px 0;
        margin: 0 auto;

        .slick-slide {
          margin: 0 7.5px;
        }
      }

      .brand-item {
        height: 139px;
        border-radius: 5px;
        box-shadow: 0 0px 6px rgba(@black, 0.16);
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }
  }
}