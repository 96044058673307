body#category {
  .container,
  .content-category,
  .elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1440px;
  }
  .products-grid .product-miniature-default {
    @media (max-width: 992px) {
      margin: 0 0 40px;
    }
  }
  &.headerbg {

    overflow: initial;
    overflow-x: clip;
    .content-category,
    .elementor-section.elementor-section-boxed>.elementor-container {
      max-width: 1440px;
    }
    .reassurance.no-bg {
      display: none;
    }

    div.category-header div.category-description {
      margin: 0 auto;
      max-width: 598px;
      padding: 41px 26px 35px;
    }
    div.category-description {
      padding: 43px 18px 34px;
      h1 {
        text-transform: capitalize;
        line-height: 1.5;
      }
    }
    .left-column div.elementor-element-populated {
      margin: 0;
    }
    .bloc-interaction {
      margin: 0 162px 0 78px;
      @media (max-width: 1440px) {
        margin: 0;
      }

      .item-interaction {
        max-width: 195px;
        .elementor-image-box-img {
          display: none;
        }
        .elementor-image-box-content {
          padding: 40px 16px !important;
        }
      }
    }
    div#content-wrapper {
      flex: initial;
      max-width: initial;
      padding: 0 21px;
      .side-carte {
        position: absolute;
        right: -27px;
        bottom: -23px;
        @media screen and (max-width: 992px) {
          position: relative;
          right: initial;
        }

        &.left {
          right: initial;
          left: 0;
        }
      }
      .right-column {
        flex: 0 0 calc(100% - 280px);
        max-width: calc(100% - 280px);
        padding: 0 0 0 53px;
        @media screen and (max-width: 1300px) {
          flex: 0 0 75%;
          max-width: 75%;
        }
        @media screen and (max-width: 1024px) {
          flex: 0 0 100%;
          max-width: 100%;
          margin: 0;
        }
        @media screen and (max-width: 992px) {
          padding: 0;
        }
      }
      .left-column {
        flex: 0 0 280px;
        max-width: 280px;
        padding: 0;
        display: inline-block;
        height: 100%;

        @media screen and (max-width: 1300px) {
          flex: 0 0 25%;
          max-width: 25%;
        }
        @media screen and (max-width: 1024px) {
          flex: 0 0 100%;
          max-width: 100%;
          margin: 0 0 30px 0;
        }
        @media screen and (max-width: 992px) {
          height: auto;
        }
        > .elementor-column-wrap.elementor-element-populated {
          position: sticky;
          top: 150px;
          z-index: 50;
          @media screen and (max-width: 1024px) {
            position: static;
          }
        }
        &.display-header-bg {
          > .elementor-column-wrap.elementor-element-populated {
              position: static;
          }
        }
      }
      .product-miniature {
        h2 {
          margin: 0;
        }
      }
      .display-header-bg p {
        font-family: @font-base;
        font-weight: 700;
        font-size: 38px !important;
        text-align: left;
        color: @green2;
        margin-top: 7px;
        margin-bottom: 64px;
        padding-bottom: 0;
        line-height: 1;
        @media screen and (max-width: 992px) {
          font-size: 25px !important;
          margin-bottom: 20px;
        }
      }
      h2 {
        font-family: @font-base;
        font-weight: 600;
        font-size: 20px;
        color: @green2;
        padding-right: 110px;
        @media screen and (max-width: 992px) {
          padding-right: 0;
        }
        span {
          &::first-letter {
            text-transform: uppercase;
          }
        }
      }
      p,
      ul li {
        font-family: @font-base;
        font-weight: 400;
        font-size: 14px;
        text-align: left;
        color: @black;
        line-height: 22px;
        margin: 0;
        em {
          font-size: 10px;
          line-height: 0;
        }
        a {
          font-weight: bold;
          font-size: 14px;
          text-decoration: underline;
          span {
            color: @black !important;
          }
          &:hover {
            opacity: .7;
            text-decoration: none;
          }
        }
      }
      ul {
        padding: 0 0 0 16px;
      }
      .img-title {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        background: @green2;
        z-index: 2;
        max-width: 162px;
        padding: 27px 10px 27px 10px;
        @media screen and (max-width: 767px) {
          left: 50%;
          transform: translateX(-50%);
          top: -21px;
        }
        p,
        h4 {
          text-align: center;
          font-size: 22px;
          font-weight: 400;
          color: @white;
          line-height: 1.4;
        }
      }
      .with-bg {
        &.leaf {
          &:after {
            content: "";
            position: absolute;
            bottom: 95px;
            left: 34%;
            background-image: url("@{img}DECOR-feuille-01.png");
            background-size: contain;
            display: block;
            z-index: 1;
            width: 100vw;
            height: 507px;
            background-repeat: no-repeat;
            margin-left: -50vw;
            pointer-events: none;
          }
        }

        &:before {
          content: "";
          background: @beige;
          width: 100vw;
          height: 100%;
          position: absolute;
          margin-left: -50vw;
          left: 34%;
          top: 0;
          pointer-events: none;

          @media screen and (max-width: 1024px) {
            left: 50%;
          }
        }
      }
      div.interaction {
        background: @green2;

        @media screen and (max-width: 1024px) {
          margin: 0 auto 20px;
        }
        .elementor-image-box-img {
          width: 100%;
          height: 195px;
          margin-bottom: 8px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .elementor-image-box-content {
          padding: 0 16px 12px;
          .elementor-image-box-title {
            text-align: center;
            font-size: 14px;
            font-weight: 700;
            color: @white;
            margin: 0 0 2px;
            line-height: 1.4;
            height: auto;
          }
          .elementor-image-box-description p {
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            color: @white;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            // display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            margin: 0;
            height: 72px;
            &::first-letter {
              text-transform: uppercase;
            }
          }
        }
      }
      .bloc-reassurance {
        &.sous-categ {
          .sommaires {
            .elementor-widget-wrap {
              padding: 0;
              margin: 0;
              display: block;
            }
          }
        }
        .sommaires {
          .elementor-widget-wrap {
            padding: 0;
            margin: 3px -53px 0;
            @media (max-width: 992px) {
              margin: 0 ;
            }
          }
          p {
            font-family: @font-base;
            font-weight: 300;
            font-size: 25px;
            text-align: left;
            color: @green2;
            margin-bottom: 16px;
          }
          ul {
            padding: 0 0 0 8px;
            list-style: none !important;
            li {
              font-family: @font-base;
              font-weight: 600;
              font-size: 13px;
              text-align: left;
              color: @green2;
              position: relative;
              padding: 0;
              &:before {
                content: "";
                position: absolute;
                left: -8px;
                top: 50%;
                transform: translateY(-50%);
                background: @green2;
                width: 4px;
                height: 4px;
                border-radius: 50px;
                display: block;
              }
              a {
                font-family: @font-base;
                font-weight: 600;
                font-size: 13px;
                text-align: left;
                color: @green2;
                position: relative;
                padding: 0;
                text-decoration: none;
                &:before {
                  content: "";
                  position: absolute;
                  left: -8px;
                  top: 50%;
                  transform: translateY(-50%);
                  background: @green2;
                  width: 4px;
                  height: 4px;
                  border-radius: 50px;
                  display: block;
                }
              }

            }
          }
        }
        .elementor-widget-wrap {
          display: flex;
          flex-wrap: wrap;
          margin: 0 -51px;
          padding: 0 38px;

          @media (max-width: 992px) {
            margin: 0 ;
            padding: 0;
          }
          .elementor-widget-image {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 24px;
            padding: 0 30px;
            @media (max-width: 992px) {
              padding: 0 ;
            }
            img {
              height: 350px; 
              object-fit: cover;
            }
          }
          .actif-reassurance {
            flex: 0 0 25%;
            max-width: 25%;
            padding: 0 10px;

            &:last-child {
              .elementor-image-box-content {
                &:after {
                  content: initial;
                }
              }
            }
            .elementor-image-box-wrapper .elementor-image-box-img {
              width: 100%;
              margin-bottom: 7px;
              height: 34px;
            }
            .elementor-image-box-content {
              position: relative;
              &:after {
                content: "";
                width: 1px;
                height: 34px;
                background: black;
                right: -10px;
                top: 0;
                position: absolute;
              }
              .elementor-image-box-title {
                font-weight: bold;
              }
              .elementor-image-box-title,
              p {
                font-size: 10px !important;
                color: black !important;
                margin: 0;
                text-align: center;
                line-height: 1.2;
                @media (max-width: 768px) {
                  line-height: 1.5;
                }
              }
            }
          }
        }
      }
    }
    .elementor-section:not(.bloc-reassurance) > .elementor-container > .elementor-row {
      align-items: center;
      justify-content: space-around;
      @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
      }
    }
    .elementor-section.bloc-reassurance > .elementor-container > .elementor-row {
      align-items: flex-start;
    }
    .elementor-widget-prestashop-widget-ProductsList {
      box-shadow: 0px 0px 9px rgba(@black, 0.74);
      background-color: @white;
      @media screen and (max-width: 992px) {
        width: 50%;
        margin: 0 auto;
      }
      @media screen and (max-width: 540px) {
        width: 100%;
      }
      div.js-product-miniature-wrapper {
        padding: 10px;
        @media screen and (max-width: 768px) {
          flex: 0 0 100%;
          max-width: 100%;
        }
        h2 {
          margin-bottom: 60px;
        }
      }
      .products-grid.row div.cart-product {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .content-category {
    margin: 0 auto;
    max-width: 1250px;
  }
  section#wrapper nav.breadcrumb {
    padding: 0;
    margin: 25px 0 45px;
  }
  .products-grid.row {
    @media screen and (max-width: 992px) {
      margin: 0 -20px;
    }
    div.cart-product {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      @media screen and (max-width: 992px) {
        padding: 0 10px;
        position: relative;
        flex: 0 0 50%;
        max-width: 50%;
      }
      @media screen and (max-width: 540px) {
        padding: 0 5px;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  div.category-header {
    padding: 28px 0 72px;
    min-height: 240px;
    div.category-image {
      margin-bottom: 0;
    }
    div.category-description {
      background-color: @green2;
      padding: 54px 26px 55px;
      max-width: 455px;
      margin: 0 0 0 162px;
      height: fit-content;

      @media screen and (max-width: 1024px) {
        margin: 0;
        padding: 20px;
      }
      p, h1 {
        font-family: @font-base;
        font-weight: 600;
        font-size: 25px !important;
        text-align: center;
        color: @white;
        line-height: 1.2;
        text-transform: initial;
        margin: 0;
        &::first-letter {
          text-transform: uppercase;
        }
        @media screen and (max-width: 992px) {
          font-size: 19px !important;
        }
      }
      //h1 {
      //  font-family: @font-base;
      //  font-weight: 600;
      //  font-size: 39px !important;
      //  text-align: center;
      //  color: @white;
      //
      //  @media screen and (max-width: 992px) {
      //    font-size: 29px !important;
      //  }
      //}
    }
  }
  div#content-wrapper {
    flex: 0 0 calc(100% - 295px);
    max-width: calc(100% - 295px);
    padding: 8px 0 0 0;
    @media screen and (max-width: 1300px) {
      flex: 0 0 80%;
      max-width: 80%;
    }
    @media screen and (max-width: 1024px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .products-grid.row {
      margin: 0;
    }
  }
  div#left-column {
    flex: 0 0 295px;
    max-width: 295px;
    padding: 0;

    @media screen and (max-width: 1300px) {
      flex: 0 0 20%;
      max-width: 20%;
    }
    @media screen and (max-width: 1024px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin: 0;
    }
    @media screen and (max-width: 992px) {
     height: auto;
      margin-bottom: 30px;
    }
    @media screen and (max-width: 768px) {
      order: -1;
    }
    .filtre {
      display: none;
      font-size: 14px;
      font-weight: 400;
      height: 35px;
      line-height: 35px;
      border-radius: 5px;
      background: @pink;
      &:hover {
        border-color: @pink;
        background-color: @white;
        color: @pink;
        i {
          &:after {
            filter: none;
          }
        }
      }
      i {
        &:after {
          content: "";
          position: relative;
          margin-right: 7px;
          background-image: url("@{img}filterIconPink.svg");
          background-repeat: no-repeat;
          background-size: contain;
          width: 14px;
          height: 14px;
          transition: all .3s ease-out;
          display: block;
          filter: brightness(0) invert(1);
        }
      }
      @media screen and (max-width: 768px) {
        display: block;
      }
    }
    div.PM_ASBlockOutput {

      @media screen and (max-width: 768px) {
        position: fixed;
        top: 0;
        left: -100%;
        height: 100vh;
        width: 100%;
        background-color: @gray-light2;
        transition: all 0.4s ease-in-out;
        z-index: 110;
        padding: 30px;
      }

      &.open-filter {
        left: 0;
      }
      .PM_ASCriterionsGroup {
        margin: 0 0 50px;
      }
      span.title-filtre {
        text-align: center;
        font-size: 32px;
        color: @green2;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 60px;
        display: none;

        @media screen and (max-width: 768px) {
          display: block;
        }
      }

      .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        width: 60px;
        height: 60px;
        align-items: center;
        justify-content: center;
        border: none;
        display: none;
        z-index: 111;
        @media screen and (max-width: 768px) {
          display: flex;
        }
        i {
          font: normal normal normal 14px/1 FontAwesome;
          &:before {
            background-image: url("@{img}Delete.svg");
            background-repeat: no-repeat;
            content: "";
            width: 30px;
            height: 30px;
            background-size: contain;
            display: block;
          }
        }
      }

    }
    .block-categories {
      margin: 0;
      padding: 0 80px 0 0;
      border-right: 1px solid @gray12;

      @media screen and (max-width: 1024px) {
        border: none;
      }
    }
    ul.category-sub-menu {
      > li {
        a {
          font-family: @font-base;
          color: @green2;
          font-size: 19px;
          font-weight: 700;
          text-transform: uppercase;
          padding: 0;
          margin-bottom: 17px;
          &:before {
            content: initial;
          }
        }
        > .collapse-icons {
          display: none;
        }
        > .collapse {
          margin: 0;
          &:not(.show) {
            display: block;
          }
          ul {
            margin: 0;
            li {
              padding: 5.5px 0;
              margin-bottom: 14px;
              border-bottom: 1px solid @green2;
              a{
                font-family: @font-base;
                color: @green2;
                font-size: 15px;
                font-weight: 400;
                margin: 0;
              }
            }
          }
        }
      }
    }
    .PM_ASBlockOutput {
      .filter-wrapper {
        margin: 0;
        padding: 0 80px 0 0;
        border-right: 1px solid @gray12;
        @media screen and (max-width: 1024px) {
          border: none;
        }
        .PM_ASCriterionsGroupName {
          display: block;
          font-family: @font-base;
          font-weight: 700;
          font-size: 19px;
          color: @green2;
          margin-bottom: 15px;
          text-transform: uppercase;
        }
        ul.PM_ASCriterionGroupLink{
          > li {
            a {
              font-family: @font-base;
              font-weight: 400;
              font-size: 17px;
              color: @green2;
              background: none;
              text-transform: capitalize;
              border: none;
              border-bottom: 1px solid @green2;
              padding: 10px 0;
              transition: all 0.4s ease-in;
              display: block;
              margin: 0 0 10px;
              &:hover {
                padding: 10px 0px 10px 20px;
              }
            }
          }
        }
      }
    }
  }
  &.sommaire {
    div#content-wrapper {
      flex: initial;
      max-width: initial;
      .sommaire h2 {
        font-weight: 700;
        font-size: 38px!important;
        letter-spacing: 0px;
        @media (max-width: 992px) {
          font-weight: 700;
          font-size: 20px!important;
        }
      }
      .group-sommaire h2 {
        width: 100%;
        text-align: center;
        margin-bottom: 40px;
        text-transform: initial;
      }
    }
    .sommaire-label {
      background: @light-green;
      max-width: 102px;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      .elementor-heading-title {
        font-size: 13px;
        font-weight: 400;
        text-align: center;
        display: block;
        padding: 5px 8px;
        color: @green2;
        @media (max-width: 992px) {
          line-height: 1.5;
        }
      }
    }
    .elementor-section.elementor-section-boxed>.elementor-container ,
    .content-category {
      margin: 0 auto;
      max-width: 1144px;
    }
    .side-carte {
      position: absolute;
      bottom: -40px;
      right: -230px;
      @media (max-width: 992px) {
        position: relative;
        right: 0;
      }
    }
    .group-sommaire {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 992px) {
        padding: 0;
      }
      >.elementor-container > .elementor-row {
        justify-content: space-between;
        @media (max-width: 1360px) {
          justify-content: center;
        }
      }
      .item-sommaire {

        max-width: 33.33%;
        flex: 0 0 33.33%;
        display: flex;
        justify-content: center;
        margin: 0 0 60px;
        @media (max-width: 1360px) {
          margin: 0 0 10px;
        }
        @media (max-width: 992px) {
          margin: 0 0 20px;
          padding: 10px;
          max-width: 50%;
          flex: 0 0 50%;
        }
        @media (max-width: 540px) {
          max-width: 100%;
          flex: 0 0 100%;
        }

       > div.content-sommaire{
         padding: 0 0 18px 0;
         max-width: 310px;
         background: @beige;
         position: relative;
       }
        .elementor-image img {
          height: 310px;
          width: 100%;
          object-fit: cover;
        }
        h3 {
          font-family: @font-base;
          font-weight: 700;
          font-size: 21px;
          color: @green2;
          padding: 8px 22px;
          margin: 0;
          line-height: initial;
          text-align: center;
        }
        p {
          font-family: @font-base;
          font-weight: 400;
          font-size: 14px;
          color: @black;
          text-align: left;
          padding: 0 22px;
          margin-bottom: 27px;
          text-transform: initial;
          line-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          // display: -webkit-box;
          -webkit-line-clamp: 3;
          height: 54px;
          -webkit-box-orient: vertical;
          &::first-letter {
            text-transform: uppercase;
          }
        }
        .link {
          display: flex;
          justify-content: center;
        }
        .btn {
          font-family: @font-base;
          font-weight: 400;
          font-size: 17px;
          color: @green2;
          background: none;
          text-transform: initial;
          border: none;
          border-bottom: 1px solid @green2;
          padding: 14px 0;
          transition: all 0.4s ease-in;
          display: block;
          line-height: initial;
          height: initial;
          border-radius: 0;
          &:hover {
            padding: 14px 20px;
          }
        }
      }
    }

  }
}