body#category {
  &.category-id-25 {
    // we remove duplicate bloc duo on category duo
    div.elementor-displayBelowProductsCategory {
      div.nos-offres-duos {
        display: none;
      }
    }
  }
  #products.-facets-loading {
    .facets-loader-icon {
      display: none !important;
    }
    .products {
      opacity: 1;
    }
  }
  .elementor-widget-prestashop-widget-ProductsList {
    .swiper-button-next,
    .swiper-button-prev {
      @media (max-width: 768px) {
        display: none;
      }
    }
    .product-add-cart {
      > a {
        @media (max-width: 768px) {
          margin-bottom: 5px;
        }
      }
    }
  }

  &.headerbg {
    @media (max-width: 992px) {
      padding-top: 0 !important;
    }

    .breadcrumb {
      display: block;
    }
  }

  #js-product-list-header {
    margin-bottom: 45px;

    p {
      font-family: @font-base;
      font-size: 28px;
      line-height: 28px;
      color: black;
      text-align: center;
      max-width: 960px;
      margin: 0 auto;
    }
  }

  .banner {
    padding: 15px;
  }

  section#wrapper {
    padding-top: 0;
    padding-bottom: 0;
  }

  div.products-selection {
    display: none;
  }

  div#content-wrapper {
    h1 {
      font-family: @font-base;
      font-weight: 600;
      font-size: 40px !important;
      text-align: center;
      color: @green2;
      margin-bottom: 5px;
      padding-bottom: 0;

      &:after {
        content: initial;
      }
    }
  }

  div.category-header {
    display: flex;
    //width: 100vw;
    position: relative;
    //left: calc(-1 * (100vw - 100%) / 2);
    //background-image: url("./@{img}slide-listing.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 768px) {
      background-size: cover;
    }

    div.category-image {
      width: 100vw;
      position: absolute;
      left: 50%;
      right: 50%;
      margin-left: -50vw;
      z-index: 0;
      top: 0;
      padding: 0;
      height: 100%;
      min-height: 240px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    div.category-description {
      width: 100%;
      padding: 248px 23px 80px 0;
      z-index: 3;
      max-width: 800px;
      margin: 0 auto 13px 0;

      @media (max-width: 991.98px) {
        padding: 140px 20px 80px 20px;
      }

      @media (max-width: 767.98px) {
        padding: 80px 20px 80px 20px;
      }

      p {
        font-family: @font-base;
        font-weight: 400;
        font-size: 20px;
        text-align: left;
        line-height: 25px;
        color: @white;
        margin: 0;

        &.category-banner-title {
          font-weight: 800;
          font-size: 36px;
          line-height: 44px;
          text-transform: uppercase;
        }

        &.category-banner-description {
          font-weight: 700;
          font-size: 32px;
          line-height: 44px;
        }

        &.category-banner-sub-description {
          font-weight: 700;
          font-size: 27px;
          line-height: 40px;
          margin-bottom: 13px;
          text-transform: uppercase;
        }

        img {
          margin-top: 83px;

          @media (max-width: 768px) {
            margin-top: 33px;
            max-width: 200px;
          }
        }
      }
    }
  }

  #js-product-list-header {
    margin-top: 18px;
  }

  div.elementor-element .elementor-heading-title {
    text-transform: initial;

    a {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
    }
  }

  div.complements-reassurance {
    div.elementor-element .elementor-heading-title {
      text-transform: uppercase;
    }
  }

  @media (max-width: 768px) {
    div.elementor-widget-prestashop-widget-ProductsList {
      div.js-product-miniature-wrapper {
        article.product-miniature {
          div.product-price-and-shipping {
            margin-right: 0;
          }

          div.product-add-cart {
            justify-content: space-around;
            margin-top: 22px;
          }
        }
      }
    }
  }
  .products-grid .product-miniature .desc-min p {
    text-transform: initial;
  }
}

@media (min-width: 1025px) {
  .products.row .js-product-miniature-wrapper {
    padding: 0 2rem 3rem;
  }
}
body#category,
body#module-iqitsearch-searchiqit {
  .elementor-displayBelowProductsCategory {
    div.js-product-miniature-wrapper {
      @media (max-width: 768px) {
        flex: initial;
        max-width: initial;
      }
    }
  }
  div.js-product-miniature-wrapper {
    @media (max-width: 768px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    &.newsletter-category-block {
      @media (max-width: 768px) {
        flex: 0 0 100%;
        max-width: 100%;
        margin: 0 0 30px;
      }
    }

    article.product-miniature {
      h2.product-title {
        @media (max-width: 768px) {
          margin-bottom: 0;
          line-height: 0.5;
        }

        a {
          @media (max-width: 768px) {
            font-size: 21px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 30px;
          }
        }
      }

      div.desc-min {
        p {
          @media (max-width: 768px) {
            font-size: 13px;
          }
        }
      }

      div.product-add-cart {
        @media (max-width: 768px) {
          margin: 0;
        }

        a.btn {
          @media (max-width: 768px) {
            width: 100%;
            margin-bottom: 10px;
            line-height: 44px;
          }
        }

        form {
          @media (max-width: 768px) {
            margin: 0;
          }
        }
      }
    }
  }

  .newsletter-card {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 20px;
    @media (max-width: 768px) {
      padding: 20px 20px 30px;
    }
    &-title {
      font-family: @font-base;
      font-size: 25px;
      font-weight: bold;
      line-height: 1.96;
      text-align: center;
      color: @white;
      margin-bottom: 23px;
      @media (max-width: 768px) {
        font-size: 22px;
      }
    }
    &-description p {
      font-family: @font-base;
      font-size: 19px;
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      text-align: center;
      color: @white;
      margin-bottom: 30px;
      @media (max-width: 768px) {
        font-size: 15px;
      }
    }
    input {
      border: 1px solid @white;
      background: transparent;
      height: 66px;
      line-height: 66px;
      margin-bottom: 14px;
      width: 100%;
      color: @white;
      &::placeholder {
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        color: @doveGray;
      }
    }
    .btn {
      background: @white;
      color: @green2;
      border: 1px solid @white;
      text-transform: uppercase;
      height: 66px;
      line-height: 66px;
      font-size: 20px;
      font-weight: 600;
      width: 100%;
      margin-bottom: 20px;
      &:hover {
        color: @white;
        background: transparent;
        border: 1px solid @white;
      }
    }
    .gdpr_module {
      .custom-checkbox {
        label {
          color: @white;
        }
        input[type="checkbox"] + span:not(.color) {
          background: transparent;
          border-color: @gray-darker !important;
        }
        input[type="checkbox"]:checked ~ span .checkbox-checked {
          background: @white;
        }
      }
    }
  }
}

body#module-iqitsearch-searchiqit {
  .products.row, .products-grid.row {
    @media (max-width: 768px) {
      margin: 0;
    }
  }
  div.js-product-miniature-wrapper {
    @media (max-width: 768px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding: 0 5px;
      margin-bottom: 40px;
    }
  }
}
div.products-selection {
  div.products-sort-order,
  div.products-nb-per-page {
    a.select-title {
      background-color: @white;
      color: @green2;
      border: 1px solid @green2;
      color: @green2;

      i {
        color: @green2;
      }
    }
  }
}

div.one-product {
  div.elementor-column {
    h3.elementor-heading-title {
      margin-bottom: 35px;
      font-family: @font-base;
      font-weight: 700;
      font-size: 30px !important;
      line-height: 1.233;
      color: @green2;
      margin-bottom: 0;
      text-transform: uppercase;

      @media (max-width: 767.98px) {
        font-size: 28px !important;
      }
    }

    div.product-description,
    div.product-description p {
      font-family: @font-base;
      font-weight: 700;
      font-size: 21px;
      color: @black;
      margin-bottom: 0;
      line-height: 27px;

      @media (max-width: 767.98px) {
        font-size: 18px;
        line-height: 1.23;
      }
    }

    div.product-description-all {
      margin-bottom: 30px;

      ul,
      ol {
        list-style: none !important;
        margin-bottom: 16px;

        li {
          font-family: @font-base3;
          font-weight: 600;
          font-size: 16px;
          color: @black;
          position: relative;
          padding-left: 41px;
          margin-bottom: 6px;

          &:after {
            content: "";
            background-image: url("@{img}list-style.png");
            background-repeat: no-repeat;
            background-size: contain;
            width: 26px;
            height: 14px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 2px;
          }
        }
      }

      p {
        font-family: @font-base3;
        font-weight: 400;
        font-size: 15px;
        color: @black;
        margin: 0;
        line-height: 23px;
      }
    }

    div.current-price {
      p {
        font-family: @font-base;
        font-weight: 700;
        font-size: 26px;
        color: @green2;
        @media (max-width: 767.98px) {
          font-size: 26px !important;
        }
      }
    }

    .product-add-cart {
      display: flex !important;
      justify-content: center;
      margin: 0 -5px;

      .btn {
        font-size: 15px;
        font-family: @font-base;
        font-weight: 600;
        margin: 0;
        letter-spacing: 0;
        max-width: 148px;
        width: 100%;

        &.add-to-cart {
          font-weight: 700;
        }
      }

      a.btn.btn-primary {
        background-color: @white;
        border-color: @gray-light2;
        color: @green;

        &:hover {
          background-color: @green;
          color: @white;
        }
      }

      form {
        max-width: 148px;
        width: 100%;
        margin-left: 10px;
      }

      i {
        display: none;
      }
    }

    .product-functional-buttons {
      display: none;
    }
  }
}

div.title-categorie-h2 {
  h2 {
    font-size: 30px;
    font-family: @font-base;
    font-weight: 500;
  }
}

div.btn-green {
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn {
    &:active,
    &:focus {
      background-color: transparent;
      border-color: @green2;
      color: @green2;
    }
  }
}

div.btn-trans {
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn {
    &:active,
    &:focus {
      background-color: @green2;
      border-color: @green2;
      color: @white;
    }
  }
}

div.list-sommaire {
  ul,
  ol {
    padding-left: 34px;

    li {
      font-family: @font-base;
      font-weight: 500;
      font-size: 20px;
      color: @black;
      margin-bottom: 10px;
      padding-left: 8px;

      &::marker {
        color: @green2;
      }

      a {
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  body#category {
    div.elementor-text-editor {
      h2 {
        font-size: 30px;
        line-height: 35px;
      }

      p {
        font-size: 14px;
        line-height: 19px;
      }
    }

    #js-product-list-header p {
      font-size: 18px;
      line-height: 20px;
    }

    div.category-header {
      div.category-image {
        height: 100%;

        img {
          object-fit: cover;
          height: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  body#category {
    div#content-wrapper {
      h1 {
        font-size: 28px !important;
        line-height: 1;
        margin-bottom: 8px;
        text-align: left;
      }
    }

    div.elementor-text-editor {
      h2 {
        font-size: 22px;
        line-height: 25px;
      }

      p {
        font-size: 14px;
        line-height: 19px;
      }
    }

    div.elementor-element {
      .elementor-heading-title {
        font-size: 22px;
        line-height: 27px;
        display: block;
        text-align: center;
      }

      div.elementor-widget-container {
        //padding: 0;
        background-size: cover;
      }

      ol li,
      ul li,
      p {
        font-size: 15px;
      }
    }

    div.category-header {
      div.category-description {
        display: block !important;

        p {
          font-size: 16px;
          line-height: 1;
        }
      }

      div.category-image {
        display: block !important;
        overflow: visible;
      }
    }
  }
}

div.one-product div.elementor-column {
  .js-product-miniature-wrapper {
    margin-bottom: 107px;
    padding: 0;
    @media (max-width: 767.98px) {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      margin-bottom: 60px;
    }
  }

  .product-miniature {
    padding: 0;
    margin-bottom: 107px;
    max-width: 1160px;
    margin: 0 auto;

    .medium-gutters {
      padding: 0;
      align-items: center;
    }

    p.product-title ,
    h3.product-title {
      a {
        margin-bottom: 19px;
        font-family: @font-base;
        font-weight: 700;
        font-size: 30px !important;
        line-height: 37px;
        color: @green2;
        margin-bottom: 0;
        text-transform: uppercase;

        @media (max-width: 767.98px) {
          font-size: 28px !important;
        }
      }
    }

    div.product-description-short,
    div.product-description-short p {
      font-family: @font-base;
      font-weight: 700;
      font-size: 21px;
      color: @black;
      margin-bottom: 26px;
      line-height: 27px;

      @media (max-width: 767.98px) {
        font-size: 18px;
        line-height: 1.23;
      }
    }

    div.product-description {
      margin-bottom: 36px;

      ul,
      ol {
        list-style: none !important;
        margin-bottom: 16px;

        li {
          font-family: @font-base3;
          font-weight: 600;
          font-size: 16px;
          color: @black;
          position: relative;
          padding-left: 41px;
          margin-bottom: 6px;

          &:after {
            content: "";
            background-image: url("@{img}list-style.png");
            background-repeat: no-repeat;
            background-size: contain;
            width: 26px;
            height: 14px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 2px;
          }
        }
      }

      p {
        display: none;
      }
    }

    .row.bottom-row {
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      @media screen and (max-width: 768px) {
        align-items: center;
        flex-direction: column;
        justify-content: center;
      }
    }

    div.product-price-and-shipping {
      margin: 0;
      margin-right: 40px;

      span {
        font-family: @font-base;
        font-weight: 700;
        font-size: 25px;
        color: @green2;
        @media (max-width: 767.98px) {
          font-size: 26px !important;
          margin-bottom: 20px;
        }
      }
    }

    .product-add-cart {
      display: flex !important;
      align-items: center;
      flex-direction: row-reverse;
      margin: 0 -5px;
      justify-content: flex-end;
      width: 350px;
      @media (max-width: 767.98px) {
        flex-direction: row !important ;
      }

      .btn {
        font-size: 15px;
        font-family: @font-base;
        font-weight: 600;
        margin: 0;
        letter-spacing: 0;
        width: 148px;
        width: 100%;

        &.add-to-cart {
          font-weight: 700;
        }
      }

      a.btn.btn-primary {
        margin-bottom: 0 !important;
        margin-left: 18.5px;
        background-color: @white;
        border: 1px solid @green;
        color: @green;
        display: block;
        width: 148px;

        &:hover {
          background-color: @green;
          color: @white;
        }
      }

      form {
        max-width: 148px;
        width: 100%;
        margin-left: 10px;
      }

      i {
        display: none;
      }
    }

    .product-functional-buttons {
      display: none;
    }
  }
}
// avis Client before Footer //

.cart-avis-listing {
	position: relative;
  @media (max-width: 992px) {
    margin-bottom: 46px;
  }
	&::after {
    content: "";
    position: absolute;
    background-color: #f3ece4;
    z-index: -1;
    height: 100%;
    width: 100vw;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .note-content {
    background-color: #afa89f;
    padding-left: 25px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    display: flex;
    justify-content: center;
    flex-direction: column;
		border-left: 3px solid @white;
		border-right: 3px solid @white;
    @media (max-width: 992px) {
      padding: 18px 0 26px 17px;
			margin: 0 auto;
			margin-bottom: 20px;
    }
    .note-nomber {
      font-family: @font-base;
      font-size: 54px;
      line-height: 66px;
      font-weight: bold;
      letter-spacing: 0px;
      color: @white;
      margin-bottom: 0;
      @media (max-width: 992px) {
        font-size: 48px;
        line-height: 58px;
      }
    }
    .sub {
      font-family: @font-base;
      font-size: 19px;
      line-height: 24px;
      font-weight: normal;
      letter-spacing: 0px;
      color: @white;
      margin-bottom: 0px;
    }
    .title,
    h3 {
      font-family: @font-base;
      font-size: 19px;
      line-height: 23px;
      font-weight: bold;
      letter-spacing: 0px;
      color: @white;
      margin-bottom: 0;
    }
		img {
			margin-top: 10px;
			width: 148px;
		}
  }
  .products {
    background-color: #f3ece4;
    overflow: hidden;
    position: relative;
    @media (max-width: 992px) {
      .slick-prev {
        left: 0 !important;
      }
      .slick-next {
        right: 0 !important;
      }
    }
		

    .slick-list {
      overflow: hidden;
    }
    .slider-wrapper {
      .slick-slide {
        float: left;
      }
			.slick-dots {
				margin-bottom: 20px !important;
			}
      .avis {
        display: flex !important;
        align-items: center;
        justify-content: center;
				border-right: 2px solid @white;
				padding: 60px 29px 15px 26px;
				@media (max-width: 992px) {
					padding: 15px 30px 15px 30px;
				}
				.content {
					@media (max-width: 992px) {
					}
					.title {
						font-family: @font-base;
						font-weight: normal;
						font-size: 20px;
						line-height: 24px;
						font-weight: 700;
						color: @green2;
						margin-bottom: 15px;
						text-transform: uppercase;
						@media (max-width: 992px) {
							margin-bottom: 10px;
						}
					}
					.description {
						font-family: @font-base;
						font-weight: normal;
						font-size: 13px;
						line-height: 17px;
						color: @black;
						margin-bottom: 37px;
						max-width: 300px;
						width: 100%;
						@media (max-width: 992px) {
							margin-bottom: 10px;
							font-size: 11px;
							line-height: 15px;
						}
					}
					.rating {
						margin-top: 20px;
						margin-bottom: 10px;
                        margin-left: 2px;
						height: 21px;
						overflow: hidden;
						@media (max-width: 992px) {
							margin-bottom: 10px;
						}
						span {
							width: 15px;
							height: 15px;
							background-image: url("data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDE1IDE1Ij4NCiAgPGcgaWQ9Ikdyb3VwZV8yNTQyIiBkYXRhLW5hbWU9Ikdyb3VwZSAyNTQyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjc0IC0xMzI1KSI+DQogICAgPHJlY3QgaWQ9IlJlY3RhbmdsZV84NjMiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDg2MyIgd2lkdGg9IjE1IiBoZWlnaHQ9IjE1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2NzQgMTMyNSkiIGZpbGw9IiM1NWJlOTMiLz4NCiAgICA8cGF0aCBpZD0icmF0aW5nLXN0YXIiIGQ9Ik02LjI3Ni41ODcsOC4xOTQsNC41NDVsNC4zNTcuNkw5LjM4LDguMTk1bC43NzQsNC4zM0w2LjI3NiwxMC40NSwyLjQsMTIuNTI0bC43NzUtNC4zM0wwLDUuMTQ3bDQuMzU3LS42WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjc1IDEzMjUuNjM0KSIgZmlsbD0iI2ZmZiIvPg0KICA8L2c+DQo8L3N2Zz4NCg==");
							background-position: center;
							background-repeat: no-repeat;
							background-size: contain;
							display: block;
							float: left;
						}
					}
					.fullname {
						font-family: @font-base;
						font-weight: normal;
						font-size: 15px;
						line-height: 19px;
						font-weight: 700;
						color: @black;
						margin-bottom: 0px;
					}
				}
				.image {
					@media (max-width: 992px) {
					}
					img {
						width: 100%;
						object-fit: contain;
						@media (max-width: 992px) {
							object-fit: initial;
						}
					}
				}
			}
    }
  }
}
