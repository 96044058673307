body#checkout {
    header {
        @media (max-width: 992px) {
          background: none !important;
         }
    }
    .header-cart {
        padding-top: 56px !important;
        margin-bottom: 55px;
        @media (max-width: 767px) {
            margin-bottom: 33px;
            padding-top: 9px !important;
        }
        h1 {
            margin-bottom: 17px;
            @media (max-width: 767px) {
            text-align: center;
            }
            svg {
            fill: @green2;
            @media (max-width: 767px) {
                width: 126px;
                height: 51px;
            }
            }
        }
    }

    position: relative;
    /*header#header,
    footer#footer {
        display: none !important;
    }

    #checkout-header {
        display: block !important;
    }*/
    &:after {
        content: "";
        background-image: url("@{img}DECORfeuille-02.png");
        background-repeat: no-repeat;
        background-size: contain;
        width: 328px;
        height: 214px;
        display: inline-block;
        margin: 0;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        @media (max-width: 1320px) {
            display: none;
        }
    }
    .s-temoignage,
    .trustpilot-widget {
        display: none;
    }

    section#wrapper {
        background: white;
        padding: 0;
        border: none;
        margin-bottom: 30px;
        position: relative;
        &:after {
            content: "";
            background-image: url("@{img}DECORfeuille-01.png");
            background-repeat: no-repeat;
            background-size: contain;
            width: 627px;
            height: 593px;
            display: inline-block;
            margin: 0;
            position: absolute;
            top: 50%;
            right: -15%;
            transform: translateY(-50%);
            @media (max-width: 1320px) {
                z-index: -1;
            }
        }

        &:before {
            content: "";
            background-image: url("@{img}DECORfeuille-03.png");
            background-repeat: no-repeat;
            background-size: contain;
            width: 353px;
            height: 419px;
            display: inline-block;
            margin: 0;
            position: absolute;
            top: 50%;
            left: -140px;
            transform: translateY(-50%);
        }

        h1.page-title {
            margin-top: 30px;
            line-height: normal;
            font-size: 30px !important;
            font-weight: bold;
            text-align: center;
        }

        section.checkout-step {

            h1.step-title {
                font-size: 20px;
                font-weight: bold;
                text-transform: initial;
            }

            &#checkout-personal-information-step {

                div.content {

                    p:first-child {
                        text-align: center;
                    }

                    div.iqitsociallogin {
                        display: flex;
                        flex-wrap: wrap;
                        text-align: center;
                        justify-content: center;

                        span.text-muted {
                            width: 100%;
                            margin-bottom: 15px;
                        }

                        a.btn-iqitsociallogin {
                            margin: 15px 0;
                            text-transform: initial;
                            font-size: 18px;
                            font-weight: 500;
                            letter-spacing: 0;
                            height: 50px;
                            line-height: 50px;
                            width: 100%;
                            transition: all 0.3s ease-in;
                            @media (max-width: 992px) {
                                font-size: 14px;
                                justify-content: center;
                            }

                            &.btn-facebook {
                                border: none;
                                background-color: @fb-btn;
                                padding: 0 24px;
                                text-align: left;
                                @media (max-width: 992px) {
                                    text-align: center ;
                                }

                                &:hover {
                                    i {
                                        color: white;
                                    }
                                }

                                i {
                                    font-size: 25px;
                                    margin-right: 56px;
                                    @media screen and (max-width: 992px) {
                                            margin-right: 0;
                                    }
                                }
                            }

                            &.btn-google {
                                border: 1px solid @purple;
                                background-color: @white !important;
                                padding: 0 30px;
                                display: flex;
                                color: @purple !important;
                                &:hover {
                                    border: 2px solid    @purple;
                                }

                                span {
                                    display: flex;
                                    align-items: center;
                                    background: transparent;
                                    position: relative;
                                    top: -2px;
                                    padding: 0;
                                    margin-right: 56px;
                                    @media screen and (max-width: 992px) {
                                        margin-right: 0;
                                    }
                                    img {
                                        width: 19px;
                                        margin-right: 6px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        div.cart-grid-right {
            section#js-checkout-summary {
                border: 1px solid hsla(0,0%,63%,.25);

                div.promo-code {

                    .form-control {
                        border-right: 0;
                    }

                    .btn {
                        height: 40px;
                        line-height: 40px;
                        font-size: 16px;
                        font-weight: 600;
                        text-transform: initial;
                    }
                }
            }
        }

        .btn-outline-secondary {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                margin: 0;
            }
        }
    }

    #customer-form label {
        display: block;
    }

    span.custom-radio input[type="radio"]:checked + span {
        left: 0;
        top: 0;
    }

    form#conditions-to-approve {
        label {
            a {
                font-size: 0.875rem;
                color: @black;
                margin: 0 3px;
                line-height: 1.4;
            }
        }
    }

    div.dalenys-form {
        p {
            text-align: center;
            width: 100%;

            label {
                text-align: left;
                margin-bottom: 0;
                font-weight: 600;
                width: 100%;
            }

            .input-container,
            input[type=text] {
                width: 100%;
            }

            &.brand-area {
                width: 130px;
                float: none;
                margin: 0;
                top: 95px;
                left: 75px;

                iframe#hosted-fields-frame-brand {
                    width: 62px !important;
                    min-width: unset;
                }
            }
        }

        input[type=text],
        .input-container {
            border: 1px solid @gray-darker;
            border-radius: 0;
            box-shadow: none;
            background-color: @white;
            background-image: none;
            font-family: @font-base;
            color: @black;
            font-weight: 400;
            font-size: 16px;
            padding: 12px 10px;
            height: 40px;
            outline: none;

            &::placeholder {
                color: @doveGray;
                font-size: 16px;
            }


            &::-webkit-input-placeholder {
                color: @doveGray;
            }

            &::-moz-placeholder {
                color: @doveGray;
            }

            &:-ms-input-placeholder {
                color: @doveGray;
            }

            &:-moz-placeholder {
                color: @doveGray;
            }

            &:active {
                &::-webkit-input-placeholder {
                    color: @doveGray;
                }

                &::-moz-placeholder {
                    color: @doveGray;
                }

                &:-ms-input-placeholder {
                    color: @doveGray;
                }

                &:-moz-placeholder {
                    color: @doveGray;
                }
            }
        }
    }
}

body#cart {
    .cart-item {
        border: 1px solid hsla(0, 0%, 63%, .25);
        border-bottom: 5px solid hsla(0, 0%, 63%, .25);
        padding-right: 10px;
        padding-left: 10px;
    }
}

@media screen and (max-width: 768px) {

    .hosted_fields_form {
        border: none;

        form {
            padding: 0 20px;
        }
    }

    body#checkout {
        .step-title {
            font-size: 20px;
        }

        #conditions-to-approve {
            label {
                display: initial;

                a {
                    font-size: 0.875rem;
                    color: @black;
                    margin: 0 3px;
                }
            }
        }

        div.additional-information {
            margin: 33px 0;
        }
    }

    body#checkout,
    body#cart {
        div#content-wrapper {

            h1.h3 {
                font-size: 20px !important;
            }
        }
    }
}

@media screen and (max-width: 580px) {
    body#checkout {
        section.checkout-step {
            div.delivery-option {
                div.col-sm-1 {
                    position: absolute;
                    left: 10px;
                }
            }
        }
    }
}

body#checkout section.checkout-step .payment-options .payment-option label {
    display: flex;
    align-items: center;
    margin: 0;
    max-width: 100%;
    width: 100%;
    .custom-radio {
        margin-right: 8px;
        order: 0;
    }
    span:not(.custom-radio) {
        order: 2;
    }
}

body#checkout section.checkout-step .payment-options .payment-option label img {
    order: 1;
    margin-left: 5px;
    margin-right: 8px;
    height: 45px;
}

table#order-products {
    thead.thead-default {
        th.head-checkbox {
            input[type='checkbox'] {
                opacity: 1;
                position: static;
            }
        }
    }

    tbody {
        tr {
            td {
                input[type='checkbox'] {
                    opacity: 1;
                    position: static;
                }
            }

            td.text-center.order-actions {
                a {

                }
            }
        }
    }
}


////////// REFONTE CHECKOUT /////////

body#checkout {
    .container,
    .elementor-section.elementor-section-boxed > .elementor-container {
        max-width: 1440px;
    }
    .modal {
        &.show {
            background-color: rgba(@black, 0.5);
            overflow: initial;
        }

        .modal-body {
            @media (max-width: 992px) {
                height: 70vh;
                overflow-y: auto;
            }
        }
    }
    #header {
        @media (max-width: 992px) {
           z-index: 0;
        }
    }
    #content-wrapper {
        counter-reset: step 0;
        position: relative;
        z-index: 2;
    }
    .row {
        @media (max-width: 768px) {
            margin: 0;
        }
    }
    .btn.btn-primary {
        background-color: @pink;
        border-color: @pink;
        color: @white;
        border-radius: 10px;
        &:active:focus {
            background: @white;
            color: @pink;
            border-color: @pink;
        }
        &:hover {
            background: @white;
            color: @pink;
            border-color: @pink;

            i {
                &:before {
                    filter: initial;
                }
            }
        }
    }
    .collapse:not(.show) {
        display: block;
    }
    .btn-back {
        a {
            background-color: transparent;
            border: none;
            color: @purple;
            text-transform: initial;
            padding: 0;
            height: auto;
            line-height: normal;
            margin-bottom: 42px;
            font-weight: 600;
            display: block;
            @media (max-width: 768px) {
                width: 100%;
                display: block;
                text-align: left;
                margin-bottom: 32px;
                font-size: 14px;
                line-height: 16px;
            }
            &::before {
                content: "< ";
            }
            &:active:focus:hover {
               opacity: .8;
               background: none;
               color: @purple;
            }
        }
    }
    section#wrapper {
        h1.h1 {
            font-family: @font-base;
            font-size: 30px !important;
            font-weight: 600;
            color: @green2;
            margin-bottom: 36px;
            text-align: left;
            @media (max-width: 768px) {
                text-transform: uppercase;
                font-size: 28px !important;
                text-align: left;
                margin-bottom: 59px;
            }
        }
    }

    section#checkout-personal-information-step {
        border: none;
        &.-complete {
            .content {
                &:after {
                    content: none !important;
                }
            }
        }
        .content {
            padding: 30px 34px;
            position: relative;
            @media (max-width: 768px) {
                padding: 12px 0px 23px 0px;
            }
            &:after {
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background: @green2;
                width: 1px;
                height: 90%;
                @media (max-width: 768px) {
                    content: initial;
                }
            }
            input,
            .btn {
                height: 50px;
                line-height: 50px;
                @media (max-width: 768px) {
                    height: 41px;
                    line-height: 41px;
                }
            }
            input {
                height: 50px;
                line-height: 50px;
                border-color: @gray3;
                @media (max-width: 768px) {
                    height: 41px;
                    line-height: 41px;
                }
            }
            .btn.btn-outline-secondary {
                font-weight: 600;
                background-color: @gray12;
                border: 1px solid @gray12;
                transition: all 0.4s ease-in-out;
                width: 50px;
                &:hover {
                    border: 1px solid @gray12;
                    background-color: transparent;
                    border-left: 0;
                }
            }
            div.iqitsociallogin {
                span.text-muted {
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }
        h2.step-title {
            // display: none;
            // .step-number {
            //     display: none;
            // }
        }
        .form-footer {
            margin-top: 20px;

            .btns {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
                margin-top: 14px;
                @media (max-width: 768px) {
                    margin-top: 24px;
                }

                a ,
                .forgot-password a {
                    font-family: @font-base;
                    font-size: 14px;
                    font-weight: 700;
                    color: @black;
                    @media (max-width: 768px) {
                        font-size: 14px;
                    }
                }
               > a {
                   position: relative;
                   &:after {
                       content: "";
                       background-image: url("@{img}iconsQ2.svg");
                       background-repeat: no-repeat;
                       background-size: contain;
                       width: 11px;
                       height: 7px;
                       display: inline-block;
                       transform: rotate(90deg);
                       margin: 0;
                       position: relative;
                       top: -2px;
                       right: -27px;
                       filter: brightness(0);
                       @media (max-width: 768px) {
                           right: -11px;
                       }
                   }
               }
                .forgot-password {
                    margin: 0;
                }
            }
        }
        .login-steps {
            display: flex;
            align-items: flex-start;
            margin: 0 -15px;
            @media (max-width: 768px) {
                flex-direction: column;
            }
            .policy {
                display: none;
            }
            &-mail,
            &-account {
                width: 50%;
                flex: 0 0 50%;
                flex-wrap: wrap;
                padding: 0 33px;
                @media (max-width: 768px) {
                    width: 100%;
                    flex: 0 0 100%;
                    padding: 0 5px;
                }
            }
            &-mail{
                @media (max-width: 768px) {
                    margin-bottom: 24px;
                    padding-bottom: 24px;
                    position: relative;
                }
                &:after {

                    @media (max-width: 768px) {
                        content: "";
                        position: absolute;
                        left: 50%;
                        bottom : 0;
                        transform: translateX(-50%);
                        background: @green2;
                        width: 260px;
                        height: 1px;
                    }
                }
            }
            h3 {
                font-family: @font-base;
                font-size: 18px;
                font-weight: 500;
                color: @black;
                margin-bottom: 18px;
                text-align: center;
                @media (max-width: 768px) {
                    font-size: 18px;
                }
            }
            #customer-form {
                section {
                    > p {
                        display: none;
                    }
                }
            }
            .form-group {
                margin-bottom: 13px;
                .form-control-comment {
                    padding: 0  ;
                }
                .col-form-label {
                    display: none;
                }
                .col-md-8 {
                    flex: 0 0 100%;
                    max-width: 100%;
                    @media (max-width: 768px) {
                        padding: 0;
                    }
                }
            }
        }
        #personal-information-step-login {
            background: @white;
            padding: 0;
        }
    }
    section.checkout-step {
        border: none;
        padding: 0;
        opacity: 1;
        margin: 0 0 10px;
        &:not(:last-child,:first-child) {
            border-bottom-color: white;
            opacity: 1;
        }
        .content {
            padding: 30px 89px 30px 45px;
            @media (max-width: 768px) {
                    padding: 12px 0px 30px 0px;
            }
        }
        &.-complete {
            padding: 0;
        }
        &.-current {
            h2.step-title {
                padding: 10px 40px;
                margin: 0;
                border-bottom: 1px solid @gray12 !important;
                @media (max-width: 768px) {
                    padding: 9px 40px;
                }
            }
        }
        h2.step-title {
            font-family: @font-base;
            font-size: 18px;
            font-weight: 500;
            text-align: left;
            color: @black;
            text-transform: initial;
            background-color: @beige9;
            padding: 10px 40px;
            opacity: 1;
            border-bottom: 1px solid @gray12 ;
            @media (max-width: 768px) {
                padding: 9px 12px;
                font-size: 18px;
            }

            .step-number {
                display: inline-block;
                font-size: 0;
                &:before {
                    counter-increment: step 1;
                    content: counter(step) ".";
                    font-size: 18px;
                    @media (max-width: 768px) {
                        font-size: 18px;
                    }
                }
            }

        }
        &#checkout-addresses-step {
            #delivery-address {
                .form-group {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start !important;
                    margin-bottom: 10px;
                    .col-form-label {
                        font-family: @font-base3;
                        font-size: 17px;
                        text-align: left;
                        text-transform: uppercase;
                        color: @gray-darker2;
                        display: flex;
                        align-items: flex-start;
                        width: 100%;
                        max-width: 100%;
                        padding: 0 15px;
                    }
                    .col-md-8 {
                        width: 100%;
                        max-width: 100%;
                        flex: 0 0 100%;
                        @media (max-width: 768px) {
                            padding: 0;
                        }
                        select, select.form-control,
                        input, input.form-control {
                            height: 50px !important;
                            line-height: 50px;
                            border: 1px solid @gray12 ;
                            &::placeholder {
                                display: none;
                            }
                        }

                        .custom-select2 {
                            &:before {
                                content: "";
                                background-image: url("@{img}Picto-fleche.svg");
                                background-repeat: no-repeat;
                                background-size: contain;
                                width: 17px;
                                height: 2px;
                                display: inline-block;
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: 14px;
                                pointer-events: none;
                                filter: brightness(0%);
                                z-index: 3;
                            }
                            &:after {
                                content: initial;
                            }
                        }
                    }
                    .form-control-comment {
                        padding: 0 16px;
                    }
                }
            }
        }
    }
    .dalenys-form {
        border: none;
        margin-top: 0;
        &-payment {
            background: @gray7;
            max-width: 778px;
            padding: 18px 24px 18px 46px;
            @media (max-width: 768px) {
                padding: 9px;
            }
            form {
                width: 100%;
                max-width: initial;
                overflow: initial;
                @media (max-width: 768px) {
                    padding:0;
                }
                p {
                    position: relative;
                    padding-right: 40px;
                    @media (max-width: 768px) {
                        padding-right: 27px;
                    }
                    label {
                        color: @gray-darker2;
                        font-family: @font-base;
                        font-size: 18px;
                        text-align: left;
                        font-weight: 400;
                        padding-left: 20px;
                        @media (max-width: 768px) {
                            font-size: 14px;
                            padding-left: 0;
                            line-height: 1;
                        }
                    }
                    .input-container {
                        border: 1px solid @gray8;
                        background-color: @white;
                        height: 51px;
                        line-height: 51px;
                        padding: 8px 10px 8px 70px;
                        position: relative;
                        @media (max-width: 768px) {
                            height: 40px;
                            line-height: 40px;
                            padding: 8px 10px 8px 50px;
                        }
                        &.hosted-fields-invalid-state {
                            border: 2px solid red;
                        }
                        &.hosted-fields-valid-state {
                            border: 2px solid green;
                        }
                        input {
                            height: 100%;
                            display: block;
                            padding: 0;
                            margin: 0;
                            border: 0;
                        }
                        &:after {
                            content: "";
                            position: absolute;
                            width: 1px;
                            height: 40px;
                            background-color:  @gray3;
                            top: 50%;
                            left: 60px;
                            transform: translateY(-50%);
                            @media (max-width: 768px) {
                                left: 40px;
                            }
                        }
                        &.card-fullname {
                            &:before {
                                content: "";
                                position: absolute;
                                width: 29px;
                                height: 22px;
                                top: 50%;
                                left: 14px;
                                transform: translateY(-50%);
                                background-image: url("@{img}address-card.svg");
                                background-repeat: no-repeat;
                                background-size: contain;
                                display: inline-block;
                                @media (max-width: 768px) {
                                    left: 9px;
                                }
                            }
                        }
                        &[id^='card-container_'] {
                            &:before {
                                content: "";
                                position: absolute;
                                width: 29px;
                                height: 22px;
                                top: 50%;
                                left: 14px;
                                transform: translateY(-50%);
                                background-image: url("@{img}credit-card.svg");
                                background-repeat: no-repeat;
                                background-size: contain;
                                display: inline-block;
                                @media (max-width: 768px) {
                                    left: 9px;
                                }
                            }
                        }
                        &[id^='expiry-container_'] {
                            padding: 16px 10px 6px 70px;
                            @media (max-width: 768px) {
                                padding: 10px 10px 6px 50px;
                            }
                            &:before {
                                content: "";
                                position: absolute;
                                width: 23.5px;
                                height: 22.5px;
                                top: 50%;
                                left: 15px;
                                transform: translateY(-50%);
                                background-image: url("@{img}date-range.svg");
                                background-repeat: no-repeat;
                                background-size: contain;
                                display: inline-block;
                                @media (max-width: 768px) {
                                    left: 9px;
                                }
                            }
                        }
                        &[id^='cvv-container_'] {
                            &:before {
                                content: "";
                                position: absolute;
                                width: 19px;
                                height: 23px;
                                top: 50%;
                                left: 19px;
                                transform: translateY(-50%);
                                background-image: url("@{img}map-locksmith.svg");
                                background-repeat: no-repeat;
                                background-size: contain;
                                display: inline-block;
                                @media (max-width: 768px) {
                                    left: 9px;
                                }
                            }
                        }
                    }
                    span.info {
                        position: absolute;
                        right: 0;
                        bottom: 25px;
                        line-height: 1;
                        @media (max-width: 768px) {
                            bottom: 20px;
                        }
                        &[title]:hover:after {
                            content: attr(title);
                            position: absolute;
                            top: -150%;
                            left: 50%;
                            transform: translateX(-50%);
                            background-color: @green2;
                            color: @white;
                            padding: 5px 10px;
                            width: max-content;
                            height: auto;
                            z-index: 5;
                            border-radius: 5px;
                        }
                    }
                }
                .date-security {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    p {
                        max-width: 250px;
                        @media (max-width: 768px) {
                            max-width: 155px;
                        }
                    }
                }
                .custom-checkbox {
                    label {
                        font-family: @font-base;
                        font-size: 18px;
                        font-weight: 300;
                        color: @gray-darker2;
                        padding-left: 20px;
                    }
                }
            }
        }
    }
    form#conditions-to-approve {
        .js-terms {
            font-size: 18px;
            color: @gray-darker2;
        }
    }
    #payment-confirmation {
     article.alert-danger {
         background-color: @beige2;
         border: 1px solid @beige6;
         font-size: 17px;
         color: @gray-darker2;
         a {
             color: @green2 !important;
             font-size: 17px;
             text-decoration: none;
         }
     }
        .btn {
            @media (max-width: 768px) {
                line-height: initial;
            }
        }

            }

            .card-body {
                padding: 21px 24px 3px;
                &.cart-summary-totals {
                    padding: 0px 26px 0;
                }
                &.cart-detailed-actions {
                    padding: 10px 10px 23px;

                }
            }
            .cart-grid-steps {
                flex: 0 0 1028px;
                max-width: 1028px;
                @media (max-width: 1440px) {
                    flex: 0 0 66.66667%;
                    max-width: 66.66667%;
                }
                @media (max-width: 1024px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                }
                @media (max-width: 414px) {
                    padding: 0;
                }
            }
            .cart-grid-right {
                flex: 0 0 calc(100% - 1028px);
                max-width: calc(100% - 1028px);
                display: flex;
                flex-direction: column-reverse;
                justify-content: flex-end;
                @media (max-width: 1440px) {
                    flex: 0 0 33.33333%;
                    max-width: 33.33333%;
                }
                @media (max-width: 1024px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin: 20px 0;
                }
                .ajax_pay {
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: flex-end;
                }
                .recap-title {
                    padding: 0 0 20px;
                    color: @black;
                    font-size: 17px;
                    font-weight: 600;
                    border-bottom: 1px solid @gray3;
                    i.recap-icon {
                        &:before {
                            content: "";
                            width: 21px;
                            height: 25px;
                            background-image: url("@{img}recap-icon.svg");
                    display: block;
                    margin-right: 8px;
                }
            }
        }
        .cart-summary {
            background-color: @beige9;
            box-shadow: 10px 8px 6px rgba(@black , 0.2);
            padding-bottom: 15px;
            &.paiment-summary {
                padding: 26px;
                margin: 0 0 15px;
                .items-payment {
                    display: flex;
                    align-items: center;
                    img {
                        max-width: 80px;
                        height: auto;
                        margin: 0 10px 0 0;
                    }
                    span {
                        font-size: 16px;
                        font-weight: 500;
                    }
                }
                img {
                    max-width: 207px;
                }
            }
        }
        .label {
            color: @black;
            font-size: 17px;
            font-weight: 600;
            &.js-subtotal {
                font-size: 17px;
                font-weight: 700;
            }
        }
        .value {
            color: @black;
            font-size: 19px;
            font-weight: 700;
            &.js-subtotal {
                font-size: 17px;
                font-weight: 700;
            }
        }
        .cart-detailed-totals {
            .card-block {
                padding: 44px 26px 0;
            }

            .cart-summary-line {
                margin-bottom: 23px;
                padding-bottom: 17px;
                border-bottom: 1px solid @gray3;
                &.cart-total {
                    border: none;
                    border-top: 1px solid @gray3;
                    padding: 21px 0 0;
                    margin: 0 0 5px;
                    .label {
                        color: @black;
                        font-size: 23px;
                        font-weight: 400;
                        padding-left: 0;
                        &:before {
                            content: initial;
                        }
                    }
                    .value {
                        color: @black;
                        font-size: 23px;
                        font-weight: 700;
                    }
                }
                &#cart-subtotal-products {
                    .label {
                        color: @black;
                        font-size: 17px;
                        font-weight: 600;
                        padding-left: 0;
                        &:before {
                            content: initial;
                        }
                    }
                    .value {
                        color: @black;
                        font-size: 19px;
                        font-weight: 700;
                        sup {
                            font-size: 17px;
                            margin-left: 5px;
                            color: #CCC;
                        }
                    }
                }
                .label {
                    color: @black;
                    font-size: 14px;
                    font-weight: 400;
                    padding-left: 15px;
                    position: relative;
                    &:before {
                        content: "";
                        background-image: url("@{img}Checked.png");
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 11px;
                        height: 13px;
                        display: inline-block;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        left: 0;
                        pointer-events: none;
                        z-index: 3;
                    }
                }
                .value {
                    color: @black;
                    font-size: 14px;
                    font-weight: 700;
                }
            }
            hr {
                width: 95%;
                margin: 0 auto;
                border: 1px solid @green2;
            }
            .cart-total {
                .label,
                .value {
                    font-size: 22px;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: @green2;
                }
            }
            p {
                color: @gray6;
                font-size: 13px;
                font-weight: 600;
                font-family: @font-base;
                margin: 0;
                line-height: 1.5;
            }
        }
        .paiment {
            padding: 12px 16px;
            border: 1px solid @lightgreen;
            p {
                font-family: @font-base;
                color: @green2;
                font-size: 15px;
                font-weight: 500;
                margin: 0;
            }
            ul li {
                font-family: @font-base;
                color: @green2;
                font-size: 15px;
                font-weight: 500;
                &:before {
                    content: "";
                    background-image: url("@{img}iconsQ2.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 11px;
                    height: 7px;
                    display: inline-block;
                    transform: rotate(90deg);
                    margin-right: 5px;
                }
                &:first-of-type {
                    font-weight: 600;
                }
            }
            a {
                font-family: @font-base;
                font-weight: 500;
                font-size: 15px;
                color: @green2;
                text-decoration: underline;
            }
        }
    }

    .cart-voucher {
        &-area {
            background-color: transparent;
            padding: 5px 26px;
            margin-bottom: 29px;
        }
        ul.promo-name {
            padding: 0 24px;
        }
        .form-control {
            border: 1px solid @gray3;
            font-size: 15px;
            margin: 0 8px 0 0;
            &::placeholder {
                font-size: 15px;
            }
        }
        .btn {
            height: 40px;
            line-height: 40px;
            font-size: 15px;
            font-weight: 400;
            background-color: @gray12;
            border-color: @gray12;
            color: @white;
            text-transform: uppercase;
        }
    }
    .paiment {
        padding: 12px 16px;
        border: 1px solid @lightgreen;
        p {
            font-family: @font-base;
            color: @green2;
            font-size: 15px;
            font-weight: 500;
            margin: 0;
        }
        ul li {
            font-family: @font-base;
            color: @green2;
            font-size: 15px;
            font-weight: 500;
            &:before {
                content: "";
                background-image: url("@{img}iconsQ2.svg");
                background-repeat: no-repeat;
                background-size: contain;
                width: 11px;
                height: 7px;
                display: inline-block;
                transform: rotate(90deg);
                margin-right: 5px;
            }
            &:first-of-type {
                font-weight: 600;
            }
        }
        a {
            font-family: @font-base;
            font-weight: 500;
            font-size: 15px;
            color: @green2;
            text-decoration: underline;
        }
    }
    .btn.back {
        background-color: transparent;
        border-color: @green2;
        color: @green2;
        text-transform: initial;
        padding: 0 41px;
        i {
            &:before {
                content: "";
                background-image: url("@{img}iconsQ2.svg");
                background-repeat: no-repeat;
                background-size: contain;
                width: 14px;
                height: 8px;
                display: inline-block;
                transform: rotate(-90deg);
                margin-right: 5px;
                position: relative;
                top: -1px;

            }
        }
        &:hover {
            background: @green2;
            color: @white;
            i {
                &:before {
                    filter: brightness(0) invert(1);
                }
            }
        }
        &:active:focus {
            background: @green2;
            color: @white;
        }
    }
}
